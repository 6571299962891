import { React, useState } from "react";
import ProfileDetails from '../companyProfile/ProfileDetails'
import '../../assets/css/companyProfile/settings.css'
import '../../assets/css/companyProfile/profile-details.css'
import api from "../../api";
const CompanySettings = ({ business }) => {
   console.log(" businesses" ,business)

    const handleUpdate = (event) => {
        const language = document.getElementById("primaryLanguage").value
        console.log(language)
    }
    const [editedBusiness, setEditedBusiness] = useState(business ? { ...business }: {});
    const [isEditMode, setIsEditMode] = useState(false);

    if(!business){
        return <p>No business found</p>
    }
 

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setEditedBusiness({ ...editedBusiness, [id]: value });
    };

    const handleUpdates = async (event) => {
        const language = document.getElementById("primaryLanguage").value
        console.log(language)
        try {
            const response = await api.get(`/business/${business.id}`, editedBusiness);
            // Handle success, maybe show a notification
            setIsEditMode(false);
        } catch (error) {
            // Handle error, maybe show an error message
            console.error("Error updating business:", error);
        }
    };

    const handleDiscard = () => {
        setEditedBusiness({ ...business });
        setIsEditMode(false);
    };
    return (
        <div>

            <div className="main-details">
                <div className="profile-details">
                    <div className="card details">
                        <h1>Profile Details</h1>

                        <form>
                            <div className="field-label">
                                <label htmlFor="businessName">Business Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    value={editedBusiness.name}
                                    onChange={handleInputChange}
                                    disabled={!isEditMode}
                                />
                            </div>
                            <div className="field-label">
                                <label htmlFor="phoneNumber">Phone Number</label>
                                <input
                                    type="text"
                                    id="phone_number"
                                    value={editedBusiness.phone_number}
                                    onChange={handleInputChange}
                                    disabled={!isEditMode}
                                />
                            </div>
                            <div className="field-label">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={editedBusiness.email}
                                    onChange={handleInputChange}
                                    disabled={!isEditMode}
                                />
                            </div>
                            <div className="field-label">
                                <label htmlFor="address">Address</label>
                                <input
                                    type="text"
                                    id="business_address"
                                    value={editedBusiness.business_address}
                                    onChange={handleInputChange}
                                    disabled={!isEditMode}
                                />

                            </div>


                            <div className="field-label">
                                <label htmlFor="language">Language</label>
                                <select

                                    name="" id="primaryLanguage">
                                    <option disabled value="">Select a language</option>
                                    {
                                        business.languages && business.languages.map((language, index) => (
                                            <option key={index} value={language.name}>{language.name}</option>
                                            
                                        ))}
                                </select>

                            </div>
                        </form>



                        <div className="btns">
                            {isEditMode ? (
                                <>
                                    <button className="discard" onClick={handleDiscard}>
                                        Discard
                                    </button>
                                    <button className="save" onClick={handleUpdates}>
                                        Save changes
                                    </button>
                                </>
                            ) : (
                                <button className="edit" onClick={() => setIsEditMode(true)}>
                                    Edit
                                </button>
                            )}
                        </div>

                    </div>
                    <div className="method-notification">
                        <div className="method card">
                            <h1>Sign-in Method</h1>

                            <form action="">
                                <div className="email">
                                    <div className="field-label">
                                        <label htmlFor="emailaddress">Email Address</label>
                                        <input type="text" name="" id="" value={business.email} />
                                    </div>
                                    <button className="change-email">Change Email</button>
                                </div>
                                <div className="password">
                                    <div className="field-label">
                                        <label htmlFor="password">Password</label>
                                        <input type="text" name="" id="" value={business.password} />
                                    </div>
                                    <button className="password-reset">Reset Password</button>
                                </div>

                            </form>
                        </div>
                        <div className="notification card">
                            <h1>Notifications</h1>
                            <div className="job-update">
                                <p>Job Update</p>
                                <form action="">
                                    <div className="email">
                                        <input type="checkbox" name="" id="" />
                                        <label htmlFor="">Email</label>
                                    </div>
                                    <div className="phone-number">
                                        <input type="checkbox" name="" id="" />
                                        <label htmlFor=""> Phone Number</label>
                                    </div>



                                </form>
                            </div>
                            <hr />

                            <div className="job-update">
                                <p>Applications</p>
                                <form action="">
                                    <div className="email">
                                        <input type="checkbox" name="" id="" />
                                        <label htmlFor="">Email</label>
                                    </div>

                                    <div className="phone-number">
                                        <input type="checkbox" name="" id="" />
                                        <label htmlFor=""> Phone Number</label>
                                    </div>

                                </form>
                            </div>
                            <hr />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default CompanySettings