import React from 'react';
import { Helmet } from 'react-helmet';
import SiteHeader from '../../components/siteHeader';
import '../../assets/css/pages/dash_business/dash_business.css'
const MessagesPage = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <title>Messages | CSR Staffing</title>
            </Helmet>
            <div className="dashboard-content">
                <SiteHeader />
            </div>
        </div>
    )
}

export default MessagesPage