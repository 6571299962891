import React from "react";
import { MEDIA_URL } from "../api";
const BusinessLoginPopup = ({ isOpen, businessInfo, togglePopup }) => {
    const logoutFunction = (event) => {
        event.preventDefault()
        localStorage.clear()
        window.location.href = "/"
    }
    return (
        isOpen && (
            <div className="account-popup">
                <div className="popup-content">
                    <div className="profile-info">
                        {
                            businessInfo.logo
                                ? <img src={`${MEDIA_URL}${businessInfo.logo}`} alt="" className="profile-pic" />
                                : <img src={require('../assets/img/logo-white.png')} alt="" className="profile-pic" />
                        }
                        <div className="name-and-type-popup">
                            <h2 className="name">{businessInfo.name}</h2>
                            <p className="type">Business</p>
                        </div>
                    </div>
                    <div className="settings">
                        <i className="fa-solid fa-gear"></i>
                        <p>Settings</p>
                    </div>
                    <div onClick={logoutFunction} className="logout">
                        <i className="fa-solid fa-arrow-right-from-bracket"></i>
                        <p>Logout</p>
                    </div>
                    <button className="close-popup" onClick={togglePopup}>Close</button>
                </div>
            </div>
        )
    )
}

export default BusinessLoginPopup