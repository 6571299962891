import React, { useState } from "react"
import { Link } from "react-router-dom"
import { ActionButton } from "./ActionButton"
import { Cancel01Icon, Menu03Icon } from "hugeicons-react"
import Branding from "./Branding"


export const PageHeader = ({ action }) => {
    const [displayMobileMenu, setDisplayMobileMenu] = useState(false)

    const displayMenu = () => {
        setDisplayMobileMenu(!displayMobileMenu)
    }

    const handleClick = (e, path) => {
        e.preventDefault()
        const targetId = path.split('#')[1];
        const targetSection = document.getElementById(targetId);

        if (targetSection) {
            const sectionPosition = targetSection.getBoundingClientRect().top + window.scrollY
            window.scrollTo({ top: sectionPosition - 100, behavior: 'smooth' });
            displayMenu()
        }

    }
    return (
        <header className='landing-page-v3-header'>
            <nav className='navigation container'>
                <Branding />
                <ul className={`nav-links ${displayMobileMenu ? 'show-links' : ''}`}>
                    <li className="nav-link"><Link onClick={(e) => handleClick(e, "/#welcome")} to={'/#welcome'}>Home</Link></li>
                    <li className="nav-link"><Link onClick={(e) => handleClick(e, "/#forCandidates")} to={'/#forCandidates'}>Talents</Link></li>
                    <li className="nav-link"><Link onClick={(e) => handleClick(e, "/#forBusiness")} to={'/#forBusiness'}>Business</Link></li>
                    <li className="nav-link"><Link onClick={(e) => handleClick(e, "/#features")} to={'/#features'}>Features</Link></li>
                    <ActionButton action={action} />
                </ul>
                <div onClick={displayMenu} className="mobile-menu-button">
                    {
                        displayMobileMenu
                            ?
                            <Cancel01Icon size={38} />
                            : <Menu03Icon size={38} />
                    }
                </div>
            </nav>
        </header>
    )
}