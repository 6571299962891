import React, { useState } from 'react';
import { useAuthentication } from '../../components/auth/checkAuth';
import { Helmet } from 'react-helmet';
import '../../assets/css/pages/profile_info/profile_info.css'
import SessionExpired from '../auth/sessionExpired';
import api from '../../api';

const ProfileFilesForm = () => {
    const { isAuth } = useAuthentication();
    const [profileImage, setProfileImage] = useState(null);
    const [cvFile, setCvFile] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null);


    const handleImageChange = (e) => {
        setProfileImage(e.target.files[0]);
    };
    const handleCVChange = (e) => {
        setCvFile(e.target.files[0]);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = new FormData();
        if (profileImage) {
            formData.append('profile_image', profileImage);
        }
        if (cvFile) {
            formData.append('cv_file', cvFile);
        }

        try {
            const response = await api.post("/candidates/update-profile/profile-files/", formData)
            if (!response.status === 200) {
                setError(response.data.message)
                setIsLoading(false)
            } else {
                setIsLoading(false)
                localStorage.clear()
                window.location.href = "/dashboard/"
            }
            console.log('Image uploaded successfully');
        } catch (error) {
            console.error('Error uploading image:', error.message);
        }
    };

    return (

        <form onSubmit={handleSubmit}>
            Your profile picture
            <input type="file" onChange={handleImageChange} />
            <br /><br />
            <p>We also need your CV</p>
            <input type="file" onChange={handleCVChange} />
            <button type="submit" className={`button primary-button ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>{isLoading ? <img className='loading-icon' src={require("../../assets/icons/loading.png")} alt="" /> : 'Upload'}</button>
        </form>

    );
};

export default ProfileFilesForm;
