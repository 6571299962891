import api from "../../api";

export const sendApplication = async (jobSlug, showNotification, setIsApplying) => {
    try {
        setIsApplying(true);
        const response = await api.post("/candidates/applications/job/apply/", { "slug": jobSlug });
        if (response.status === 201) {
            setIsApplying(false);
            showNotification('success', 'Your application has been sent');
            window.location.href = "/applications/";
        }
    } catch (error) {
        setIsApplying(false);
        showNotification('fail', `${error.response.data.message}`);
    }
};
