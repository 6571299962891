import React, { useState } from 'react';
import { useNotification } from '../../components/services/notificationContext';
import api from '../../api';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import '../../assets/css/pages/register/register.css'
import BrandIcon from '../../components/services/brandIcon';
import toast from 'react-hot-toast';
import Branding from '../../components/v3/Branding';
import { Loading03Icon } from 'hugeicons-react';

const RegistrationPage = () => {
    localStorage.clear()
    const [isLoading, setIsLoading] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false)
    const [error, setError] = useState(null);
    const [currentStep, setCurrentStep] = useState(1)

    // form fields
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [gender, setGender] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [accountType, setAccountType] = useState("")

    const handleSteps = () => {
        setCurrentStep(currentStep === 2 ? currentStep - 1 : currentStep + 1)
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError(null);

        if (!email || !password || !phoneNumber || !firstName || !lastName || !dateOfBirth || !gender || !accountType) {
            setError('Please fill in all fields');
            setIsLoading(false);
            return;
        }
        try {
            const credentials = {
                "first_name": firstName,
                "last_name": lastName,
                "phone_number": phoneNumber,
                "gender": gender,
                "date_of_birth": dateOfBirth,
                "email": email,
                "password": password,
                "account_type": accountType
            }

            const response = await api.post("/accounts/register/", credentials)
            console.log(error)
            if (response.status === 201) {
                if (accountType === 'candidate') {
                    window.location.href = '/thank-you/'
                    return
                } else if (accountType === 'business') {
                    window.location.href = '/business/register/'
                    return
                }
            }
        } catch (error) {
            console.log(error)
            if (error.response) {
                toast.error(error.response.data.message || "Unknown error")
            } else {
                toast.error("fail", "Network error")
            }
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <title>Registration | jobMatch</title>
            </Helmet>

            <div className="page-content">

                <div className="container auth-forms">
                    <Branding />
                    <p>CSR jobMatch offer a centralized repository for talent showcasing and recruitment.</p>
                    <p className="">If you already have an account <span className='form-link'><Link to="/login">Login</Link></span></p>
                    <form action="" className="registerForm">
                        {error && (<div className='error-message'>{error}</div>)}
                        {
                            currentStep < 2 ?
                                <>
                                    <div className="half">
                                        <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" name="firstName" id="firstName" placeholder='Your first name' />
                                        <input value={lastName} onChange={(e) => setLastName(e.target.value)} type="text" name="lastName" id="lastName" placeholder='Your last name' />
                                    </div>
                                    <div className="half">
                                        <input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} type="text" name="phoneNumber" id="phoneNumber" placeholder='Phone number' />
                                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" placeholder='Your email' />
                                    </div>
                                    <div className="half">
                                        <div className="field">
                                            <p>Date of birth</p>
                                            <input value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} type="date" name="dateOfBirth" id="dateOfBirth" placeholder='Date of birth' />

                                        </div>
                                        <div className="field">
                                            <p>Gender</p>
                                            <select value={gender} onChange={(e) => setGender(e.target.value)} name="gender" id="gender">
                                                <option value="" disabled>Select gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>

                                    <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" id="password" placeholder='Your password' />


                                </>
                                :
                                <>
                                    <div className="account-choices">
                                        <h2>
                                            What brings you to CSR jobMatch
                                        </h2>
                                        <div className="profile-choices">
                                            <div onClick={() => setAccountType('candidate')} className={`choice ${accountType === 'candidate' ? 'selected' : ''}`}>
                                                <h4>I’m looking for job</h4>
                                                <p>Find a work whether its part-time, full-time and Remote</p>
                                            </div>
                                            <div onClick={() => setAccountType('business')} className={`choice ${accountType === 'business' ? 'selected' : ''}`}>
                                                <h4>I’m hiring</h4>
                                                <p>
                                                    Post opportunities and discover employee
                                                </p>
                                            </div>
                                        </div>
                                        <p className='form-footer'>
                                            By continuing you agree to CSR jobMatch <span><Link className='form-link'> terms of use </Link></span> and confirm that you have read CSR jobMatch <span><Link className='form-link'>privacy-policy</Link></span>
                                        </p>
                                    </div>
                                </>
                        }
                        {/* <span>Or</span>
                            <div className="button tertiary-button">
                            <img src={require("../../assets/icons/google.png")} alt="" />
                            <span>Sing up with google</span>
                            </div> */}
                        <div className="buttons">
                            <button type='button' onClick={handleSteps} className='secondary-button' >{currentStep < 2 ? 'Continue' : 'Back'}</button>
                            {
                                currentStep === 2
                                    ? <button type='button' onClick={handleSubmit} id="RegisterButton" className={`button primary-button ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>{isLoading ? <Loading03Icon className='loading-icon' size={20} /> : 'Register'}</button>
                                    : ''
                            }
                        </div>

                    </form>
                </div>

            </div>
        </div>

    )
}

export default RegistrationPage