import React from 'react';
import { useAuthentication } from '../components/auth/checkAuth';
import { Helmet } from 'react-helmet';
import SiteHeader from '../components/siteHeader';
const TrainingsPage = () => {
    const { isAuth } = useAuthentication();
    const logoutFunction = (event) => {
        event.preventDefault()
        localStorage.clear()
        window.location.href = "/"
    }
    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Trainings | CSR Staffing</title>
            </Helmet>
            <div className="main">
                <SiteHeader />
                <button onClick={logoutFunction} className="button primary-button">Logout</button>
            </div>
        </div>
    )
}

export default TrainingsPage