import React from 'react';
import { Helmet } from 'react-helmet';
import { API_URL } from '../api';
import FetchData from '../components/services/fetchData';
import { useAuthentication } from '../components/auth/checkAuth';
import SiteHeader from '../components/siteHeader';
import PageFooter from '../components/footer';
import JobsContainer from '../components/jobsContainer';
import { Link } from 'react-router-dom';
import '../assets/css/pages/index_business/index_business.css';
import benefits from '../assets/img/pages/index_candidates/benefits.jpg';




const IndexBusinessPage = () => {
    const { isAuth } = useAuthentication();
    const jobsUrl = `/${API_URL}/jobs/`;

    const jobsData = FetchData(jobsUrl)
    return (

        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <title>Businesses | CSR jobMatch</title>
            </Helmet>
            <div className="main">
                <SiteHeader />
                {/* <button onClick={logoutFunction} className="button primary-button">Logout</button> */}
                <section className="hero-section">
                    <div className="container">
                        <div className="hero-content">
                            <h1 className='hero-title'>
                                Welcome to <br /> <span className='color-title'>CSR jobMatch!</span>
                            </h1>
                            <p>
                                Find top talent, streamline your hiring process, and build your dream team with ease.
                            </p>

                            <Link to={'/dashboard/'} className="primary-button button">
                                Post a Job
                            </Link>

                            <div className="active-users">
                                <div className="sub-title"><h3>Active Users</h3> <div className="line"></div></div>
                                <p>Over <span className='users count'>100K</span> satisfied and happy user around the world</p>
                                <div className="users-profiles">
                                    <div className="profile"> <img src={require("../assets/img/pages/index_candidates/reviews/1.png")} alt="" /></div>
                                    <div className="profile"> <img src={require("../assets/img/pages/index_candidates/reviews/Ellipse 141.png")} alt="" /></div>
                                    <div className="profile"> <img src={require("../assets/img/pages/index_candidates/reviews/Ellipse 142.png")} alt="" /></div>
                                    <div className="profile"> <img src={require("../assets/img/pages/index_candidates/reviews/Ellipse 143.png")} alt="" /></div>
                                    <div className="profile"> <img src={require("../assets/img/pages/index_candidates/reviews/Ellipse 144.png")} alt="" /></div>
                                </div>
                            </div>
                        </div>
                        <div className="hero-img">

                            <div className="verified-candidate">
                                <i className="fa-regular fa-square-check"></i>
                                <div className="verify">
                                    <h4>Verified Candidate</h4>
                                    <p>Candidate that fits with your ambitions</p>
                                </div>

                            </div>
                            <div className="name-role">
                                <img src={require("../assets/img/pages/index_candidates/benefits.jpg")} alt="" srcset="" />
                                <div className="nameRole">
                                    <h4>Amour Cyusa Bahizi</h4>
                                    <p>ui/ux designer</p>
                                </div>



                            </div>
                            <img src={require("../assets/img/pages/index_candidates/benefits.jpg")} alt="" srcset="" />

                        </div>

                    </div>

                </section>
                <section className="featured-jobs">
                    <div className="container">
                        <div className="section-header">
                            <div className="text">
                                <h1 className="title">
                                    Featured <span className="color-title">Candidates</span>
                                </h1>
                                <p>
                                    Explore highlighted different standout candidates that <br /> fits your preferences

                                </p>
                            </div>
                            <Link className='on-page-link' to="/jobs/" ><span>View all</span> <i class="fa-solid fa-arrow-right"></i></Link>
                        </div>

                        {/* <JobsContainer jobs={jobsData.data} isAuth={isAuth} /> */}
                        <div className="cards">
                            <div className="card">
                                <img src={benefits} alt="" srcset="" />
                                <h3>Amour Cyusa Bahizi</h3>
                                <h4>Product Designer</h4>
                                <p><i class="fa-solid fa-map-pin"></i>Kigali,Rwanda</p>
                                <Link>View Profile</Link>
                            </div>
                            <div className="card">
                                <img src={benefits} alt="" srcset="" />
                                <h3>Amour Cyusa Bahizi</h3>
                                <h4>Product Designer</h4>
                                <p><i class="fa-solid fa-map-pin"></i>Kigali,Rwanda</p>
                                <Link>View Profile</Link>
                            </div>
                            <div className="card">
                                <img src={benefits} alt="" srcset="" />
                                <h3>Amour Cyusa Bahizi</h3>
                                <h4>Product Designer</h4>
                                <p><i class="fa-solid fa-map-pin"></i>Kigali,Rwanda</p>
                                <Link>View Profile</Link>
                            </div>
                        </div>


                    </div>
                </section>
                <section className="how-it-works">
                    <div className="container">
                        <div className="section-header center">
                            <div className="text">
                                <h1 className="title">
                                    How it <span className="color-title">works</span>
                                </h1>
                                <p>
                                    Here is an overview of the process you can expect <br /> when using our platform or service,
                                </p>
                            </div>
                        </div>

                        <div className="how-container profile">
                            <div className="cards">
                                <div className="card">
                                    <div className="job-title">
                                        <div className="name">
                                            <i className="fa-brands fa-spotify"></i>
                                            <div className="job-name">
                                                <h4>Sinior UI/UX Designer</h4>
                                                <div className="job-statistic">
                                                    <p>Spotify</p> <div className='dot'></div> <p>55 Applicants</p>
                                                </div>

                                            </div>
                                        </div>
                                        <i className="fa-regular fa-star"></i>

                                    </div>
                                    <div className="job-description">
                                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, molestias?</p>
                                    </div>
                                    <div className="job-type">
                                        <p><i className="fa-regular fa-chart-bar"></i>Intermediate</p>
                                        <p><i className="fa-solid fa-globe"></i>Full-time</p>
                                        <p><i className="fa-solid fa-briefcase"></i>Remote</p>
                                    </div>
                                    <div className="job-price">
                                        <p>300K/<span className='month'>Month</span></p>
                                        <p className='time'><i className="fa-regular fa-clock"></i>Posted 1 hr ago</p>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="job-title">
                                        <div className="name">
                                            <i className="fa-brands fa-spotify"></i>
                                            <div className="job-name">
                                                <h4>Sinior UI/UX Designer</h4>
                                                <div className="job-statistic">
                                                    <p>Spotify</p> <div className='dot'></div> <p>55 Applicants</p>
                                                </div>

                                            </div>
                                        </div>
                                        <i className="fa-regular fa-star"></i>

                                    </div>
                                    <div className="job-description">
                                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, molestias?</p>
                                    </div>
                                    <div className="job-type">
                                        <p><i className="fa-regular fa-chart-bar"></i>Intermediate</p>
                                        <p><i className="fa-solid fa-globe"></i>Full-time</p>
                                        <p><i className="fa-solid fa-briefcase"></i>Remote</p>
                                    </div>
                                    <div className="job-price">
                                        <p>300K/<span className='month'>Month</span></p>
                                        <p className='time'><i className="fa-regular fa-clock"></i>Posted 1 hr ago</p>
                                    </div>
                                </div>

                            </div>

                            <div className="text">
                                <div className="step">
                                    <p>1</p>
                                </div>
                                <div className="how-text">
                                    <h2 className="how-title">
                                        Post a Job
                                    </h2>
                                    <p>Submit job listings, with detailed  requirements and preferences for potential candidates.</p>
                                </div>
                            </div>
                        </div>

                        <div className="how-container job-search">
                            <div className="text">
                                <div className="step">
                                    <p>2</p>
                                </div>
                                <div className="how-text">
                                    <h2 className="how-title">
                                        Review Candidate Profiles
                                    </h2>
                                    <p>Navigate through profiles of applicants, assess their  qualifications and suitability for the position.</p>
                                </div>
                            </div>
                            <div className="cards">
                                <div className="card">
                                    <img src={benefits} alt="" srcset="" />
                                    <h3>Amour Cyusa Bahizi</h3>
                                    <h4>Product Designer</h4>
                                    <p><i class="fa-solid fa-map-pin"></i>Kigali,Rwanda</p>
                                    <Link>View Profile</Link>
                                </div>
                                <div className="card">
                                    <img src={benefits} alt="" srcset="" />
                                    <h3>Amour Cyusa Bahizi</h3>
                                    <h4>Product Designer</h4>
                                    <p><i class="fa-solid fa-map-pin"></i>Kigali,Rwanda</p>
                                    <Link>View Profile</Link>
                                </div>

                            </div>

                        </div>

                        <div className="how-container company">

                            <div className="chats card">
                                <div className="upper-chats">
                                    <div className="profile">
                                        <img src={require("../assets/img/pages/index_candidates/benefits.jpg")} alt="" className="partner" />
                                        <div className="names">
                                            <h4>Amour Cyusa Bahizi</h4>
                                            <p>UI/UX Designer</p>
                                        </div>
                                    </div>
                                    <div className="icons-view">
                                        <i class="fa-solid fa-thumbtack"></i>
                                        <i class="fa-regular fa-star"></i>
                                        <i class="fa-solid fa-ellipsis-vertical"></i>
                                        <Link>View Profile</Link>
                                    </div>
                                </div>
                                <div className="chart-contents">
                                    <div className="sender">
                                        <div className="profile-img">
                                            <h4>You</h4>
                                            <div className="profile"> <img src={require("../assets/img/pages/index_candidates/reviews/1.png")} alt="" /></div>
                                        </div>

                                        <div className="text">
                                            <p className='sender-text'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facere eveniet ex quibusdam facilis explicabo ut necessitatibus dignissimos, aspernatur molestiae adipisci.</p>
                                        </div>

                                        <p className='sender-time'>12 mins ago</p>
                                    </div>

                                </div>
                                <div className="receiver-contents">
                                    <div className="receiver">
                                        <div className="profile-img">
                                            <div className="profile"> <img src={require("../assets/img/pages/index_candidates/reviews/1.png")} alt="" /></div>
                                            <h4>Amour Cyusa Bahizi</h4>

                                        </div>

                                        <div className="receiver-text">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, numquam!</p>
                                        </div>

                                        <p className='receiver-time'>12 mins ago</p>
                                    </div>

                                </div>
                            </div>


                            <div className="text">
                                <div className="step">
                                    <p>3</p>
                                </div>
                                <div className="how-text">
                                    <h2 className="how-title">
                                        Connect with Top Talent
                                    </h2>
                                    <p>
                                        Engage with promising candidates, initiating communication and further evaluation to facilitate the hiring process.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="benefits">
                    <div className="container">
                        <img className='img' src={require("../assets/img/pages/index_candidates/benefits.jpg")} alt="" />
                        <div className="content">
                            <h1 className="title"><span>Enjoy</span> <span className="color-title">Benefits</span></h1>
                            <p>
                                Simplify your hiring by taking advantage of  CSR jobMatch
                            </p>

                            <ul className="benefits-list">
                                <li className="benefit"><i className="fa-solid fa-arrow-right"></i><p>Access to a wide range of job opportunities </p></li>
                                <li className="benefit"><i className="fa-solid fa-arrow-right"></i><p>Personalized job recommendations based on your profile  </p></li>
                                <li className="benefit"><i className="fa-solid fa-arrow-right"></i><p>Seamless communication with employers throughout the application process  </p></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="reviews-section">
                    <div className="container">
                        <div className="section-header center">
                            <div className="text">
                                <h1 className="title">
                                    <span className="color-title">Reviews</span> of People who have found employees through CSR jobMatch
                                </h1>

                            </div>
                        </div>

                        <div className="reviews">
                            <div className="review">
                                <i className="fa-solid fa-quote-right quote"></i>
                                <p>
                                    "CSR jobMatch has been instrumental in helping us find top talent for our team. The platform is easy to use and provides access to a wide range of qualified candidates."
                                </p>
                                <p className="reviewer-name">Emely</p>
                                <p className="reviewer-position">
                                    HR Manager at CocaCola
                                </p>
                            </div>

                            <div className="reviews-profiles">
                                <div className="profile"> <img src={require("../assets/img/pages/index_candidates/reviews/1.png")} alt="" /></div>
                                <div className="profile"> <img src={require("../assets/img/pages/index_candidates/reviews/Ellipse 141.png")} alt="" /></div>
                                <div className="profile active"> <img src={require("../assets/img/pages/index_candidates/reviews/Ellipse 142.png")} alt="" /></div>
                                <div className="profile"> <img src={require("../assets/img/pages/index_candidates/reviews/Ellipse 143.png")} alt="" /></div>
                                <div className="profile"> <img src={require("../assets/img/pages/index_candidates/reviews/Ellipse 144.png")} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="partners-section">
                    <div className="container">
                        <div className="section-header center">
                            <div className="text">
                                <h1 className="title">
                                    More than <span className="color-title">100 companies </span> sponsorship with us
                                </h1>

                            </div>
                        </div>

                        <div className="partners">
                            <img src={require("../assets/img/pages/index_candidates/partners/adidas_logo.svg-1.png")} alt="" className="partner" />
                            <img src={require("../assets/img/pages/index_candidates/partners/trivago_logo.svg-1.png")} alt="" className="partner" />
                            <img src={require("../assets/img/pages/index_candidates/partners/adidas_logo.svg-1.png")} alt="" className="partner" />
                            <img src={require("../assets/img/pages/index_candidates/partners/trivago_logo.svg-1.png")} alt="" className="partner" />
                            <img src={require("../assets/img/pages/index_candidates/partners/trivago_logo.svg-1.png")} alt="" className="partner" />
                        </div>
                    </div>
                </section>
                <PageFooter />
            </div>
        </div>
    )
}

export default IndexBusinessPage