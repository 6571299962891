import { React, useState, useEffect } from "react";
import { sendApplication } from "../../components/services/sendApplication";
import { useAuthentication } from "../../components/auth/checkAuth";
import { useNotification } from "../../components/services/notificationContext";
import SessionExpired from "../auth/sessionExpired";
import { API_URL } from "../../api";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import api from "../../api";
import SiteHeader from "../../components/siteHeader";
import FetchData from "../../components/services/fetchData";

import '../../assets/css/pages/single_blog/singleBlogPage.css'
import JobsContainer from "../../components/jobsContainer";
import IsLoading from "../../components/loadingIcon";

const SingleBlogPage = () => {
    const { isAuth } = useAuthentication()
    const { showNotification } = useNotification()
    const jobsUrl = `${API_URL}/jobs/jobs/all/`;
    const jobsData = FetchData(jobsUrl)
    const { jobSlug } = useParams();
    const [jobObj, setJobObj] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isApplying, setIsApplying] = useState(false)
    const [alreadyApplied, setAlreadyApplied] = useState(false)

    const handSendApplication = async (event) => {
        event.preventDefault()
        sendApplication(jobSlug, showNotification, setIsApplying)
    }

    useEffect(() => {
        const fetchBlog = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`jobs/job/${jobSlug}/`);
                if (response.status === 200) {
                    setJobObj(response.data.job);
                    setAlreadyApplied(response.data.user_applied);
                    console.log("saved data", jobObj)
                    setIsLoading(false)
                }
            } catch (error) {
                // Handle errors
            } finally {
                setIsLoading(false);
            }
        };
        fetchBlog();
    }, []);

    return (
        isAuth ?
            <div>
                <Helmet>
                    <meta charSet="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link rel="icon" href="favicon.ico" type="image/x-icon" />
                    <title>Job</title>
                </Helmet>

                <div className="dashboard-content">
                    <SiteHeader />
                    <div className="bread-crumbs container">
                        <p>Dashboard /</p>
                        <p>Jobs List /</p>
                        {/* <p className="current">{jobObj.name}</p> */}
                    </div>
                    <div className="single-job-content container">
                        <JobsContainer jobs={jobsData.data} />
                        <div className="single-job">
                            <div className="filters card">
                                <div className="input">
                                    <div className="icon">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                    <input type="search" name="" id="" placeholder="Enter something to search" />
                                </div>
                                <div className="divider"></div>
                                <div className="input">
                                    <div className="icon">
                                        <i className="fa-solid fa-globe"></i>
                                        <i className="fa-solid fa-magnifying-glass search"></i>
                                    </div>

                                    <select name="" id="">
                                        <option value="">Kigali</option>
                                        <option value="">Kicukiro</option>
                                    </select>
                                </div>
                            </div>
                            {
                                isLoading ? (
                                    <IsLoading text={"job's data"} />
                                )
                                    : Object.keys(jobObj).length > 0 && (
                                        <div className="job-content">
                                            <div className="job-details card">
                                                <div className="details-head">
                                                    <h2 className="job-title">
                                                        {jobObj.name}
                                                    </h2>
                                                    <div className="jobs-meta">
                                                        <div className="experience-level meta">
                                                            <i className="fa-solid fa-bars-progress"></i>
                                                            <p className="level">{jobObj.experience_level}</p>
                                                        </div>

                                                        <div className="work-type meta">
                                                            <i className="fa-solid fa-globe"></i>
                                                            <p className="type">{jobObj.work_type}</p>
                                                        </div>

                                                        <div className="job-type meta">
                                                            <i className="fa-solid fa-briefcase"></i>
                                                            <p className="type">{jobObj.job_type}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h3 className="about-the-job">About the Job</h3>
                                                <div className="job-content">
                                                    {jobObj.job_details}
                                                </div>
                                            </div>


                                            <div className="company-details card">
                                                <div className="branding">
                                                    <h4 className="company-title">{jobObj.company_name.name}</h4>
                                                    <div className="logo">
                                                        <img src={require("../../assets/img/logo.png")} alt="" className="company-logo" />
                                                    </div>
                                                </div>

                                                <div className="company-content">

                                                    <div className="establishment">
                                                        <h4>Founded At</h4>
                                                        <p>{jobObj.company_name.established_at}</p>
                                                    </div>

                                                    <div className="location">
                                                        <h4>Location</h4>
                                                        <p>{jobObj.company_name.address}</p>
                                                    </div>

                                                    <div className="employees">
                                                        <h4>Employees</h4>
                                                        <p>{jobObj.company_name.employees}</p>
                                                    </div>

                                                    <div className="industry">
                                                        <h4>Industry</h4>
                                                        <p>{jobObj.company_name.industry.name}</p>
                                                    </div>

                                                    <hr />
                                                    <div className="services">
                                                        <p className="service">Web & App Design</p>
                                                        <p className="service">Backend</p>
                                                        <p className="service">Accounting</p>
                                                        <p className="service">Frontend</p>
                                                        <p className="service">Cybersecurity</p>
                                                        <p className="service">Data Science</p>
                                                        <p className="service">Offensive Security</p>
                                                    </div>
                                                    {alreadyApplied ?
                                                        <p className="button tertiary-button">You have applied</p>
                                                        :
                                                        <button onClick={handSendApplication} className="button primary-button" disabled={isApplying}>{isApplying ? <img className='loading-icon' src={require("../../assets/icons/loading.png")} alt="loading"></img> : 'Apply Now'}</button>
                                                    }
                                                </div>
                                            </div>


                                        </div>
                                    )
                            }

                        </div>
                    </div>

                </div>
            </div>
            :
            <SessionExpired />
    )
}

export default SingleBlogPage