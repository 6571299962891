import { Link06Icon, Search01Icon, SquareArrowLeft02Icon, UserSquareIcon } from "hugeicons-react"
import React from "react"

export const candidatesSteps = [
    {
        "step": "01",
        "icon": "",
        "title": "Sign up",
        "description": "Create your profile to get started on finding your dream job",
        "imageLink": '/images/landingv2/candidates/image1.png',
        "iconClass": <SquareArrowLeft02Icon />
    },
    {
        "step": "02",
        "icon": "", "title": "Find Job",
        "description": "Browse and search for job opportunities that match your skills and interests.",
        "imageLink": '/images/landingv2/candidates/image1.png',
        "iconClass": <Search01Icon />
    },
    {
        "step": "03",
        "icon": "",
        "title": "Get Matched",
        "description": "Receive personalized job recommendations based on your profile.",
        "imageLink": '/images/landingv2/candidates/image1.png',
        "iconClass": <Link06Icon />

    },
    {
        "step": "04",
        "icon": "",
        "title": "Get Hired",
        "description": "Apply for jobs and connect with potential employers to secure your next role",
        "imageLink": '/images/landingv2/candidates/image1.png',
        "iconClass": <UserSquareIcon />
    }
]

export const businessSteps = [
    {
        "step": "01",
        "icon": "",
        "title": "Sign up",
        "description": "Create your profile to get started on finding your dream job",
        "imageLink": '/images/landingv2/candidates/image1.png',
        "iconClass": <SquareArrowLeft02Icon />
    },
    {
        "step": "02",
        "icon": "",
        "title": "Post Job",
        "description": "Add job listings to attract the right talent for your needs.",
        "imageLink": '/images/landingv2/candidates/image1.png',
        "iconClass": <Search01Icon />
    },
    {
        "step": "03",
        "icon": "",
        "imageLink": '/images/landingv2/candidates/image1.png',
        "title": "Get Applications",
        "description": "Receive applications from qualified candidates tailored to your job requirements.",

        "iconClass": <Link06Icon />
    },
    {
        "step": "04",
        "icon": "",
        "title": "Hire a Talent",
        "description": "Review applications, interview candidates, and hire the best fit for your team",
        "imageLink": '/images/landingv2/candidates/image1.png',
        "iconClass": <UserSquareIcon />
    }

]

export const jobMatchFeatures = [
    {
        "title": "Personalized Job Recommendations",
        "description": "Get tailored job suggestions based on your profile and preferences",
        "imageLink": '/images/landingv2/features/feature1.png',
    },
    {
        "title": "Easy Application Process",
        "description": "Apply for jobs with a streamlined and user-friendly application process.",
        "imageLink": '/images/landingv2/features/feature1.png',
    },
    {
        "title": "Exclusive Access to Top Employers",
        "description": "Connect with leading employers who are looking for talent like yours.",
        "imageLink": '/images/landingv2/features/feature1.png',
    },
    {
        "title": "Exclusive Access to Top Candidates",
        "description": "Apply for jobs with a streamlined and user-friendly application process.",
        "imageLink": '/images/landingv2/features/feature1.png',
    }
]
