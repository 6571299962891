import React from 'react';

function PdfPreview({ url }) {
    return (
        <iframe
            src={url}
            title="PDF Preview"
            width="100%"
            height="500px" // Adjust height as needed
        />
    );
}

export default PdfPreview;
