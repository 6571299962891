import React from 'react'
import { Helmet } from 'react-helmet'
import { PageHeader } from '../../components/v3/PageHeader'
import PageFooterV3 from '../../components/v3/PageFooter'
import { SectionTitle } from '../../components/v3/SectionTitle'

import '../../assets/css/pages/legal/legal.css'
const TermsAndConditions = () => {
    return (
        <div>
            <Helmet>
                <title>Terms and conditions | Job match</title>
            </Helmet>
            <PageHeader />
            <div className="terms-and-conditions-page">
                <div className="container">
                    <SectionTitle subTitle={'Effective Date: 08.06.2024'} mainTitle={'Terms and conditions'} />

                    <div className='terms-and-conditions'>
                        <div className="section">
                            <h2>1. Introduction</h2>
                            <p>Welcome to CSR Jobmatch. These Terms and Conditions govern your use of our platform. By accessing or using CSR Jobmatch, you agree to comply with these terms. If you do not agree with these terms, please do not use our platform.</p>
                        </div>
                        <div className="section list">
                            <h2>2. Definitions</h2>
                            <div className="list-content">
                                <p><strong>"Platform"</strong> refers to the CSR Jobmatch website and any related services.</p>
                                <p><strong>“Users”</strong> includes job seekers, employers, and any other individuals who use our platform.</p>
                                <p><strong>“Content”</strong> refers to any data, information, text, graphics, or other materials uploaded or displayed on our platform.</p>

                            </div>
                        </div>

                        <div className="section list">
                            <h2>3. User accounts</h2>
                            <div className="list-content">
                                <p><strong>Registration: </strong>To access certain features, you must create an account by providing accurate and complete information.</p>
                                <p><strong>Account Responsibility: </strong>You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

                            </div>
                        </div>

                        <div className="section list">
                            <h2>4. Use of the Platform</h2>
                            <div className="list-content">
                                <p><strong>Permitted Use:</strong> You agree to use the platform only for lawful purposes and in accordance with these Terms and Conditions.</p>
                                <p><strong>Prohibited Conduct:</strong> You may not use the platform for any illegal or unauthorized purposes, including but not limited to posting false information, harassing other users, or transmitting malware.</p>
                            </div>
                        </div>

                        <div className="section list">
                            <h2>5. Content</h2>
                            <div className="list-content">
                                <p><strong>User Content:</strong> You retain ownership of any content you upload to the platform, but grant CSR Jobmatch a license to use, display, and distribute that content for the purpose of providing our services.</p>
                                <p><strong>Platform Content:</strong> All content provided on the platform, excluding user content, is owned by CSR Jobmatch or its licensors and may not be copied, modified, or distributed without permission.</p>
                            </div>
                        </div>

                        <div className="section list">
                            <h2>6. Job Listings and Applications</h2>
                            <div className="list-content">
                                <p><strong>Accuracy:</strong> Employers are responsible for ensuring that job listings are accurate and comply with applicable laws. Job seekers are responsible for the accuracy of their profiles and applications.</p>
                                <p><strong>No Guarantee:</strong> CSR Jobmatch does not guarantee the accuracy of job listings, the success of job applications, or the outcome of any job search or hiring process.</p>

                            </div>
                        </div>

                        <div className="section list">
                            <h2>7. Fees and Payments</h2>
                            <div className="list-content">
                                <p><strong>Fees:</strong> Certain features of the platform may require payment. All fees are described on our platform and are subject to change.</p>
                                <p><strong>Payment Terms:</strong> Payments must be made using the methods specified on the platform. Any fees paid are non-refundable, except as required by law.</p>
                            </div>
                        </div>

                        <div className="section list">
                            <h2>8. Termination</h2>
                            <div className="list-content">
                                <p><strong>Termination by Us:</strong> We may suspend or terminate your access to the platform at any time if you breach these Terms and Conditions or for any other reason at our discretion.</p>
                                <p><strong>Termination by You:</strong> You may deactivate your account at any time by following the instructions on the platform.</p>
                            </div>
                        </div>

                        <div className="section list">
                            <h2>9. Limitation of Liability</h2>
                            <div className="list-content">
                                <p><strong>Disclaimer:</strong> The platform is provided “as is” without warranties of any kind, either express or implied. CSR Jobmatch does not guarantee the availability, reliability, or accuracy of the platform.</p>
                                <p><strong>Limitation:</strong> To the fullest extent permitted by law, CSR Jobmatch is not liable for any indirect, incidental, special, or consequential damages arising from your use of the platform.</p>
                            </div>
                        </div>

                        <div className="section">
                            <h2>10. Indemnification</h2>
                            <p>You agree to indemnify and hold CSR Jobmatch, its affiliates, and their respective officers, directors, employees, and agents harmless from any claims, liabilities, damages, losses, or expenses arising from your use of the platform or violation of these Terms and Conditions.</p>
                        </div>

                        <div className="section">
                            <h2>11. Governing Law</h2>
                            <p>These Terms and Conditions are governed by and construed in accordance with the laws of Rwanda. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts in Rwanda.</p>
                        </div>
                        <div className="section">
                            <h2>12. Changes to These Terms</h2>
                            <p>We may update these Terms and Conditions from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of the platform constitutes acceptance of the revised terms.</p>
                        </div>
                        <div className="section">
                            <h2>13. Contact Us</h2>
                            <p>If you have any questions about these Terms and Conditions, please contact us at:</p>
                            <h3>CSR Jobmatch</h3>
                            <p>Kigali, Rwanda </p>
                            <p>csrjobmatch@gmail.com</p>
                            <p>+250791902159</p>
                        </div>
                    </div>
                </div>
            </div>
            <PageFooterV3 />
        </div>
    )
}

export default TermsAndConditions
