import React, { useState } from 'react';
import api from '../../api';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useAuthentication } from '../../components/auth/checkAuth';
import { useNotification } from '../../components/services/notificationContext';
import '../../assets/css/pages/profile_info/profile_info.css'
import LoginPage from '../auth/login';

const UpdateAcademicEducationPage = () => {
    const { isAuth } = useAuthentication()
    const { showNotification } = useNotification()
    const [isLoading, setIsLoading] = useState(false);

    const [schoolName, setSchoolName] = useState("")
    const [degreeName, setDegreeName] = useState("")
    const [startingYear, setStartingYear] = useState("")
    const [endingYear, setEndingYear] = useState("")

    const SubmitEducationInfo = async (event) => {
        event.preventDefault()
        if (!schoolName || !degreeName || !startingYear || !endingYear) {
            showNotification('fail', "All fields are required")
            return
        }
        setIsLoading(true)

        const candidateInfo = {
            "school_name": schoolName,
            "degree_attained": degreeName,
            "started_year": startingYear,
            "ended_year": endingYear,
        }

        try {
            const response = await api.post("/candidates/update-profile/academic-education/", candidateInfo)
            if (response.status === 200) {
                window.location.href = "/candidate/profile/files/"
            }
        } catch (error) {
            if (error.response.data) {

                showNotification("fail", error.response.data.message || "Network error saving education info")
                setIsLoading(false)
                return
            }
        } finally {
            setIsLoading(false);
            return
        }
    }

    return (
        isAuth ?
            <div>
                <Helmet>
                    <meta charSet="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link rel="icon" href="favicon.ico" type="image/x-icon" />
                    <title>Academic education | taleConnect</title>
                </Helmet>
                <div className="page-content">
                    <div className="form-content col">
                        <Link to="/candidate/work-experience/form/" className='button outline-button enroll-back-button'>Back</Link>
                        <div className="auth-forms">
                            <img className='logo' src={require("../../assets/img/logo.png")} alt="logo" />
                            <h2 className="title">Academic Education</h2>
                            <p>Tell us about your  recent academic education </p>
                            <form action="" className="proFileInfoForm" encType='multipart/form-data'>
                                <input value={schoolName} onChange={(e) => setSchoolName(e.target.value)} type="text" name="schoolName" id="schoolName" placeholder='School name' />
                                <input value={degreeName} onChange={(e) => setDegreeName(e.target.value)} type="text" name="degreeName" id="degreeName" placeholder='Degree name' />
                                <div className="paired-input">
                                    <input value={startingYear} onChange={(e) => setStartingYear(e.target.value)} type="text" name="startingYear" id="startingYear" placeholder='Started in' />
                                    <input value={endingYear} onChange={(e) => setEndingYear(e.target.value)} type="text" name="endingYear" id="endingYear" placeholder='Ended in' />
                                </div>
                                <button onClick={SubmitEducationInfo} id="SubmitEducationInfo" className={`button primary-button ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>{isLoading ? <img className='loading-icon' src={require("../../assets/icons/loading.png")} alt="" /> : 'Update'}</button>
                            </form>
                        </div>
                    </div> 
                    <div className="background-col col">
                        {/* Content of background-col */}
                    </div>
                    
                </div>
            </div>
            :
            <LoginPage></LoginPage>
    )
}

export default UpdateAcademicEducationPage