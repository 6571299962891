import { format, parseISO, differenceInYears } from 'date-fns';

const CalculateAge = ({ birthDay }) => {
    const birthDate = parseISO(birthDay);

    const currentDate = new Date();

    const age = differenceInYears(currentDate, birthDate);

    return age
};

export default CalculateAge;