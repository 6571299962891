import React from "react";
import '../../assets/css/companyProfile/employee-list.css'

const CompanyProfileEmployees = ({ employees }) => {
    console.log("Employees", employees)




    return (
        <div>
            <div className="company-profile-employees card">
                <h3>Company employees</h3>
                <p>
                    These are the applicants that were hired though the platform.
                </p>
            </div>
            <div className="company-employees">

                {employees.map((employee, index) => (

                    <div className="card">

                        <div className="employee-info" key={index}>
                            <div className="info-icon">
                                <div className="names">
                                    <div className="name-abbreviation">
                                        <p>{employee.user.first_name.charAt(0)}{employee.user.last_name.charAt(0)}</p>
                                    </div>

                                    <div className="names-email">
                                        <h4>{employee.user.first_name} {employee.user.last_name}</h4>
                                        <p>{employee.user.email}</p>
                                    </div>
                                </div>
                                <i className="fa-solid fa-ellipsis"></i>
                            </div>

                            <div className="posititon-experience">
                                <div className="position">
                                    <p>Position</p>
                                    <h4>{employee.employment_title}</h4>
                                </div>
                                <div className="experience">
                                    <p>Experience</p>
                                    <h3>{employee.work_experience}</h3>
                                    {/* <h4>3 Years</h4> */}

                                </div>
                            </div>
                        </div>

                        <div className="employee-skills">
                            {/* <p>Web & App Design</p>
                            <p>Backend</p>
                            <p>Data Science</p>
                            <p>Accounting</p>
                            <p>Cybersecurity</p>
                            <p>Frontend</p>
                            <h4>+4 More</h4> */}


                            {employee.skills.map((skill, index) => (
                                <p key={index}>{skill.name}</p>
                            ))}


                        </div>
                    </div>
                ))}
            </div>

        </div>
    );
}

export default CompanyProfileEmployees;
