import { React, createContext, useState, useContext } from "react";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);
export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState(null)

    const showNotification = (type, message) => {
        setNotification({ type, message });

        setTimeout(() => {
            setNotification(null);
        }, 5000);
    }

    return (
        <NotificationContext.Provider value={{ showNotification }}>
            {children}
            {notification && (
                <div className={`notification-popup ${notification.type}`}>
                    <p className="notification">{notification.message}</p>
                </div>
            )}
        </NotificationContext.Provider>
    );
}