import { useEffect, useState } from 'react';
import { jwtDecode } from "jwt-decode";
import { ACCESS_TOKEN } from '../../constants';


export const useAuthentication = () => {
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        if (token !== null) {
            const tokenExpired = isTokenExpired(token);

            if (tokenExpired) {
                setIsAuth(false)
            } else {
                setIsAuth(true);
            }
        }
    }, []);

    const logout = () => {
        localStorage.clear();
        window.location.href = "/";
    };

    return { isAuth, logout };
};

function isTokenExpired(token) {
    if (!token) return true;

    const decodeToken = jwtDecode(token);
    const expirationTime = decodeToken.exp * 1000
    const currentTime = Date.now();

    return expirationTime < currentTime
}

export const useCheckBusiness = () => {
    const [isBusiness, setIsBusiness] = useState(false);

    useEffect(() => {
        const business_id = localStorage.getItem("businessId")
        if (business_id) {
            setIsBusiness(true)
        }
    }, [])
    return { isBusiness }
}