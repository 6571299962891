import axios from "axios";
import { ACCESS_TOKEN } from "./constants";

const environment = process.env.REACT_APP_ENV;
export const API_URL = process.env.REACT_APP_API_URL;
export const MEDIA_URL = process.env.REACT_MEDIA_URL;
export const REACT_BUCKET_TOKEN = process.env.REACT_BUCKET_TOKEN;

export const DOMAIN_NAME = environment === "test" ? process.env.REACT_TEST_DOMAIN : "https://jobmatch.csrlimited.com";
export const ENV = environment;

const api = axios.create({
    baseURL: API_URL
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
            localStorage.removeItem(ACCESS_TOKEN);
            window.location.reload();
        }
        return Promise.reject(error);
    }
);

export default api;
