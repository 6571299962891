import { useAuthentication } from '../../components/auth/checkAuth';
import { useNotification } from '../../components/services/notificationContext';
import { React, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { API_URL } from '../../api';
import api from '../../api';
import SiteHeader from '../../components/siteHeader';
import '../../assets/css/pages/dash_business/dash_business.css';
import JobsContainer from '../../components/jobsContainer';
import FetchData from '../../components/services/fetchData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

const JobsPage = () => {
    const { isAuth } = useAuthentication();
    const { showNotification } = useNotification();
    const [jobTypes, setJobTypes] = useState([]);
    const [workTypes, setWorkTypes] = useState([]);
    const [experienceLevels, setExperienceLevels] = useState([]);
    const [fetchingExperience, setFetchingExperienceLevels] = useState(false);
    const [error, setError] = useState(null);
    const [fetchingJobsTypes, setFetchingJobsTypes] = useState(false);
    const [fetchingWorkTypes, setFetchingWorkTypes] = useState(false);

    const [selectedJobTypes, setSelectedJobTypes] = useState([]);
    const [selectedWorkTypes, setSelectedWorkTypes] = useState([]);
    const [selectedExperienceLevels, setSelectedExperienceLevels] = useState([]);

    // Hiding jobs
    const initialVisibility = {
        experienceLevels: true,
        jobFilter: true,
        jobTypes: true,
    };
    const [visibility, setVisibility] = useState(initialVisibility);

    const toggleVisibility = (filterId) => {
        setVisibility({
            ...visibility,
            [filterId]: !visibility[filterId],
        });
    };

    // const jobTypesUrl = `${API_URL}/job-types/`;
    // const workTypesUrl = `${API_URL}/work-types/`;
    // const experienceLevelsUrl = `${API_URL}/experience-levels/`;
    const jobsUrl = `${API_URL}/jobs/jobs/all/`;
    const jobsData = FetchData(jobsUrl);

    useEffect(() => {
        const fetchJobTypes = async () => {
            setFetchingJobsTypes(true);
            try {
                const response = await api.get(`/jobs/job-types/`);
                if (response.status === 200) {
                    setJobTypes(response.data);
                    setFetchingJobsTypes(false);
                } else {
                    setFetchingJobsTypes(false);
                    showNotification("fail", "Network response was not ok");
                }
            } catch (error) {
                console.log(error);
                setFetchingJobsTypes(false);
                showNotification('fail', 'Error fetching job data:');
            }
        };

        const fetchWordTypes = async () => {
            setFetchingWorkTypes(true);
            try {
                const response = await api.get(`/jobs/work-types/`);
                if (response.status === 200) {
                    setWorkTypes(response.data);
                    setFetchingWorkTypes(false);
                } else {
                    setFetchingWorkTypes(false)
                }
            } catch (error) {
                setFetchingWorkTypes(false)
            }
        };

        const fetchExperienceLevels = async () => {
            setFetchingExperienceLevels(true);
            try {
                const response = await api.get(`/jobs/experience-levels/`)
                if (response.status === 200) {
                    setExperienceLevels(response.data)
                    setFetchingExperienceLevels(false)
                } else {
                    setFetchingExperienceLevels(false)
                }
            } catch (error) {
                setFetchingExperienceLevels(false)
            }
        };

        fetchExperienceLevels();
        fetchJobTypes();
        fetchWordTypes();
        // fetchJobs()

    }, []);

    const handleJobTypeChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedJobTypes([...selectedJobTypes, value]);
        } else {
            setSelectedJobTypes(selectedJobTypes.filter(type => type !== value));
        }
    };

    const handleWorkTypeChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedWorkTypes([...selectedWorkTypes, value]);
        } else {
            setSelectedWorkTypes(selectedWorkTypes.filter(type => type !== value));
        }
    };

    const handleExperienceLevelChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedExperienceLevels([...selectedExperienceLevels, value]);
        } else {
            setSelectedExperienceLevels(selectedExperienceLevels.filter(level => level !== value));
        }
    };

    const filteredJobs = jobsData.data.filter(job => {
        const jobTypeFilter = selectedJobTypes.length === 0 || selectedJobTypes.includes(job.job_type);
        const workTypeFilter = selectedWorkTypes.length === 0 || selectedWorkTypes.includes(job.work_type);
        const experienceLevelFilter = selectedExperienceLevels.length === 0 || selectedExperienceLevels.includes(job.experience_level);
        return jobTypeFilter && workTypeFilter && experienceLevelFilter;
    });

    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <title>Jobs | CSR Staffing</title>
            </Helmet>
            <div className="dashboard-content">
                <SiteHeader />
                <section className='jobs-section container'>
                    <div className="filters card">
                        <div className="filters-container">
                            <div className="filter-title">
                                <h4>Filter Job</h4>
                                <p className='reset-button'>Reset filters</p>
                            </div>
                            <div className="filter-icon" id='jobTypes' onClick={() => toggleVisibility('jobTypes')}>
                                <h4>Job types</h4>
                                <FontAwesomeIcon icon={visibility['jobTypes'] ? faAngleUp : faAngleDown} />
                            </div>
                            {visibility['jobTypes'] && (
                                fetchingJobsTypes ? (
                                    <div className="loading-icon-text">
                                        <p>Getting jobs types</p>
                                        <img src={require("../../assets/icons/loading-yellow.png")} alt="" className="loading-icon" />
                                    </div>
                                ) : (
                                    <div className="jobs-types car-content">
                                        {jobTypes.map(joType => (
                                            <div key={joType.id} className="type">
                                                <input type="checkbox" name="jobType" value={joType.name} onChange={handleJobTypeChange} />
                                                <label htmlFor={joType.id}>{joType.name}</label>
                                            </div>
                                        ))}
                                    </div>
                                )
                            )}
                            <hr />
                        </div>
                        <div className="filters-container">
                            <div className="filter-icon" id='jobFilter' onClick={() => toggleVisibility('jobFilter')}>
                                <h4>Work types</h4>
                                <FontAwesomeIcon icon={visibility['jobFilter'] ? faAngleUp : faAngleDown} />
                            </div>
                            {visibility['jobFilter'] && (
                                fetchingWorkTypes ? (
                                    <div className="loading-icon-text">
                                        <p>Getting work types</p>
                                        <img src={require("../../assets/icons/loading-yellow.png")} alt="" className="loading-icon" />
                                    </div>
                                ) : (
                                    <div className="work-types">
                                        {workTypes.map(workType => (
                                            <div key={workType.id} className="type">
                                                <input type="checkbox" name="workType" value={workType.name} onChange={handleWorkTypeChange} />

                                                <label htmlFor={workType.id}></label>
                                                {workType.name}</div>
                                        ))}
                                    </div>
                                )
                            )}
                            <hr />
                        </div>
                        <div className="filters-container">
                            <div className="filter-icon" id='experienceLevels' onClick={() => toggleVisibility('experienceLevels')}>
                                <h4>Experience levels</h4>
                                <FontAwesomeIcon icon={visibility['experienceLevels'] ? faAngleUp : faAngleDown} />
                            </div>
                            {visibility['experienceLevels'] && (
                                fetchingExperience ? (
                                    <div className="loading-icon-text">
                                        <p>Getting experiences</p>
                                        <img src={require("../../assets/icons/loading-yellow.png")} alt="" className="loading-icon" />
                                    </div>
                                ) : (
                                    <div className="work-types">
                                        {experienceLevels.map(level => (
                                            <div key={level.id} className="type">
                                                <input type="checkbox" name="experienceLevels" id={level.id} value={level.name} onChange={handleExperienceLevelChange} />
                                                <label htmlFor={level.id}></label>
                                                {level.name}</div>
                                        ))}
                                    </div>
                                )
                            )}
                            <hr />
                        </div>
                    </div>
                    <JobsContainer jobs={filteredJobs} isAuth={isAuth} />
                </section>
            </div>
        </div>
    );
};

export default JobsPage;
