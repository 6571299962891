import React from "react";
import '../assets/css/pages/thankYou/thank_you.css'
import { Link } from "react-router-dom";
import ShareButtons from "../components/shareButtons";
const ThankYouPage = () => {
    const message = localStorage.getItem("thankYou")
    return (
        <div className="thank-you-page">
            <div className="thank-you-content">
                <h2 className="thank-you-title">Thank you !</h2>

                <p>{message || 'Your information has been saved successfully'}</p>
                <h3 className="share-title">
                    Share news with your friends
                </h3>
                <ShareButtons url={"https://jobmatch.csrlimited.com/"} title={"jobMatch"} />
                <Link to={"/"} className="action-link" >Go to home</Link>
            </div>
        </div>
    )
}
export default ThankYouPage