import React, { useState } from "react"
import { useNotification } from "../services/notificationContext"
import api from "../../api"
import { formatDate } from "../services/dateFormat"
const InterviewPopup = ({ application, setIsInterviewPopupOpen }) => {
    const [isInterviewScheduled, setIsInterviewScheduled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [scheduledInterview, setScheduledInterview] = useState([])
    const [interviewType, setInterviewType] = useState("inPerson")
    const { showNotification } = useNotification()
    const closeInterviewPopup = (event) => {
        event.preventDefault()
        setIsInterviewPopupOpen(false)
    }

    const changeInterviewType = (type) => {
        setInterviewType(type)
    }
    const scheduleInterview = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        const interviewDate = document.getElementById("interviewDate").value
        const timeFrameFrom = document.getElementById("timeFrameFrom").value
        const timeFrameTo = document.getElementById("timeFrameTo").value
        const interViewMessage = document.getElementById("interViewMessage").value
        let interviewPlace = null;
        if (interviewType === "inPerson") {
            interviewPlace = document.getElementById("interviewPlace").value;
        } else {
            interviewPlace = document.getElementById("interviewLink").value;
        }

        const interviewData = {
            "application_id": application.id,
            "date": interviewDate,
            "from": timeFrameFrom,
            "to": timeFrameTo,
            "message": interViewMessage,
            "interview_type": interviewType,
            "interview_place": interviewPlace,
        };

        try {
            const response = await api.post("/candidates/new-interview/", interviewData)
            if (response.status === 201) {
                setIsLoading(false)
                setIsInterviewScheduled(true)
                setScheduledInterview(response.data.interview)
                // showNotification("success", response.data.message)
                // setIsInterviewPopupOpen(false)
            } else {
                setIsLoading(false)
                showNotification("fail", response.data.message)
                setIsInterviewPopupOpen(true)
            }
        } catch (error) {
            setIsLoading(false)
            showNotification("fail", error.response.data.message)
        }



    }
    const reloadThePage = () => {
        setIsInterviewPopupOpen(false)
        window.location.reload()
    }
    return (
        <div className="interview-popup">
            <div className="popup-content">
                {isInterviewScheduled
                    ? ''
                    : <h3>Schedule interview with {application.applicant.user.first_name}</h3>}
                {isInterviewScheduled
                    ? (<div className="success-message">
                        <div onClick={reloadThePage} className="close-button">
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className="circle primary">
                            <div className="circle secondary">
                                <div className="circle tertiary">
                                    <div className="icon">
                                        <i className="fa-solid fa-check"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p>You have scheduled<span className="page-link"> Interview </span> with {application.applicant.user.first_name} on {formatDate(scheduledInterview.interview_date)} at {scheduledInterview.from_time}</p>
                        <button onClick={reloadThePage} className="primary-button">
                            <i class="fa-solid fa-arrow-left"></i>
                            back to applications
                        </button >

                    </div>)
                    : (<form action="">
                        <input type="date" name="" id="interviewDate" placeholder="Interview date" />

                        <div className="half">
                            <div>
                                <h4>From</h4>
                                <select id="timeFrameFrom">
                                    <option value="">Select a Time</option>
                                    <option value="09:00 am">09:00 AM</option>
                                    <option value="09:00 am">10:00 AM</option>
                                    <option value="09:00 am">11:00 AM</option>
                                    <option value="09:00 am">12:00 AM</option>
                                    <option value="09:00 am">02:00 PM</option>
                                    <option value="09:00 am">03:00 PM</option>
                                    <option value="09:00 am">03:00 PM</option>
                                    <option value="09:00 am">04:00 PM</option>
                                </select>
                            </div>
                            <div>
                                <h4>To</h4>
                                <select id="timeFrameTo">
                                    <option value="09:00 am">09:00 AM</option>
                                    <option value="09:00 am">10:30 AM</option>
                                    <option value="09:00 am">11:30 AM</option>
                                    <option value="09:00 am">12:30 AM</option>
                                    <option value="09:00 am">02:30 PM</option>
                                    <option value="09:00 am">03:30 PM</option>
                                    <option value="09:00 am">03:30 PM</option>
                                    <option value="09:00 am">04:30 PM</option>
                                </select>
                            </div>
                        </div>
                        <div className="half">
                            <div>
                                <h4>Interview type</h4>
                                <select id="InterviewType">
                                    <option onClick={() => changeInterviewType("inPerson")} value="inPerson">In person</option>
                                    <option onClick={() => changeInterviewType("Online")} value="Online">Online</option>
                                </select>
                            </div>
                            {
                                interviewType === "Online"
                                    ? <div>
                                        <h4>Link</h4>
                                        <input type="url" name="" id="interviewLink" placeholder="Link" />
                                    </div>
                                    : <div>
                                        <h4>Location</h4>
                                        <input type="text" name="" id="interviewPlace" placeholder="Place" />
                                    </div>
                            }


                        </div>

                        <textarea name="" id="interViewMessage" cols="30" rows="5" placeholder={`Dear ${application.applicant.user.first_name} ${application.applicant.user.last_name} \nCongratulations! You have been selected for the interview interview. `}></textarea>
                        <div className="buttons">
                            <button onClick={scheduleInterview} className="primary-button" disabled={isLoading}>
                                {isLoading ? <div><img className='loading-icon' src={require("../../assets/icons/loading.png")} alt="" /> Scheduling</div> : <div><i className="fa-solid fa-calendar-days"></i>
                                    <span>Schedule</span></div>}

                            </button>

                            {
                                isLoading
                                    ? ''
                                    : <button onClick={closeInterviewPopup} className="tertiary-button">
                                        Cancel
                                    </button>}

                        </div>
                    </form>)
                }

            </div>
        </div>
    )
}

export default InterviewPopup