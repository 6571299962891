import React, { useState, useEffect } from 'react';
import api from '../../api'
import { useAuthentication } from '../../components/auth/checkAuth';
import { useNotification } from "../../components/services/notificationContext";
import { Helmet } from 'react-helmet';
import '../../assets/css/pages/update_business_serivces/updateBusinessServices.css'
import SessionExpired from '../auth/sessionExpired';
import Branding from '../../components/v3/Branding';

const UpdateBusinessServicesPage = () => {
    const { isAuth } = useAuthentication()
    const { showNotification } = useNotification()
    const [items, setItems] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [updatingServices, setUpdatingServices] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const businessId = localStorage.getItem("businessId")
    const businessSlug = localStorage.getItem("businessSlug")
    const handleServiceToggle = (serviceName) => {
        console.log(serviceName)
        console.log(selectedServices)
        setSelectedServices(prevSelectedServices => {
            if (prevSelectedServices.includes(serviceName)) {
                return prevSelectedServices.filter(service => service !== serviceName);
            } else {
                return [...prevSelectedServices, serviceName];
            }
        });
    };

    const handleUpdateServices = async (event) => {
        event.preventDefault()
        setUpdatingServices(true)
        const profileData = {
            "services": selectedServices,
        }
        if (selectedServices.length <= 0) {
            showNotification("fail", "You need to select at list one service")
            setIsLoading(false)
        }
        else {
            try {

                const response = await api.post(`/business/update/${businessSlug}/`, profileData)
                if (response.status === 200) {
                    showNotification("success", "Business profile is updates")
                    window.location.href = "/thank-you/"
                }
            } catch (error) {
                showNotification("fail", error.response.data.message)
                setIsLoading(false)
            }
        }
    }
    const servicesData = [
        {
            "industry": "Technology",
            "services": [
                { "name": "Software Development" },
                { "name": "Cybersecurity" },
                { "name": "IT Support" },
                { "name": "Cloud Computing" },
                { "name": "Data Analytics" }
            ]
        },
        {
            "industry": "Healthcare",
            "services": [
                { "name": "Medical Services" },
                { "name": "Telemedicine" },
                { "name": "Pharmaceuticals" },
                { "name": "Medical Devices" },
                { "name": "Health Insurance" }
            ]
        },
        {
            "industry": "Finance",
            "services": [
                { "name": "Banking" },
                { "name": "Investment Management" },
                { "name": "Insurance" },
                { "name": "Financial Advisory" },
                { "name": "Fintech" }
            ]
        },
        {
            "industry": "Retail",
            "services": [
                { "name": "E-commerce" },
                { "name": "Brick-and-Mortar Stores" },
                { "name": "Supply Chain Management" },
                { "name": "Customer Service" },
                { "name": "Merchandising" },
                { "name": "Healthcare" },
            ]
        },
        {
            "industry": "Manufacturing",
            "services": [
                { "name": "Product Design" },
                { "name": "Quality Control" },
                { "name": "Supply Chain Management" },
                { "name": "Logistics" },
                { "name": "Maintenance" }
            ]
        },
        {
            "industry": "Energy",
            "services": [
                { "name": "Renewable Energy" },
                { "name": "Oil and Gas" },
                { "name": "Utilities" },
                { "name": "Energy Efficiency" },
                { "name": "Energy Consulting" }
            ]
        },
        {
            "industry": "Real Estate",
            "services": [
                { "name": "Property Management" },
                { "name": "Real Estate Brokerage" },
                { "name": "Real Estate Development" },
                { "name": "Real Estate Investment" },
                { "name": "Appraisal Services" }
            ]
        },
        {
            "industry": "Transportation",
            "services": [
                { "name": "Logistics" },
                { "name": "Public Transportation" },
                { "name": "Freight Services" },
                { "name": "Courier Services" },
                { "name": "Transportation Consulting" }
            ]
        },
        {
            "industry": "Education",
            "services": [
                { "name": "K-12 Education" },
                { "name": "Higher Education" },
                { "name": "Online Education" },
                { "name": "Tutoring Services" },
                { "name": "Education Consulting" }
            ]
        },
        {
            "industry": "Entertainment",
            "services": [
                { "name": "Film Production" },
                { "name": "Music Production" },
                { "name": "Event Management" },
                { "name": "Gaming" },
                { "name": "Publishing" }
            ]
        },
        {
            "industry": "Other",
            "services": [
                { "name": "Entertainment Services" },
                { "name": "Education Services" },
                { "name": "Transportation" },
                { "name": "Legal Services" },
                { "name": "Energy Services" },
                { "name": "Manufacturing Services" },
                { "name": "Technology Services" },
                { "name": "Retail Services" },
                { "name": "Finance Services" },
            ]
        }
    ]


    useEffect(() => {
        const businessIndustry = localStorage.getItem("businessIndustry") || 'Other'
        setItems(servicesData.find(data => data.industry === businessIndustry).services || [])
    }, [])


    return (
        isAuth ?
            <div>
                <Helmet>
                    <meta charSet="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link rel="icon" href="favicon.ico" type="image/x-icon" />
                    <title>Profile | talentConnect</title>
                </Helmet>
                <div className="side-bard"></div>
                <div className="page-content">
                    <div className="container">
                        <Branding />
                        <h2 className="title">What Services do you possess</h2>
                        <p>
                            Providing these info will help hiring managers and recruiters know more interested in you
                        </p>
                        <form action="" className="profileForm">
                            {isLoading ? (
                                <p
                                    className='loading-icon-text'>Loading Services... <img className='loading-icon' src={require("../../assets/icons/loading-yellow.png")} alt="" />
                                </p>
                            ) : (

                                <div className="services-category-container">
                                    {/* {items.length} */}
                                    <ul className="services">
                                        {items.map((service, serviceIndex) => (
                                            <li
                                                className={`service ${selectedServices.includes(service.name) ? 'selected' : ''}`}
                                                key={serviceIndex}
                                                onClick={() => handleServiceToggle(service.name)}
                                            >
                                                <div className="check">
                                                    {selectedServices.includes(service.name) && (
                                                        <img className="custom-check-icon" src={require("../../assets/icons/checkmark.png")} alt="Checkmark" />
                                                    )}
                                                </div>
                                                <p className="service-name">{service.name}</p>


                                            </li>
                                        ))}

                                    </ul>
                                </div>


                            )}
                            <button onClick={handleUpdateServices} className='button primary-button'>{updatingServices ? <img className='loading-icon' src={require("../../assets/icons/loading.png")} alt="" /> : 'Save Services'}</button>
                        </form>
                    </div>
                </div>
            </div>
            : <SessionExpired />

    )
}

export default UpdateBusinessServicesPage