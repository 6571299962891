import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import SiteHeader from '../../components/siteHeader';
import '../../assets/css/pages/dash_business/dash_business.css';
import api from '../../api';
import JobsContainer from '../../components/jobsContainer';
import { useCheckBusiness } from '../../components/auth/checkAuth';
import SmallFooter from '../../components/smallFooter';
import { Link } from 'react-router-dom';
import emptyJobsImg from '../../assets/img/pages/dashboard/emptyJobsImg.jpg';
import IsLoading  from '../../components/loadingIcon';

const DashBusiness = () => {
    const [jobs, setJobs] = useState([]);
    const [totalJobs, setTotalJobs] = useState(0);
    const { isBusiness } = useCheckBusiness();
    const [search, setSearch] = useState('');
    const [timeRange, setTimeRange] = useState('all');
    const businessId = localStorage.getItem("businessId");
    const businessSlug = localStorage.getItem("businessSlug");
    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => {
        const getJobs = async () => {
            setIsFetching(true);
            try {

                if (businessId) {
                    const response = await api.get(`/business/${businessSlug}/jobs/`);
                    if (response.status === 200) {
                        setJobs(response.data);
                        setTotalJobs(response.data.length);
                        setIsFetching(false);
                    }
                }
            } catch (error) {
                console.log(error.response);
            }
        };
        getJobs();
    }, []);

    const handleTimeRangeChange = (event) => {
        console.log("Selected time range:", event.target.value);
        setTimeRange(event.target.value);
    };

    const filterJobsByTimeRange = (jobs) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (timeRange === 'all') {
            return jobs;
        } else if (timeRange === '2days') {
            const twoDaysAgo = new Date(today);
            twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
            console.log("Two days ago:", twoDaysAgo);
            return jobs.filter(job => new Date(job.date_created) >= twoDaysAgo && new Date(job.date_created) <= today);
        } else if (timeRange === '1month') {
            const today = new Date();
            const prevMonthLastDay = new Date(today.getFullYear(), today.getMonth() - 1, 0);
            // const prevMonthFirstDay = new Date(prevMonthLastDay.getFullYear(), prevMonthLastDay.getMonth(), 1);

            // console.log("Last month first day:", prevMonthFirstDay);
            console.log("Last month last day:", prevMonthLastDay);

            return jobs.filter(job =>
                //   new Date(job.date_created) >= prevMonthFirstDay && 
                new Date(job.date_created) <= prevMonthLastDay
            );

        } else if (timeRange === '') {

            return jobs.filter(job => {
                const jobDate = new Date(job.date_created);
                return jobDate.getFullYear() === today.getFullYear() &&
                    jobDate.getMonth() === today.getMonth() &&
                    jobDate.getDate() === today.getDate();
            });
        } else {

            return [];
        }
    };

    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <title>Business Dashboard | CSR Staffing</title>
            </Helmet>
            {isFetching ? (
                <IsLoading  text="Getting Jobs"/>
            ) : (
                <div className="dashboard-content">
                <SiteHeader />
                <div className="search card">
                    <div className="jobs">
                        <h3>Jobs</h3>
                        <p className='number'>{totalJobs}</p>
                    </div>
                    <div className="search-input input">
                        <i className="fa-solid fa-magnifying-glass"></i>
                        <form action="">
                            <input type="text" name="" id="" onChange={(e) => setSearch(e.target.value)} placeholder='Search Something' />
                        </form>
                    </div>
                    <div className="filters">
                        <div className="add-job">
                            <Link to={`/business/${businessSlug}/new-job/`} className='add-job-btn'><i className="fa-solid fa-plus"></i><span className='job-btn'>Add Job</span></Link>
                        </div>
                        {/* <div className="icon-text">
                            <i className="fa-solid fa-filter"></i>
                            <p> Filter</p>
                        </div> */}
                        <form action="" className='filter-form'>
                            <select className='input' name="timeRange" onChange={handleTimeRangeChange}>
                                <option value="all">All</option>
                                <option value="">Recents</option>
                                <option value="2days">2 days ago</option>
                                <option value="1month">Months ago</option>
                            </select>
                        </form>

                    </div>

                </div>

                <div className="content container">
                    {jobs.filter((job) => {
                        const lowerSearch = search.toLowerCase();
                        return (
                            lowerSearch === '' ||
                            (job.job_type && job.job_type.toLowerCase().includes(lowerSearch)) ||
                            (job.name && job.name.toLowerCase().includes(lowerSearch)) ||
                            (job.work_type && job.work_type.toLowerCase().includes(lowerSearch))
                        );
                    }).length === 0 && <div className="empty-business-jobs">
                            <div className="empty-container">
                                <img src={emptyJobsImg} alt="Empty Business Jobs" />
                                <div className="text">
                                    <h3>It looks like you haven't posted any jobs yet</h3>
                                    <p>Start by creating your first job posting to attract talented candidates</p>
                                    <Link to={`/business/${businessSlug}/new-job/`} className='create-job-btn'>
                                        <span>Create a job</span><i className="fa-solid fa-plus"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>}
                    {filterJobsByTimeRange(jobs).length === 0 && <div className="empty-business-jobs">
                        <div className="empty-container">
                            <img src={emptyJobsImg} alt="Empty Business Jobs" />
                            <div className="text">
                                <h3>It looks like you haven't posted any jobs yet</h3>
                                <p>Start by creating your first job posting to attract talented candidates</p>
                                <Link to={`/business/${businessId}/new-job/`} className='create-job-btn'>
                                    <span>Create Job Post</span><i className="fa-solid fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                    </div>}
                    <JobsContainer
                        jobs={filterJobsByTimeRange(jobs).filter((job) => {
                            const lowerSearch = search.toLowerCase();
                            return (
                                lowerSearch === '' ||
                                (job.job_type && job.job_type.toLowerCase().includes(lowerSearch)) ||
                                (job.name && job.name.toLowerCase().includes(lowerSearch)) ||
                                (job.work_type && job.work_type.toLowerCase().includes(lowerSearch))
                            );
                        })}
                        isBusiness={isBusiness}
                    />


                </div>
            </div>
            )}
            

            <SmallFooter />
        </div>
    );
};

export default DashBusiness;