import React from 'react'
import "../../assets/css/pages/time_out/time_out.css"

const TimeOut = () => {
  return (
    <div className="timeout">
      <h3>Time out</h3>
      <h1>OOPS!</h1>
      <p> It seems like the connection to our server <br></br> timed out.</p>
    </div>
  )
}

export default TimeOut