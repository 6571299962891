import React, { useEffect, useState } from 'react';
import api from '../../api';
import IsLoading from '../../components/loadingIcon';
import NoDataContainer from '../../components/noDataContainer';
import { useNotification } from '../../components/services/notificationContext';
import { Helmet } from 'react-helmet';
import SiteHeader from '../../components/siteHeader';
import '../../assets/css/pages/dash_business/dash_business.css'
import '../../assets/css/pages/applications/applications.css'

const ApplicationsSearch = ({ applications, setSearchResults }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        if (event.target.value.length > 0) {

            const filteredResults = applications.filter(app =>
                app.job.company.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                app.job.job_title.toLowerCase().includes(event.target.value.toLowerCase()) ||
                app.job.job_type.job_type_name.toLowerCase().includes(event.target.value.toLowerCase())
            );
            setSearchResults(filteredResults);
        } else {
            setSearchResults([])
        }
    }

    return (
        <div className="application-search card">
            <h3>Application Search</h3>
            <div className="input">
                <div className="icon">
                    <i className="fa-solid fa-magnifying-glass"></i>
                </div>
                <input
                    type="search"
                    placeholder="Search applications"
                    value={searchQuery}
                    onChange={handleSearch}
                />
            </div>
        </div>
    )
}

const DeletePoPup = ({ slug, name, closePopup }) => {
    const [isDeleting, setIsDeleting] = useState()
    const { showNotification } = useNotification()


    const deleteApplication = async () => {
        setIsDeleting(true)
        try {
            const response = await api.delete(`/candidates/applications/delete/${slug}/`)
            if (response.status === 200) {
                showNotification("success", "Applications is revoked")
                window.location.reload()
            }
            else if (response.status === 400) {
                showNotification("fail", "An error happened")
            }
        } catch (error) {
            showNotification("fail", `An error happened ${error.response.data.message}`)
        }
    }
    return (
        <div className="delete-popup">
            <div className="popup-content">
                <div className='popup-header'>
                    <h2 >
                        Are you sure?
                    </h2>
                    <h4>You're going revoke application for {name}</h4>
                </div>

                <hr />
                <div className="popup-items">
                    <p>Revoking this application will delete it from out system, and employers won't be able to see your talent</p>

                    <div className="action-buttons">
                        <button onClick={closePopup} className='tertiary-button'>Cancel</button>
                        <button onClick={deleteApplication} className='danger-button'>{isDeleting ? <img src={require("../../assets/icons/loading.png")} alt="" className="loading-icon" /> : 'Delete'} </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ApplicationsTabs = ({ applications }) => {
    const statuses = ["All", ...new Set(applications.map(app => app.status))];
    const [activeTab, setActiveTab] = useState(statuses[0])

    const counts = statuses.map(status => {
        if (status === 'All') return applications.length;
        return applications.filter(app => app.status === status).length;
    });
    const flitteredApplications = activeTab === "All"
        ? applications
        : applications.filter(app => app.status === activeTab)
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' });
        const day = date.getDate();
        return `${month} ${day}, ${year}`;
    };
    const [deletePopup, setDeletePopup] = useState(false)
    const openDeletePopup = () => {
        setDeletePopup(true)
    }
    const closePopup = () => {
        setDeletePopup(false)
    }
    return (
        <div className='applications-container card'>
            <div className="tabs">
                {statuses.map((status, index) => (
                    <div
                        key={status}
                        className={`tab ${activeTab === status ? 'active' : ''}`}
                        onClick={() => setActiveTab(status)}
                    >
                        <p>{status} ({counts[index]})</p>
                    </div>
                ))}

            </div>
            {/* <div className="application-search card">
                <h3>Application History</h3>
                <div className="input">
                    <div className="icon">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <input type="search" name="" id="" placeholder="Enter something to search" />
                </div>
            </div> */}
            <div className="tab-content">
                <table>
                    <thead>
                        {/* <th>#</th> */}
                        <th>Company</th>
                        <th>Position</th>
                        <th>Employment type</th>
                        <th>Applied at</th>
                        <th>Status</th>
                        <th>Action</th>
                    </thead>
                    <tbody>

                        {
                            flitteredApplications.map(app => (
                                <tr key={app.id} className="application">
                                    {/* <td><p>{app.job.job_title}</p></td> */}
                                    <td><div className="company"> <img src={require("../../assets/img/logo.png")} alt="" className='logo' /> <p>{app.job_applied.name}</p></div></td>
                                    <td><p>{app.job_applied.name}</p></td>
                                    <td><p>{app.job_applied.job_type.name}</p></td>
                                    <td><p>{formatDate(app.date_created)}</p></td>
                                    <td><div className={`status ${app.status}`}><p>{app.status}</p></div></td>
                                    <td><div className="actions">
                                        <button className="view">
                                            <div className="tooltip"><p>View details</p></div>
                                            <i className="fa-solid fa-eye"></i>
                                        </button>
                                        <button className="edit">
                                            <div className="tooltip"><p>Edit</p></div>
                                            <i className="fa-solid fa-pencil"></i>
                                        </button>
                                        <button onClick={openDeletePopup} className="delete">
                                            <div className="tooltip"><p>Revoke application</p></div>
                                            <i className="fa-solid fa-trash"></i>
                                        </button></div></td>

                                    {
                                        deletePopup ? <DeletePoPup slug={app.slug} name={app.job_applied.job_title} closePopup={closePopup} /> : ''
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>


            </div>
        </div>

    )
}

const ApplicationsPage = () => {
    const [applications, setApplications] = useState([])
    const { showNotification } = useNotification()
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const userObj = localStorage.getItem("user")
    const user = userObj ? JSON.parse(userObj) : null

    useEffect(() => {
        const getApplications = async () => {
            setIsLoading(true)
            try {
                const response = await api.get("/candidates/applications/")
                setApplications(response.data.applications)
                console.log(response.data.applications)
                setIsLoading(false)
            } catch (error) {
                showNotification("fail", `${error}`)
                setIsLoading(false)
            }
        }

        getApplications()
    }, [])
    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <title>Applications | CSR Staffing</title>
            </Helmet>
            <div className="dashboard-content">
                <SiteHeader />
                <div className="applications-content container">
                    <div className="greetings">
                        <h2 className="greetings">Hi {user === null ? '' : user.first_name}</h2>
                        <p>
                            This is your jobs application status
                        </p>
                    </div>
                    <div className="apps-search">
                        <ApplicationsSearch applications={applications} setSearchResults={setSearchResults} />

                        {isLoading ?
                            (<IsLoading text={"applications"} />)

                            : (applications.length === 0 ?
                                <NoDataContainer title={"Opps! No applications found"} text={"When you have applications, they will show up here"} actionLink={"/dashboard/business/jobs/"} actionText={"Checkout jobs"} />
                                : <ApplicationsTabs applications={searchResults.length > 0 ? searchResults : applications} />)

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationsPage