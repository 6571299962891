import React from 'react';
import { useState, useEffect } from 'react';
import { useAuthentication } from '../../components/auth/checkAuth';
import { Helmet } from 'react-helmet';
import '../../assets/css/pages/profile_info/profile_info.css'
import SessionExpired from '../auth/sessionExpired';
import api from '../../api';

const ProfileFiles = () => {
    const { isAuth } = useAuthentication();
    const [profileImage, setProfileImage] = useState(null);
    const [cvFile, setCvFile] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null);


    const handleImageChange = (e) => {
        setProfileImage(e.target.files[0]);
    };
    const handleCVChange = (e) => {
        setCvFile(e.target.files[0]);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = new FormData();
        if (profileImage) {
            formData.append('profile_image', profileImage);
        }
        if (cvFile) {
            formData.append('cv_file', cvFile);
        }

        try {
            const response = await api.post("/candidates/update-profile/profile-files/", formData)
            if (!response.status === 200) {
                setError(response.data.message)
                setIsLoading(false)
            } else {
                setIsLoading(false)
                localStorage.clear()
                window.location.href = "/dashboard/candidates/"
            }
            console.log('Image uploaded successfully');
        } catch (error) {
            console.error('Error uploading image:', error.message);
        }
    };

    const checkProfileFiles = () => {
        const candidateObj = JSON.parse(localStorage.getItem("candidate"))
        if (candidateObj && candidateObj.profile_picture && candidateObj.resume) {
            window.location.href = "/profile/"
        }
    }

    useEffect(() => {
        checkProfileFiles();
    }, []);


    return (
        isAuth ?
            <div>
                <Helmet>
                    <meta charSet="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link rel="icon" href="favicon.ico" type="image/x-icon" />
                    <title>Registration | taleConnect</title>
                </Helmet>
                <div className="page-content">
                    {error && (<div className='error-message'>{error}</div>)}
                    <div className='form-content col'>
                        <div className="auth-forms">
                            <img className='logo' src={require("../../assets/img/logo.png")} alt="logo" />
                            <h2 className="title">Profile files</h2>
                            <p>Now, we need some files from you</p>
                            <form onSubmit={handleSubmit}>
                                <p>We need your profile picture</p>
                                <input type="file" onChange={handleImageChange} />
                                <br /><br />
                                <p>We also need your CV</p>
                                <input type="file" onChange={handleCVChange} />
                                <button type="submit" className={`button primary-button ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>{isLoading ? <img className='loading-icon' src={require("../../assets/icons/loading.png")} alt="" /> : 'Upload'}</button>
                            </form>
                        </div>
                    </div>
                    <div className='background-col col'></div>

                </div>
            </div>
            : <SessionExpired />
    );
};

export default ProfileFiles;
