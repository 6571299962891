import React from "react"
import { Link } from "react-router-dom"
const PageFooter = () => {
    return (
        <footer>
            <div className="container">
                <div className="company">
                    <Link to={"/"} className="logo-image" >
                        <img src={require("../assets/img/logo-white.png")} alt="" className="logo" />
                    </Link>
                    <p className="company-description">
                        CSR Staffing is dedicated to empowering candidates like you to find fulfilling career
                        opportunities and unlock your full potential. Join our community today and take the next step towards your professional success.
                    </p>
                </div>

                <div className="contacts">
                    <h2>Contacts</h2>
                    <Link to={"mailto:info@CSR Staffing.com"} className="company-email">info@csrlimited.com</Link>
                    <Link to={"tel:+250791902159"} className="company-phone">+250791902159</Link>
                </div>

                {/* <div className="socials">
                    <h2>Socials</h2>
                    <Link className="facebook">Facebook</Link>
                    <Link className="instagram">Instagram</Link>
                    <Link className="x">X</Link>
                </div>

                <div className="links">
                    <h2>Links</h2>
                    <Link className="footer-link">Career Tips</Link>
                    <Link className="footer-link">Industry News</Link>
                </div> */}
            </div>
        </footer>
    )
}

export default PageFooter