import { React, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuthentication } from './auth/checkAuth';
import { MEDIA_URL } from '../api';
import LoginPopup from './profilePopup';
import BusinessLoginPopup from './profilePopupBusiness';
import { REACT_BUCKET_TOKEN } from '../api';
import api from '../api';

const SiteHeader = () => {
    const { isAuth } = useAuthentication();
    const [isBusiness, setIsBusiness] = useState(false)
    const location = useLocation();
    const [userObj, setUserObj] = useState([])
    const [businessInfo, setBusinessInfo] = useState({})
    const [candidateObj, setCandidateObj] = useState([])

    const [isLogOutPopupOpen, setIsLogOutPopupOpen] = useState(false)

    const [menOpen, setMenuOpen] = useState(false)
    const togglePopup = () => {
        setIsLogOutPopupOpen(!isLogOutPopupOpen)
    }

    const logoutFunction = (event) => {
        event.preventDefault()
        localStorage.clear()
        window.location.href = "/"
    }

    const toggleMobileMenu = () => {
        setMenuOpen(!menOpen)
    }
    useEffect(() => {
        const userData = localStorage.getItem("user")
        if (userData && userData !== 'undefined') {
            setUserObj(JSON.parse(userData))
        } else {
            window.location.href = '/login/'
        }
    }, [])
    const menuItems =
        isAuth ?
            [
                //menu for logged in users
                { path: '/dashboard/candidates/', label: "Dashboard", icon: "fa-solid fa-house" },
                { path: '/dashboard/profile/', label: "Profile", icon: "fa-regular fa-user" },
                { path: '/dashboard/business/jobs/', label: "Find Jobs", icon: "fa-solid fa-suitcase" },
                { path: '/dashboard/business/applications/', label: "Applications", icon: "fa-solid fa-list-check" },
                { path: '/dashboard/business/messages/', label: "Messages", icon: "fa-regular fa-comment-dots" },
            ] :
            [
                // menu for logged out users
                { path: '/', label: "For Candidates", icon: 'fa-solid fa-people-group' },
                { path: '/business/', label: "For Businesses", icon: 'fa-solid fa-shop' },
                { path: '/jobs/', label: "Find Jobs", icon: "fa-solid fa-suitcase" },
                { path: '/trainings/', label: "Trainings", icon: "fa-regular fa-paper-plane" },
            ];

    const isActive = (path) => {
        return location.pathname === path;
    };
    return (
        <div className='header'>
            <div className="nav container">
                <Link className='branding' to="/"><img src={require("../assets/img/branding/logo-white.png")} alt="" className="logo" /></Link>

                <div className={menOpen ? "menu-profile open" : "menu-profile"}>
                    <ul className='menuItems'>
                        {menuItems.map((item) => (
                            <li key={item.path} className={isActive(item.path) ? 'active' : ''}>
                                <Link className='menu-link' to={item.path}><i className={item.icon}></i> {item.label}</Link>
                            </li>
                        ))}
                        {isAuth ?

                            <div onClick={logoutFunction} className="logout mobile-logout">
                                <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                <p>Logout</p>
                            </div>
                            :
                            <Link to={"/register/"} className="logout mobile-logout">
                                <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                <p>Register</p>
                            </Link>
                        }
                    </ul>
                    {isAuth ?
                        isBusiness
                            ? <div className='profile-container'>
                                <div className="profile">
                                    {
                                        businessInfo.logo
                                            ? <img src={businessInfo.logo} alt="" className="profile-pic" />
                                            : <img src={'/logo192.png'} alt="" className="profile-pic" />
                                    }
                                    <div className="name-and-type">
                                        <h2 className="name">{businessInfo.name}</h2>
                                    </div>
                                    {businessInfo ? (
                                        <BusinessLoginPopup isOpen={isLogOutPopupOpen} businessInfo={businessInfo} togglePopup={togglePopup} />
                                    ) : ''}

                                    <i onClick={togglePopup} className="fa-solid fa-chevron-down open-popup"></i>
                                </div>
                                <div className="profile-links">
                                    <div className="settings">
                                        <i className="fa-solid fa-gear"></i>
                                        <p>Settings</p>

                                    </div>
                                    <div className="notification">
                                        <i className="fa-regular fa-bell"></i>
                                        <div className="dot"></div>
                                    </div>
                                </div>
                            </div>
                            : userObj && candidateObj ?
                                <div className="profile-container">
                                    <div className="profile">
                                        <img src={`${MEDIA_URL}${candidateObj.profile_picture}`} alt="" className="profile-pic" />
                                        <div className="name-and-type">
                                            {userObj ? (
                                                <h2 className="name">{userObj.first_name} {userObj.last_name}</h2>
                                            ) : ""}

                                            <p className="type">Candidate</p>
                                        </div>
                                        {userObj ? (
                                            <LoginPopup isOpen={isLogOutPopupOpen} candidateObj={candidateObj} userObj={userObj} togglePopup={togglePopup} />
                                        ) : ''}

                                        <i onClick={togglePopup} className="fa-solid fa-chevron-down open-popup"></i>
                                    </div>
                                    <div className="profile-links">
                                        <div className="settings">
                                            <i className="fa-solid fa-gear"></i>
                                            <p>Settings</p>

                                        </div>
                                        <div className="notification">
                                            <i className="fa-regular fa-bell"></i>
                                            <div className="dot"></div>
                                        </div>
                                    </div>

                                </div> :
                                <button onClick={logoutFunction} className='account-login button secondary-button'><div className="icon"><i className="fa-regular fa-user"></i></div><p>Logout</p></button>
                        :
                        <Link to="/register/" className='account-login button secondary-button'><div className="icon"><i className="fa-regular fa-user"></i></div><p>Register</p></Link>
                    }

                </div>
                <div onClick={toggleMobileMenu} className={menOpen ? "menu-icon menu-open" : "menu-icon"}>
                    <img src={require("../assets/icons/menubar.png")} alt="" className="menu-bar first" />
                    <img src={require("../assets/icons/menubar.png")} alt="" className="menu-bar second" />
                    <img src={require("../assets/icons/menubar.png")} alt="" className="menu-bar third" />
                </div>

            </div>
        </div>
    )
}
export default SiteHeader