import React, { useEffect, useState } from 'react';
import { useAuthentication } from '../../components/auth/checkAuth';
import { useNotification } from '../../components/services/notificationContext';
import api from '../../api';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import '../../assets/css/pages/profile_info/profile_info.css'
import SessionExpired from '../auth/sessionExpired';

const RegisterProfileForm = ({ handleNext }) => {
    const { isAuth } = useAuthentication();
    const { showNotification } = useNotification()
    const [isLoading, setIsLoading] = useState(false);

    // form fields
    const [location, setLocation] = useState("")
    const [jobTitle, setJobTitle] = useState("")
    const [resentCompany, setResentCompany] = useState("")
    const [schoolName, setSchoolName] = useState("")
    const [degreeName, setDegreeName] = useState("")
    const [startingYear, setStartingYear] = useState("")
    const [endingYear, setEndingYear] = useState("")


    const SubmitLocationInfo = async () => {
        if (!location || !schoolName || !degreeName || !startingYear || !endingYear) {
            showNotification('fail', "Location and school info are are required")
            return
        }
        try {
            const selectedLocation = {
                "location": location,
                "school_name": schoolName,
                "degree_attained": degreeName,
                "started_year": startingYear,
                "ended_year": endingYear,
                "job_title": jobTitle,
                "recent_company": resentCompany
            }
            setIsLoading(true)
            const response = await api.post("/candidates/update-profile/", selectedLocation)

            if (response.status === 200) {
                setIsLoading(false)
                showNotification("success", "You location is update")
                window.location.href = '/thank-you/'
            }
        } catch (error) {
            setIsLoading(false)
            if (error.response) {
                showNotification("fail", error.response.data.message || "Network while saving locations")
            } else {
                showNotification("fail", "Network while saving locations")
            }
        }
    }


    return (

        <form className="proFileInfoForm">
            <h3>Where do you live</h3>
            <input
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Type to search locations"
                id='selectedLocation'
            />
            <h3>Recent work info</h3>
            <input onChange={(e) => setJobTitle(e.target.value)} type="text" name="jobTitle" id="jobTitle" placeholder='Most recent job title' />
            <input onChange={(e) => setResentCompany(e.target.value)} type="text" name="resentCompany" id="resentCompany" placeholder='Most recent company' />
            <h3>Recent Academic info</h3>
            <input onChange={(e) => setSchoolName(e.target.value)} type="text" name="schoolName" id="schoolName" placeholder='School name' />
            <input onChange={(e) => setDegreeName(e.target.value)} type="text" name="degreeName" id="degreeName" placeholder='Degree attained' />
            <div className="half">
                <input onChange={(e) => setStartingYear(e.target.value)} type="text" name="startingYear" id="startingYear" placeholder='Started year' />
                <input onChange={(e) => setEndingYear(e.target.value)} type="text" name="endingYear" id="endingYear" placeholder='Ended year' />
            </div>
            <button type='button' onClick={SubmitLocationInfo} id="RegisterButton" className={`button primary-button ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>{isLoading ? <img className='loading-icon' src={require("../../assets/icons/loading.png")} alt="" /> : 'Save profile'}</button>
        </form>

    )
}

export default RegisterProfileForm