import React from "react";

export const SectionTitle = ({ subTitle, mainTitle }) => {
    return (
        <div className="section-title">
            <div className="icon-sub-title">
                <img src="/images/v2/title-img.svg" alt="" className="title-img" />
                <h4 className="sub-title">{subTitle}</h4>
            </div>
            <h2 className="main-title">{mainTitle}</h2>
        </div>
    )
}