import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import ProfileFilesForm from "../register/profileFilesForm";
import { useAuthentication } from "../../components/auth/checkAuth";
import SessionExpired from "../auth/sessionExpired";
import BrandIcon from "../../components/services/brandIcon";
import Branding from "../../components/v3/Branding";
import RegisterProfileForm from "../register/profileLocationForm";

const CreateProfile = () => {
    const { isAuth } = useAuthentication()
    const userData = localStorage.getItem("user")
    const user = userData ? JSON.parse(userData) : null
    const [currentStep, setCurrentStep] = useState(1)

    const handlePrevious = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1)
        } else {
            setCurrentStep(1)
        }
        setCurrentStep(currentStep - 1)

    }
    const handleNext = () => {
        setCurrentStep(currentStep + 1)
        localStorage.setItem("profileStep", currentStep + 1)
        console.log(currentStep)
    }
    return (
        isAuth
            ? < div >
                <Helmet>
                    <meta charSet="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link rel="icon" href="favicon.ico" type="image/x-icon" />
                    <title>Create profile | taleConnect</title>
                </Helmet>
                <div className="page-content">
                    <div className="form-content col">
                        {
                            currentStep && currentStep > 1
                                ? <button className="button outline-button enroll-back-button" onClick={handlePrevious}>Back</button>
                                : ''
                        }
                        <div className="auth-forms">
                            <div className="branding">
                                <Branding />
                            </div>
                            <p>We need few details just to get started</p>
                            {
                                currentStep && currentStep === 1
                                    ? < RegisterProfileForm handleNext={handleNext} /> :
                                    currentStep === 2
                                        ? < ProfileFilesForm handleNext={handleNext} />
                                        : ''
                            }
                        </div>
                    </div>

                    <div className="background-col col">
                    </div>
                </div>
            </div >
            : < SessionExpired />
    )
}

export default CreateProfile