import { CopyrightIcon } from 'hugeicons-react'
import React from 'react'
import { Link } from 'react-router-dom'

const BottomBar = () => {
    const currentYear = new Date().getFullYear()
    return (
        <div className='bottom-bar'>
            <div className="container">
                <div className="copy">
                    <small><CopyrightIcon size={14} /> {currentYear} CSR Limited, all rights reserved </small>
                </div>
                <div className="terms-privacy">
                    <small> <Link to={'/terms-and-conditions/'}>Terms and conditions</Link> </small>
                    <small> <Link to={'/privacy-policy/'}>Privacy policy</Link> </small>
                </div>
            </div>
        </div>
    )
}

export default BottomBar
