import React from "react";

export const HowItWorksCard = ({ step, iconClass, title, description, imageLink }) => {
    return (
        <div className="step">
            <div className="image">
                <img src={imageLink} alt="" />
            </div>
            <div className="step-content">
                <div className="number-icon">
                    <p>{step}</p>
                    {iconClass}
                </div>

                <h5 className="step-title">{title}</h5>
                <p className='description'>{description}</p>
            </div>
        </div>
    )
}