import React from "react";
import { Link } from "react-router-dom";
import Myimage from "../../assets/img/Myimage.svg";
import error2 from "../../assets/img/erro2.svg";
import "../../assets/css/pages/page_not_found/page_not_found.css";

const PageNotFound = () => {
  return (
    <div>
      <div className="page-not-found">
        <div className="image-text">
          <img src={Myimage} alt=""></img>
        </div>
        <div className="text">
          <img id="error" src={error2} alt=""></img>
          <h1>Page Not Found</h1>
          <p>Oops! It seems like the page you are looking <br></br>for does not exist.</p>
          <Link className="button primary-button" to={"/"} >Go to home</Link>


        </div>

      </div>
    </div>
  );
}

export default PageNotFound;
