import React, { useState, useEffect } from 'react';
import api from '../../api';
import { Helmet } from 'react-helmet';
import '../../assets/css/pages/profile_creation/profile_creation.css'
// import Sidebar from '../../components/sideBar';
function getCookie(name) {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : '';
}
const csrftoken = getCookie('csrftoken');
const UpdateSkillsPage = () => {
    const [items, setItems] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [updatingSkills, setUpdatingSkills] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const checkSkills = () => {
        const candidateObj = localStorage.getItem("candidate")
        const candidate = candidateObj ? JSON.parse(candidateObj) : null

        if (candidate && candidate.skills && candidate.skills.length > 0) {
            window.location.href = "/candidate/work-experience/form/"
        }
    }

    const handleSkillToggle = (skillName) => {
        setSelectedSkills(prevSelectedSkills => {
            if (prevSelectedSkills.includes(skillName)) {
                return prevSelectedSkills.filter(skill => skill !== skillName);
            } else {
                return [...prevSelectedSkills, skillName];
            }
        });
    };

    const handleUpdateSkills = async (event) => {
        event.preventDefault()
        setUpdatingSkills(true)
        const profileData = {
            "skills": selectedSkills,
        }
        try {
            const response = await api.post("/candidates/update-profile/skills/", profileData)

            if (response.status === 200) {
                console.log(response.data)
                window.location.href = "/candidate/work-experience/form/"
            } else {
                console.log(response.data.message)
            }
        } catch (error) {
            // setError(error.message);
            console.log('Error', error)
        } finally {
            console.log("Error 2")
        }
    }
    useEffect(() => {
        const getSkills = async () => {
            setIsLoading(true)
            try {
                const response = await api.get("/candidates/skills/")
                if (response.status === 200) {
                    console.log(response)
                    setItems(response.data.skills.categories || []);
                    setIsLoading(false);
                }

            } catch (error) {
                // setError(error.message);
                console.log('Error')
            } finally {
                console.log("Error 2")
            }
        }

        getSkills()
    }, [])
    checkSkills()

    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <title>Profile | CSR Staffing</title>
            </Helmet>
            <div className="side-bard"></div>
            <div className="page-content">
                <div className="container">
                    <img src={require("../../assets/img/logo.png")} alt="" />
                    <h2 className="title">What skills do you possess</h2>
                    <p>
                        Providing these info will help hiring managers and recruiters know more interested in you
                    </p>
                    <form action="" className="profileForm">
                        {isLoading ? (
                            <p
                                className='loading-icon-text'>Loading skills... <img className='loading-icon' src={require("../../assets/icons/loading-yellow.png")} alt="" />
                            </p>
                        ) : (
                            items.map((category, index) => (
                                <div className="skills-category-container" key={index}>
                                    <h2 className="skill-category">{category.name}</h2>
                                    <ul className="skills">
                                        {category.topics.map((topic, topicIndex) => (
                                            <li
                                                className={`skill ${selectedSkills.includes(topic.name) ? 'selected' : ''}`}
                                                key={topicIndex}
                                                onClick={() => handleSkillToggle(topic.name)}
                                            >
                                                <div className="check">
                                                    {selectedSkills.includes(topic.name) && (
                                                        <img className="custom-check-icon" src={require("../../assets/icons/checkmark.png")} alt="Checkmark" />
                                                    )}
                                                </div>
                                                <p className="skill-name">{topic.name}</p>


                                            </li>
                                        ))}

                                    </ul>
                                </div>
                            ))

                        )}
                        <button onClick={handleUpdateSkills} className='button primary-button'>{updatingSkills ? <img className='loading-icon' src={require("../../assets/icons/loading.png")} alt="" /> : 'Save skills'}</button>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default UpdateSkillsPage