import React, { useState } from 'react';
import api from '../../api';
import { useNotification } from '../../components/services/notificationContext';
import { Helmet } from 'react-helmet';
import '../../assets/css/pages/forgot_password/forgot_password.css'
import Branding from '../../components/v3/Branding';
function getCookie(name) {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : '';
}
const VerifyCodePage = () => {
    const { showNotification } = useNotification()
    const [isLoading, setIsLoading] = useState(false)
    const passResetEmail = localStorage.getItem("pass_res_email")

    const verifyCode = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        const verificationCode = document.getElementById("verificationCode").value
        const password = document.getElementById("password").value
        const confirmPassword = document.getElementById("confirmPassword").value
        if (!verificationCode || !password || !confirmPassword) {
            showNotification('fail', 'All fields are required')
            setIsLoading(false)
        } else if (password !== confirmPassword) {
            showNotification('fail', 'Passwords do not match')
            setIsLoading(false)
        } else {
            const csrftoken = getCookie('csrftoken');
            console.log(csrftoken)
            const passwordData = {
                "email": passResetEmail,
                "password": password,
                "code": verificationCode
            }
            try {
                const response = await api.post("/accounts/verify-code/", passwordData)
                if (response.status === 200) {
                    setIsLoading(false)
                    showNotification('success', 'Password is reset')
                    setTimeout(() => {
                        window.location.href = "/login/"
                    }, 4000);
                }

            } catch (error) {
                showNotification('fail', error.response.data.message)
            } finally {
                setIsLoading(false);
            }
        }
    }
    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" />
                <title>Verify code | jobMatch</title>
            </Helmet>
            <div className="page-content">
                <div className="container auth-forms">
                    <Branding />
                    <div className='form-content'>
                        <h2>Emails on the way</h2>
                        <p>We sent you password reset code to {passResetEmail}. If it doesn’t show up soon, check your spam folder.</p>
                        <form action="" id="passwordResetCode">
                            <input type="text" name="" id="verificationCode" placeholder='Enter the code here' />
                            <input type="password" name="" id="password" placeholder='New password' />
                            <input type="password" name="" id="confirmPassword" placeholder='Confirm new password' />
                            <button onClick={verifyCode} className="button primary-button">{isLoading ? <img className='loading-icon' src={require("../../assets/icons/loading.png")} alt="" /> : 'Verify code'} </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyCodePage