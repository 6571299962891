import { parseISO, differenceInYears } from 'date-fns'

const CalculateExperience = ({ startedAt }) => {
    const experience = parseISO(startedAt)
    const currentDate = new Date();

    const experienceSpan = differenceInYears(currentDate, experience)

    return experienceSpan
}

export default CalculateExperience;