import React from "react";
import { formatDate } from "../services/dateFormat";
import { GoDotFill } from "react-icons/go";
import { CiStar } from "react-icons/ci";
import { CiGlobe } from "react-icons/ci";
import { CiClock2 } from "react-icons/ci";
import "../../assets/css/pages/profile-page/companyJobs.css";
import spotify from "../../assets/img/talentConnect (3)/campany/spotify_symbol.svg.svg";
import SlicedText from "../slicedTitle";

const CompanyProfileJobs = ({ jobs }) => {
  console.log(jobs)
  return (

    <div className="company-profile-jobs">
      {jobs.map((job, index) => (
        <div className="job card">

          <div className="job-content" key={index}>
            <div className="contents">
              <div className="details">
                <img src={require("../../assets/img/branding/icon.png")}></img>
                <div className="title">
                  <h3><SlicedText text={job.name} limit={32} /></h3>
                  <div className="sub-title">
                    <p>{job.company_name.name}</p>
                    <div className="dot">
                      <GoDotFill />
                      <p> {job.applicants}</p>
                    </div>
                  </div>
                </div>


              </div>
              <div className="star">
                <CiStar />
              </div>
            </div>
            <div className="text">
              <SlicedText text={job.job_description} limit={90} />
            </div>
            <div className="row">

              <div className="card">
                <CiGlobe id="icon" />
                <p>{job.job_type}</p>
              </div>

            </div>
            <div className="col">
              <div className="col-1">
                <h3>{job.max_salary}/</h3>
                <p>month</p>
              </div>
              <div className="col-2">
                <CiClock2 id="clock" />
                <p>{formatDate(job.date_updated)}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CompanyProfileJobs;
