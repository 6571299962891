import React, { useState } from 'react';
import { useNotification } from '../../components/services/notificationContext';
import api from '../../api';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import '../../assets/css/pages/forgot_password/forgot_password.css'
import Branding from '../../components/v3/Branding';
import { Loading03Icon } from 'hugeicons-react';
function getCookie(name) {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : '';
}
const ForgotPasswordPage = () => {
    const { showNotification } = useNotification()
    const [isLoading, setIsLoading] = useState(false)

    const sendRestCode = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        const email = document.getElementById("email").value
        if (email === '') {
            alert("No email entered")
        } else {
            const csrftoken = getCookie('csrftoken');
            console.log(csrftoken)
            try {
                const response = await api.post("/accounts/verify-email/", { 'email': email })
                console.log(response)
                if (response.status === 200) {
                    setIsLoading(false)
                    showNotification('success', `Code is sent to ${response.data.email}`)
                    localStorage.setItem("pass_res_email", response.data.email)
                    setTimeout(() => {
                        window.location.href = "/forgot-password/verify-code/"
                    }, 4000);
                }
            } catch (error) {
                showNotification('fail', error.response.data.message)
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        }
    }
    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" />
                <title>Forgot Password | CSR Staffing</title>
            </Helmet>
            <div className="page-content">
                <div className="container auth-forms">
                    <Branding />
                    <div className='form-content'>
                        <h1 className="title">Forgot your password</h1>
                        <p>
                            All good. Enter your account’s email address and we’ll send you a code to reset your password.
                        </p>
                        <form action="" id="emailVerifyForm">
                            <input type="email" name="email" id="email" placeholder='Your email' />
                            <button onClick={sendRestCode} className="button primary-button">{isLoading ? <Loading03Icon className='loading-icon' size={20} /> : 'Send reset link'}</button>
                        </form>
                        <p className="from-link-container"><i class="fa-solid fa-arrow-left"></i><span className='form-link'><Link to="/login">Return to login</Link> </span></p>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordPage