import React, { useState } from "react";
import api from '../../api';

const PopUpUpdateAdditionalInfo = ({ setUserData, hideFunction }) => {
    const [isAdditionalPopupVisible, setAdditionalPopupVisible] = useState(false);
    const [showAddAdditionalInfoPopup, setShowAddAdditionalInfoPopup] = useState(false);
    const [candidatePhonenumber, setCandidatePhonenumber] = useState("");
    const [candidateLanguages, setCandidateLanguages] = useState([]);

    const handleAdditionalInfoSubmit = async (event) => {
        event.preventDefault();
        const candidateObjects = {
            "phone_number": candidatePhonenumber,
            "languages": candidateLanguages
        };

        console.log(candidateObjects);
        try {
            const response = await api.post('candidates/update/additional-info/', candidateObjects);
            console.log('Additional info updated successfully');
            setUserData(prevUserData => ({ ...prevUserData, additionalInfo: candidateObjects }));
            hideFunction();
            toggleAddAdditionalInfoPopup(false);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                console.error("Error:", error.response.data.message);
            } else {
                console.error("Error:", error);
            }
        }
    };

    const toggleAddAdditionalInfoPopup = () => {
        setShowAddAdditionalInfoPopup(!showAddAdditionalInfoPopup);
        setAdditionalPopupVisible(!isAdditionalPopupVisible);
    };

    return (
        <>
            {isAdditionalPopupVisible && (
                <div className="overlay" onClick={toggleAddAdditionalInfoPopup}></div>
            )}
            {showAddAdditionalInfoPopup && (
                <div className='additional-info-form card'>
                    <form onSubmit={handleAdditionalInfoSubmit}>
                        <h3>Additional Details <i onClick={toggleAddAdditionalInfoPopup} className="fa-solid fa-xmark popup-x"></i></h3>
                        <label>
                            <input placeholder='Your Phone number' type="text" name="phone_number" value={candidatePhonenumber} onChange={(e) => setCandidatePhonenumber(e.target.value)} />
                        </label>
                        <p className='add-another'>Add another language <i className="fa-solid fa-plus"></i></p>
                        <label>
                            <input placeholder='Language you speak' type="text" name="language" value={candidateLanguages.map(language => language.name).join(',')}
                                onChange={(e) => {
                                    const languages = e.target.value;
                                    const languagesArray = languages.split(',').map(language => ({
                                        name: language.trim(),
                                        code: '' // You can set the code here or leave it empty
                                    }));
                                    setCandidateLanguages(languagesArray);
                                }} />
                        </label>
                        <div className="save-cancel">
                            <button type="button" onClick={toggleAddAdditionalInfoPopup}>Cancel</button>
                            <button type="submit" className="save" onClick={handleAdditionalInfoSubmit}>Save</button>
                        </div>
                    </form>
                </div>
            )}
            <div className='render-details card'>
                <img src={require("../../assets/css/pages/profile-page/Personal data-pana 1.png")} alt="" />
                <button onClick={toggleAddAdditionalInfoPopup}>Add Additional Information <i className="fa-solid fa-plus"></i></button>
            </div>
        </>
    );
}

export default PopUpUpdateAdditionalInfo;

