import { React, useEffect, useState } from "react";
import { MEDIA_URL } from "../../../api";
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet";
import SiteHeader from "../../siteHeader";
import StepsCards from "./stepsCards";
import { useNotification } from "../../services/notificationContext";
import api from "../../../api";
import IsLoading from "../../loadingIcon";

// styles
import '../../../assets/css/pages/newJob/newJob.css'



const UpdateJobBenefits = () => {
    const { jobSlug } = useParams()
    const [jobObj, setJobObj] = useState({})
    const [benefits, setBenefits] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const { showNotification } = useNotification()
    const updateUrl = `/jobs/job/${jobSlug}/benefits/`

    // handle benefits
    const [newBenefitName, setNewBenefitName] = useState("")
    const [newBenefitDescription, setNewBenefitDescription] = useState("null")
    const [isNewBenefitFormOpen, setIsNewBenefitFormOpen] = useState(false)

    useEffect(() => {
        const fetchBlog = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(updateUrl);
                if (response.status === 200) {
                    setJobObj(response.data.job);
                    setBenefits(response.data.benefits)
                    setIsLoading(false)
                }
            } catch (error) {
                // Handle errors
            } finally {
                setIsLoading(false);
            }
        };
        fetchBlog();
    }, []);

    const openNewBenefitPopup = () => {
        setIsNewBenefitFormOpen(!isNewBenefitFormOpen)
    }

    const handleAddNewBenefit = () => {
        const newBenefit = {
            "name": document.getElementById("benefitName").value,
            "description": document.getElementById("benefitDescription").value
        }
        setBenefits(prevBenefit => [...prevBenefit, newBenefit])
        setIsNewBenefitFormOpen(false)


    }
    const updateBenefits = async () => {
        setIsUpdating(true)
        try {
            const response = await api.post(updateUrl, { "benefits": benefits })
            if (response.status === 200) {
                showNotification("success", "Job benefits are added")
                setIsUpdating(false)
                window.location.href = "/dashboard/business/"
            }
        } catch (error) {
            if (error.response.status === 400) {
                showNotification("fail", error.response.data.message)

            }
            else {
                showNotification("fail", "There was an error while updating your job")
                setIsUpdating(false)
            }
        } finally {
            setIsUpdating(false)
        }
    }
    const NewBenefitPopup = () => {
        return (
            <div className="popup">
                <div className="popup-content">
                    <h2>Add new benefit</h2>
                    <p>Adding more benefits encourages candidates to chose you as their main consideration</p>

                    <form>
                        <input
                            type="text"
                            name="benefitName"
                            placeholder="Benefit name/title"
                            id="benefitName"
                        />
                        <textarea
                            name="benefitDescription"
                            id="benefitDescription"
                            cols="30"
                            rows="6"
                            placeholder="Explain the benefit in short"
                        >

                        </textarea>
                        <div className="action-buttons">
                            <button
                                type="button"
                                onClick={handleAddNewBenefit}
                                className="primary-button">
                                Save and continue
                            </button>

                            <button
                                onClick={openNewBenefitPopup}
                                className="tertiary-button">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    return (
        <div className="dashboard-content">
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <title>Business Dashboard | New Job</title>
            </Helmet>
            <SiteHeader />
            <div className="bread-crumbs container">
                <p>Dashboard /</p>
                <p className="current">New job</p>
            </div>

            <div className="container forms">
                <StepsCards step={"3"} />
                {
                    isLoading
                        ? <IsLoading text={"job data"} />
                        : (
                            Object.keys(jobObj).length > 0

                                ? <div className="form card benefits-step">
                                    <div className="benefits-actions">
                                        <div className="text">
                                            <h2>Perks and Benefits</h2>
                                            <p>Encourage more people to apply by sharing the attractive rewards and benefits you offer your employees</p>
                                        </div>

                                        <button
                                            onClick={openNewBenefitPopup}
                                            className="outline-button">
                                            <i className="fa-solid fa-plus"></i>
                                            Add benefit
                                        </button>
                                    </div>
                                    {benefits.length > 0
                                        ? (

                                            <div className="benefits-cards">
                                                {
                                                    benefits.map((benefit, index) => (
                                                        <div
                                                            key={index}
                                                            className="card">
                                                            <div className="remove-benefit">
                                                                <i className="fa-solid fa-xmark"></i>
                                                            </div>
                                                            <img
                                                                src={
                                                                    benefit.icon
                                                                        ? `${MEDIA_URL}/${benefit.icon}`
                                                                        : require("../../../assets/img/logo.png")
                                                                }
                                                                alt=""
                                                                className="benefit-icon"
                                                            />


                                                            <h4 className="name">{benefit.name}</h4>
                                                            <p className="description">
                                                                {benefit.description}
                                                            </p>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                        )
                                        : <p>No benefits</p>}

                                    <button
                                        type="button"
                                        onClick={updateBenefits}
                                        className="primary-button">
                                        {isUpdating
                                            ? (<img className='loading-icon' src={require("../../../assets/icons/loading.png")} alt="" />)
                                            : 'Submit for a review'}
                                    </button>
                                </div>
                                : <p>No job found</p>
                        )
                }
            </div>

            {isNewBenefitFormOpen
                ? <NewBenefitPopup />
                : ''}
        </div>
    )
}
export default UpdateJobBenefits