import React, { useState } from 'react';

const CopyButton = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(text);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        } catch (error) {
            console.error('Failed to copy:', error);
        }
    };

    return (
        <div>
            <button onClick={handleCopy}> <div className="icon"><i class="fa-regular fa-copy"></i></div> {copied ? 'Copied! happy sharing' : 'Copy link'}</button>
        </div>
    );
};

export default CopyButton;
