import React from "react";
import { Link } from "react-router-dom";
import access from "../../assets/img/access.svg";
import "../../assets/css/pages/forbidden_access/forbidden_access.css";
const ForbiddenAccess = () => {
  localStorage.clear()
  return (
    <div className="access">
      <div className="contents">
        <img src={access} alt=""></img>

        <div className="text">
          <h1>Forbidden Access</h1>
          <p>
            sorry, you don't have permission to access <br></br>this page.
          </p>
        </div>
        <div className="click">
          <Link className="button primary-button" to={"/login/"} >Login again</Link>
        </div>
      </div>
    </div>
  );
};

export default ForbiddenAccess;
