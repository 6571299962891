                    import React, { useState } from "react";
                    import api from '../../api';


                    const PopUpUpdateSocialLinks = ({setUserData, hideFunction }) => {
                    const [showAddSocialLinksPopup, setShowAddSocialLinksPopup] = useState(false);
                    const [isSocialPopupVisible, setSocialPopupVisible] = useState(false);
                    const [handle, setHandle] = useState("");
                    const [link, setLink] = useState("");
                    
                    
                    const handleSubmitSocialLinks = async (event) => {
                        event.preventDefault();
                        const socialLinks = {
                        "handle": handle,
                        "link": link,
                    };
                    console.log(socialLinks);
                        try {
                            const response = await api.post('candidates/update/social-links/', socialLinks);
                            console.log('Social links updated successfully:', response.data);
                            setUserData(prevUserData => ({ ...prevUserData, socialLinks: socialLinks }));
                            // setUserData(prevUserData => ({
                            //     ...prevUserData,
                            //     socialLinks: {
                            //         ...prevUserData.socialLinks,
                            //         [handle]: link
                            //     }
                            // }));

                        

                            // Clear input fields
                            setHandle('');
                            setLink('');
                    
                        hideFunction();

                        } catch (error) {
                            console.error('Error updating social links:', error);
                        }
                    };
                    const showAddSocialLinksPopupFn = () => {
                        setShowAddSocialLinksPopup(!showAddSocialLinksPopup);
                        setSocialPopupVisible(!isSocialPopupVisible);
                    
                        
                    };

                    return (
                        <>
                        {isSocialPopupVisible && (
                            <div className="overlay" onClick={showAddSocialLinksPopupFn}></div>
                        )}
                        {showAddSocialLinksPopup && (
                    <div className='social-links-popup card'>
                    <form onSubmit={handleSubmitSocialLinks}>
                        <h3>Social Links <i onClick={showAddSocialLinksPopupFn} className="fa-solid fa-xmark popup-x"></i></h3>
                        <label>
                            <input type="text" name="handle" id="" placeholder="Enter handle" value={handle} onChange={(e) => setHandle(e.target.value)} />
                        </label>
                        <label>
                            <input
                                placeholder='Link'
                                type="text" name="link" value={link} onChange={(e) => setLink(e.target.value)}  />
                        </label>
                        <div className='save-cancel'>
                            <button type="button"  onClick={showAddSocialLinksPopupFn} >Cancel</button>
                            <button className='save' type="button" onClick={handleSubmitSocialLinks}>Save</button>
                        </div>
                    </form>
                    </div>
                    )}
                    <div className='render-details'>
                                        <img src={require("../../assets/css/pages/profile-page/Social tree-bro 1.png")} alt="" />
                                                            <button onClick={showAddSocialLinksPopupFn}> Add your socials<i className="fa-solid fa-plus"></i></button>
                                                        </div>
                                                        </>
                    );
                    }

                    export default PopUpUpdateSocialLinks;