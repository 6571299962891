import React from 'react'
import { Helmet } from 'react-helmet'
import { PageHeader } from '../../components/v3/PageHeader'
import PageFooterV3 from '../../components/v3/PageFooter'
import { SectionTitle } from '../../components/v3/SectionTitle'

const PrivacyPolicy = () => {
    return (
        <div>
            <Helmet><title>Privacy Policy | Job Match</title></Helmet>
            <PageHeader />
            <div className="privacy-policy-page">
                <div className="container">
                    <SectionTitle subTitle={'Effective Date: 08.06.2024'} mainTitle={'Privacy policy'} />

                    <div className="privacy-policy">
                        <div className="section">
                            <h2>1. Introduction</h2>
                            <p>Welcome to CSR Jobmatch. We are committed to protecting your privacy and ensuring your personal information is handled in a secure and responsible manner. This Privacy Policy explains how we collect, use, and safeguard your personal data when you use our platform.</p>
                        </div>
                        <div className="section list">
                            <h2>2. Information We Collect</h2>
                            <div className="list-content">
                                <p><strong>Personal Information: </strong>When you register on our platform, we collect personal details such as your name, email address, phone number, and job preferences.</p>
                                <p><strong>Profile Data:</strong> We collect information related to your work experience, education, skills, and resume.</p>
                                <p><strong>Usage Data:</strong> We gather data about your interactions with our platform, including your IP address, browser type, and usage patterns.</p>
                                <p><strong>Communication Data:</strong> We may collect information from communications you have with us, such as email or support requests.</p>
                            </div>
                        </div>
                        <div className="section list">
                            <h2>3. How We Use Your Information</h2>
                            <div className="list-content">
                                <p><strong>To Provide Services: </strong>We use your information to match job seekers with employers, manage your profile, and communicate with you about job opportunities or candidates.</p>
                                <p><strong>To Improve Our Platform: </strong>We analyze usage data to enhance our services, develop new features, and ensure a better user experience.</p>
                                <p><strong>To Communicate with You: </strong>We may use your contact information to send updates, newsletters, or promotional materials related to our platform.</p>
                                <p><strong>To Comply with Legal Obligations: </strong>We may process your information to comply with legal requirements or protect our rights and interests.</p>
                            </div>
                        </div>
                        <div className="section list">
                            <h2>4. Sharing Your Information</h2>
                            <div className="list-content">
                                <p><strong>With Employers and Job Seekers: </strong>We share your information with employers or job seekers as necessary to facilitate job matching.</p>
                                <p><strong>With Service Providers: </strong>We may share your information with third-party service providers who assist us in operating our platform, such as payment processors or technical support.</p>
                                <p><strong>For Legal Reasons:</strong> We may disclose your information if required by law, or to protect our rights, property, or safety, or that of others.</p>
                            </div>
                        </div>
                        <div className="section">
                            <h2>5. Data Security</h2>
                            <p>We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                        </div>
                        <div className="section list">
                            <h2>6. Your Rights</h2>
                            <div className="list-content">
                                <p><strong>Access and Correction:</strong> You have the right to access and correct your personal information held by us.</p>
                                <p><strong>Opt-Out:</strong> You can opt-out of receiving marketing communications from us by following the unsubscribe instructions in those communications.</p>
                                <p><strong>Deletion:</strong> You may request the deletion of your personal information, subject to certain legal obligations and retention requirements.</p>
                            </div>
                        </div>
                        <div className="section">
                            <h2>7. Cookies and Tracking Technologies</h2>
                            <p>We use cookies and similar tracking technologies to collect information about your interactions with our platform. You can manage your cookie preferences through your browser settings.</p>
                        </div>
                        <div className="section">
                            <h2>8. Changes to This Privacy Policy</h2>
                            <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.</p>
                        </div>
                        <div className="section">
                            <h2>9. Contact us</h2>
                            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                            <h3>CSR Jobmatch</h3>
                            <p>Kigali, Rwanda </p>
                            <p>csrjobmatch@gmail.com</p>
                            <p>+250791902159</p>
                        </div>

                    </div>
                </div>
            </div>
            <PageFooterV3 />
        </div>
    )
}

export default PrivacyPolicy
