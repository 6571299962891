import React from 'react'
import { Link } from 'react-router-dom'
const Branding = () => {
    return (
        <Link to={'/'} className='branding'>
            <img src="/images/v2/jobmatch-logo.svg" alt="logo" className="logo" />
            <h1 className="site-title">Job Match</h1>
        </Link>
    )
}

export default Branding
