import React, { useState } from 'react';
import { useAuthentication } from '../../components/auth/checkAuth';
import { useNotification } from '../../components/services/notificationContext';
import api from '../../api';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import '../../assets/css/pages/profile_info/profile_info.css'
import SessionExpired from '../auth/sessionExpired';

const UpdateWorkExperiencePage = () => {
    const { showNotification } = useNotification()
    const { isAuth } = useAuthentication()
    const [isLoading, setIsLoading] = useState(false);
    const [jobTitle, setJobTitle] = useState("")
    const [employmentTitle, setEmploymentTittle] = useState("")
    const [resentCompany, setRecentCompany] = useState("")

    const WorkExperienceInfo = async (event) => {
        if (!jobTitle || !employmentTitle || !resentCompany) {
            showNotification('fail', "All fields are required")
            return
        }
        setIsLoading(true)
        const candidateInfo = {
            "job_title": jobTitle,
            "employment_title": employmentTitle,
            "company_name": resentCompany,
        }

        try {
            const response = await api.post("/candidates/update-profile/work-experience/", candidateInfo)
            if (response.status === 200) {
                window.location.href = "/candidate/education/form/"
            }

        } catch (error) {
            showNotification("fail", error.response.data.message)
        } finally {
            setIsLoading(false);
        }
        console.log(candidateInfo)
    }

    return (
        isAuth ?
            <div>
                <Helmet>
                    <meta charSet="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link rel="icon" href="favicon.ico" type="image/x-icon" />
                    <title>Work Experience | taleConnect</title>
                </Helmet>
                <div className="page-content">
                    <div className="form-content col">
                        <Link to="/candidate/skills/form/" className='button outline-button enroll-back-button'>Back</Link>
                        <div className="auth-forms">
                            <img className='logo' src={require("../../assets/img/logo.png")} alt="logo" />
                            <h2 className="title">Work Experience</h2>
                            <p>Tell us about your recent job details</p>
                            <form action="" className="proFileInfoForm" encType='multipart/form-data'>
                                <h3>Personal info</h3>
                                <input value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} type="text" name="jobTitle" id="jobTitle" placeholder='Most recent job title' />
                                <input value={employmentTitle} onChange={(e) => setEmploymentTittle(e.target.value)} type="text" name="employmentTitle" id="employmentTitle" placeholder='Employment title' />
                                <input value={resentCompany} onChange={(e => setRecentCompany(e.target.value))} type="text" name="resentCompany" id="resentCompany" placeholder='Most recent company' />
                                <button onClick={WorkExperienceInfo} id="WorkExperienceInfoButton" className={`button primary-button ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>{isLoading ? <img className='loading-icon' src={require("../../assets/icons/loading.png")} alt="" /> : 'Update'}</button>
                            </form>


                        </div>
                    </div>
                    <div className="background-col col">

                    </div>
                </div>
            </div>
            : <SessionExpired />
    )
}

export default UpdateWorkExperiencePage