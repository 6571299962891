import React from "react";

const SlicedText = ({ text, limit }) => {
    let slicedText;
    if (text.length <= 0) {
        return (
            <>"No data"</>
        )
    } else {
        if (text.length > limit) {
            slicedText = text.slice(0, limit) + "..."
        } else {
            slicedText = text
        }

        return <>{slicedText}</>
    }

}

export default SlicedText