import { React, useEffect, useState } from "react";
import { useAuthentication } from "../../components/auth/checkAuth";
import { useNotification } from "../../components/services/notificationContext";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import api from "../../api";
import SessionExpired from "../auth/sessionExpired";

const BusinessRegistrationPage = () => {
    const { isAuth } = useAuthentication()
    const { showNotification } = useNotification()
    const [isLoading, setIsLoading] = useState(false)
    const [businessIndustries, setBusinessIndustries] = useState([])
    const industries = [
        { id: 1, "name": "Technology" },
        { id: 2, "name": "Healthcare" },
        { id: 3, "name": "Finance" },
        { id: 4, "name": "Retail" },
        { id: 5, "name": "Manufacturing" },
        { id: 6, "name": "Energy" },
        { id: 7, "name": "Real Estate" },
        { id: 8, "name": "Transportation" },
        { id: 9, "name": "Education" },
        { id: 10, "name": "Entertainment" }
    ]

    useEffect(() => {
        setBusinessIndustries(industries)
    }, [])

    const saveCompanyDetails = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        const companyName = document.getElementById("companyName").value
        const companyWebsite = document.getElementById("companyWebsite").value
        const companyDescription = document.getElementById("companyDescription").value
        const establishedAt = document.getElementById("establishedAt").value
        const companyAddress = document.getElementById("companyAddress").value
        const businessIndustry = document.getElementById("businessIndustry").value
        const numberOfEmployees = document.getElementById("numberOfEmployees").value

        const business_data = {
            "name": companyName,
            "website_url": companyWebsite,
            "description": companyDescription,
            "established_at": establishedAt,
            "number_of_employees": numberOfEmployees,
            "address": companyAddress,
            "industry": businessIndustry,
        }
        if (!companyName || !companyDescription || !establishedAt || !companyAddress || !businessIndustry) {
            showNotification("fail", "All fields are required")
            setIsLoading(false)
        } else {
            try {

                const response = await api.post("/business/new_business/", business_data)
                if (response.status === 200) {
                    console.log(response.data)
                    const businessId = response.data.business.id
                    const businessSlug = response.data.business.slug
                    localStorage.setItem("businessId", businessId)
                    localStorage.setItem("businessIndustry", businessIndustry)
                    localStorage.setItem("businessSlug", businessSlug)
                    showNotification("success", "Business data are saved")
                    window.location.href = "/update/business/services/"
                }
            }
            catch (error) {
                showNotification("fail", error.response.data.message)
                setIsLoading(false)
            }
        }

    }
    return isAuth ? (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <title>Business registration | taleConnect</title>
            </Helmet>
            <div className="page-content">
                <div className="form-content col">
                    <Link to="/register/" className='button outline-button enroll-back-button'>Back</Link>
                    <div className="auth-forms">
                        <img className='logo' src={require("../../assets/img/logo.png")} alt="logo" />
                        <h2 className="title">Complete profile</h2>
                        <p>We need few details just to get started</p>

                        <form action="" className="proFileInfoForm" encType='multipart/form-data'>
                            <h3>Company info</h3>
                            {/* <div className="profile-upload">
                                                <div className="profile-icon">
                                                    <i className="fa-solid fa-camera"></i>
                                                </div>
                                                <input type="file" name="profilePic" id="ProfilePic" />
                                            </div> */}
                            <input type="text" name="companyName" id="companyName" placeholder='Company name' />
                            <input type="url" name="companyWebsite" id="companyWebsite" placeholder='Website url' />
                            <textarea rows={3} type="url" name="companyDescription" id="companyDescription" placeholder='Description' />
                            <input type="date" name="establishedAt" id="establishedAt" />

                            <input type="text" name="companyAddress" id="companyAddress" placeholder='Address' />
                            <input type="number" name="numberOfEmployees" id="numberOfEmployees" placeholder='Number of employees' />
                            {businessIndustries ?

                                <select name="businessIndustry" id="businessIndustry">
                                    <option value="" disabled>Select Industry</option>
                                    {businessIndustries.map(industry => (

                                        <option key={industry.id} value={industry.name}>{industry.name}</option>
                                    ))}
                                </select>

                                : ''}
                            <button onClick={saveCompanyDetails} id="RegisterButton" className={`button primary-button ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>{isLoading ? <img className='loading-icon' src={require("../../assets/icons/loading.png")} alt="" /> : 'Next'}</button>
                        </form>
                    </div>
                </div>
                <div className="background-col col">

                </div>
            </div>
        </div>
    ) : (<SessionExpired />)
}

export default BusinessRegistrationPage