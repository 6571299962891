import React from "react";

import '../assets/css/pages/dash_business/dash_business.css';

const SmallFooter = () => {
    const currentYear = new Date().getFullYear();
    return (
        <div>
            <div className="talentfooter">
                <p>@CSR Limited {currentYear}</p>
            </div>
        </div>
    )

}
export default SmallFooter