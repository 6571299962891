import React from "react";

const StepsCards = ({ step }) => {

    return (
        <div className="steps card">
            <div className="step">
                <div className={step === "1" ? `icon active` : `icon`}>
                    <i className="fa-solid fa-briefcase"></i>
                </div>

                <div className="step-content">
                    <p className="step-title">Step 1/3</p>
                    <p className="step-text">
                        Job Information
                    </p>
                </div>
            </div>
            <div className="divider"></div>
            <div className="step">
                <div className={step === "2" ? `icon active` : `icon`}>
                    <i className="fa-solid fa-bars"></i>
                </div>

                <div className="step-content">
                    <p className="step-title">Step 2/3</p>
                    <p className="step-text">
                        Job Description
                    </p>
                </div>
            </div>
            <div className="divider"></div>
            <div className="step">
                <div className={step === "3" ? `icon active` : `icon`}>
                    <i className="fa-solid fa-gift"></i>
                </div>

                <div className="step-content">
                    <p className="step-title">Step 3/3</p>
                    <p className="step-text">
                        Perks & Benefit
                    </p>
                </div>
            </div>
        </div>
    )
}

export default StepsCards