import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import api from '../../api'
import StepsCards from "../../components/jobs/form/stepsCards";
import { useParams, Link } from "react-router-dom";
import SiteHeader from "../../components/siteHeader";
import JobInformation from '../../components/jobs/form/newJobInformation'

import '../../assets/css/pages/newJob/newJob.css'
const NewJobPage = () => {
    const { businessSlug } = useParams();
    return (
        <div className="dashboard-content">
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <title>Business Dashboard | New Job</title>
            </Helmet>
            <SiteHeader />
            <div className="bread-crumbs container">
                <p>Dashboard /</p>
                <p className="current">New job</p>
            </div>

            <div className="forms container">
                <StepsCards />
                <JobInformation businessSlug={businessSlug} />
            </div>
        </div>




    )
}

export default NewJobPage