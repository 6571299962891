import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../../assets/css/pages/landingV3/landingV3.css'
import { Cancel01Icon, Link06Icon, Menu03Icon, Message01Icon, Search01Icon, SquareArrowLeft02Icon, UserSquareIcon } from 'hugeicons-react'
import ComingSoon, { JoinWaitingList } from '../comingSoon'
import PageFooter from '../../components/footer'
import { ActionButton } from '../../components/v3/ActionButton'
import { PageHeader } from '../../components/v3/PageHeader'
import { SectionTitle } from '../../components/v3/SectionTitle'
import { HowItWorksCard } from '../../components/v3/HowItWorksCard'
import { candidatesSteps, businessSteps, jobMatchFeatures } from '../../data/v3'
import PageFooterV3 from '../../components/v3/PageFooter'

const LandingV3 = () => {
    const [waitingListPopup, setWaitingListPopup] = useState(false)
    const [heroProfiles, setHeroProfiles] = useState([])

    useState(() => {
        setHeroProfiles([])
    })
    const toggleWaitingListPopup = () => {
        setWaitingListPopup(!waitingListPopup)
    }

    return (
        <main className='landingV3'>
            {
                waitingListPopup
                    ? <div className="join-list-popup">
                        <JoinWaitingList />
                    </div>
                    : ''
            }
            <PageHeader action={toggleWaitingListPopup} />
            <section className="hero-section-v3" id='welcome'>
                <div className="container">
                    <div className="text">
                        <h1 className='hero-title'>Find Your Dream Job or Perfect Candidate</h1>
                        <p className='hero-paragraph'>Connecting job seekers and employers made easy and comprehensive.</p>
                        <ActionButton action={toggleWaitingListPopup} />

                        {
                            heroProfiles && heroProfiles.length > 0 &&
                            <div className="hero-profiles">
                                <div className="images-text">
                                    <div className="hero-profile-images">
                                        <div className="hero-profile-img-v3"></div>
                                        <div className="hero-profile-img-v3"></div>
                                        <div className="hero-profile-img-v3"></div>

                                    </div>
                                    <p>Profiles</p>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="images">
                        <img src={'/images/shape.svg'} alt="" />
                        {/* <div className="hero-images">
                            <div className="image-card card1">
                                <img src="/images/v2/welcome.jpg" alt="" className="hero-image" />

                            </div>
                            <div className="image-card card2">
                                <img src="/images/v2/welcome.jpg" alt="" className="hero-image" />
                                <div className="content-card">
                                    <div className="icon">
                                        <Message01Icon />
                                    </div>
                                    <div className="text">
                                        <h4>Verified Candidate</h4>
                                        <p>Candidate that fits with your ambitions</p>
                                    </div>
                                </div>
                            </div>
                            <div className="image-card card3">
                                <img src="/images/v2/welcome.jpg" alt="" className="hero-image" />

                            </div>
                        </div>
                        <div className="shape">
                            <div className="shape1">
                                <div className="shape2">
                                    <div className="shape3"></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <section className="about-section-v3">
                <div className="container">
                    <div className="image-col">

                        <div className="images">
                            <div className="about-image"></div>
                            <div className="image-and-card">
                                {/* this will be worked on when we get the first job. by now, it is replaced by an image */}
                                {/* <div className="card">
                                    <div className="card-header">
                                        <img src="" alt="" className="car-icon" />
                                        <div className="card-header-text">
                                            <h5>Senior UI/UX Designer</h5>
                                            <div className="company-applications">
                                                <small>Spotify</small>
                                                <div className="dot"></div>
                                                <small>55 Applicants</small>
                                            </div>
                                        </div>

                                        <div className="job-info">
                                            <div className=" info experience-level">
                                                <div className="icon"></div>
                                                <small>Intermediate</small>
                                            </div>
                                            <div className=" info job-type">
                                                <div className="icon"></div>
                                                <small>Full time</small>
                                            </div>
                                            <div className=" info work-type">
                                                <div className="icon"></div>
                                                <small>Remote</small>
                                            </div>
                                        </div>

                                        <div className="salary-time-posted">
                                            <p>Salary | 300K  <small>Month</small></p>
                                            <div className="time-posted">
                                                <div className="icon"></div>
                                                <small>Posted 1 hr ago</small>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <img src="/images/v3/getting hired on job match.png" alt="" />
                                <img src="/images/v3/job match hiring.png" alt="" />
                            </div>
                        </div>

                    </div>
                    <div className="text-col">
                        <SectionTitle subTitle="About JobMatch" mainTitle="Connecting job seekers and employers made easy" />
                        <p>Discover your next career opportunity or find the ideal candidate with ease</p>
                        <Link to={'/register/'} className="button primary-button ">
                            Join us now
                        </Link>
                    </div>
                </div>
            </section>

            <section className="for-candidates" id='forCandidates'>
                <div className="container">
                    <SectionTitle subTitle={'How It Works'} mainTitle={'For candidates'} />

                    <div className="steps-container">
                        {
                            candidatesSteps.map((step, index) => (
                                <HowItWorksCard key={index} {...step} />
                            ))
                        }
                    </div>
                </div>
            </section>

            <section className="for-business" id='forBusiness'>
                <div className="container">
                    <SectionTitle subTitle={'How It Works'} mainTitle={'For Businesses'} />

                    <div className="steps-container">
                        {
                            businessSteps.map((step, index) => (
                                <HowItWorksCard key={index} {...step} />
                            ))
                        }
                    </div>
                </div>
            </section>

            <section className="features-v3" id='features'>
                <div className="container">
                    <SectionTitle subTitle="Features" mainTitle="What makes JobMatch truly special" />

                    <div className="featured-grid">

                        {
                            jobMatchFeatures.map((feature, index) => (
                                <div key={index} className="feature-card card">
                                    <div className="card-content">
                                        <h5>{feature.title}</h5>
                                        <p>{feature.description}</p>
                                    </div>
                                    <div className="feature-image">
                                        <img src={feature.imageLink} alt="" />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>

            <section className="call-to-action">
                <div className="container">
                    <SectionTitle subTitle={'Join us now'} mainTitle={'Sign up now to find your next job or the perfect candidate'} />
                    <Link to={'/register/'} className="button primary-button ">
                        Join us now
                    </Link>
                </div>
            </section>

            <PageFooterV3 />
        </main>
    )
}

export default LandingV3
