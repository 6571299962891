import React from 'react';
import CopyButton from './copyButton';

const ShareButtons = ({ url, title }) => {
    const shareOnFacebook = () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
    };

    const shareOnTwitter = () => {
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`, '_blank');
    };

    const shareOnLinkedIn = () => {
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`, '_blank');
    };

    return (
        <div className='share-buttons'>
            <button onClick={shareOnFacebook}> <div className="icon"><i className="fa-brands fa-facebook-f"></i></div> <span className='platform'>Share on</span><span>Facebook</span></button>
            <button onClick={shareOnTwitter}> <div className="icon"><i className="fa-brands fa-twitter"></i></div> <span className='platform'>Share on</span> <span>Twitter</span></button>
            <button onClick={shareOnLinkedIn}> <div className="icon"><i className="fa-brands fa-linkedin-in"></i></div> <span className="platform">Share on</span> <span>LinkedIn</span></button>
            <CopyButton text={url} />
        </div>
    );
};

export default ShareButtons;
