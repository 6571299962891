import React, { useState, useEffect } from 'react';
import { useAuthentication } from '../../components/auth/checkAuth';
import { Link } from 'react-router-dom';
import { API_URL, MEDIA_URL } from '../../api';
import { Helmet } from 'react-helmet';
import SiteHeader from '../../components/siteHeader';
import { useNotification } from '../../components/services/notificationContext';
import '../../assets/css/pages/dash_business/dash_business.css'
import '../../assets/css/pages/profile-page/profile.css'
import PageFooter from '../../components/footer';
import api from '../../api';
import PdfPreview from '../../components/pdfProfile';
import BusinessProfile from '../../components/profile/businessProfile';
import SessionExpired from '../auth/sessionExpired';
import PopUpUpdateWorkExperience from '../../components/popUps/updateWorkExxperinces';
import PopUpSocialLinks from '../../components/popUps/updateSocialLinks';
import PopUpAdditionalInfo from '../../components/popUps/updateAdditionalInfo';
import PopUpUpdateAdditionalInfo from '../../components/popUps/updateAdditionalInfo';
import PopUpUpdateSocialLinks from '../../components/popUps/updateSocialLinks';


const ProfilePage = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(useAuthentication())
    const [userData, setUserData] = useState({
        user: {},
        academicEducation: [],
        skills: [],
        workExperience: [],
        location: {},
        profilePicture: null,
        resume: null,
        isEmployed: false,
        linkedinUrl: null,
        portfolioUrl: null,
        bio: null,
        dateCreated: null,
        // socialLinks: {}
        additionalInfo: {},
        languages: []
    });

    const { showNotification } = useNotification()
    const [showAddBioPopup, setShowAddBioPopup] = useState(false);
    const [newBio, setNewBio] = useState('');
    const [isSocialPopupVisible, setSocialPopupVisible] = useState(false);
    const [isBioPopupVisible, setBioPopupVisible] = useState(false);
    const [isWorkPopupVisible, setWorkPopupVisible] = useState(false);
    const [isAdditionalPopupVisible, setAdditionalPopupVisible] = useState(false);
    const [showAddWorkExperiencePopup, setShowAddWorkExperiencePopup] = useState(false);
    const [showAddSocialLinksPopup, setShowAddSocialLinksPopup] = useState(false);

    const [formData, setFormData] = useState({
        employment_title: '',
        company_name: '',
        started_from: '',
        ended_at: ''
    });
    const [showAddAdditionalInfoPopup, setShowAddAdditionalInfoPopup] = useState(false);


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await api.get(`${API_URL}/candidates/profile-data/`);
                const data = response.data;
                if (response.status === 200) {
                    setUserData({
                        user: data.user,
                        academicEducation: data.academic_education,
                        skills: data.skills,
                        workExperience: data.work_experience,
                        location: data.location,
                        profilePicture: data.profile_picture,
                        resume: data.resume,
                        isEmployed: data.is_employed,
                        linkedinUrl: data.linkedin_url,
                        portfolioUrl: data.portfolio_url,
                        bio: data.bio,
                        dateCreated: data.date_created,
                        additionalInfo: {
                            phone_number: data.phone_number,
                            languages: data.languages,
                        }

                        //socialLinks: data.social_links
                    });
                }

            } catch (error) {
                if (error.response.status === 403) {
                    setIsLoggedIn(false)
                }
            }
        };

        fetchUserData();
    }, []);
    //Biography update
    const handleBioChange = (event) => {
        setNewBio(event.target.value);
    };

    const handleSubmitBio = async () => {
        try {
            const response = await api.post('candidates/update/bio/', { bio: newBio });
            console.log('Bio updated successfully:', response.data);
            setUserData(prevUserData => ({ ...prevUserData, bio: newBio }));
            setShowAddBioPopup(false);
            setBioPopupVisible(false);
        } catch (error) {
            console.error('Error updating bio:', error);
        }
    };


    const showAddSocialLinksPopupFn = () => {
        setShowAddSocialLinksPopup(!showAddSocialLinksPopup);
        setSocialPopupVisible(!isSocialPopupVisible);


    };


    const showAddWorkExperiencePopupFn = () => {
        setShowAddWorkExperiencePopup(true);
        setWorkPopupVisible(true);
    }
    const hideAddWorkExperiencePopupFn = () => {
        setShowAddWorkExperiencePopup(false);
        setWorkPopupVisible(false);
    }

    const showAddBioPopupFn = () => {
        setShowAddBioPopup(true);
        setBioPopupVisible(true);
        console.log(showAddBioPopup)
    }



    const hideAddBioPopupFn = () => {
        setShowAddBioPopup(false);
        setBioPopupVisible(false);
        console.log(showAddBioPopup)
    }
    //work Experience
    const handleWorkExperienceChange = (event) => {
        setFormData(event.target.value);
    };





    //      const toggleAddWorkExperiencePopup = () => {
    //     setShowAddWorkExperiencePopup(!showAddWorkExperiencePopup);
    //     setPopupVisible(!isPopupVisible);
    // };




    const toggleAddAdditionalInfoPopup = () => {
        setShowAddAdditionalInfoPopup(!showAddAdditionalInfoPopup);
        setAdditionalPopupVisible(!isAdditionalPopupVisible);
    };

    const businessObj = localStorage.getItem("business")
    const business = businessObj ? JSON.parse(businessObj) : null

    if (business) {
        return (
            <BusinessProfile />
        )

    } else {

        return (
            isLoggedIn
                ? <div>
                    <Helmet>
                        <meta charSet="UTF-8" />
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <link rel="icon" href="favicon.ico" type="image/x-icon" />
                        <title>Profile | CSR Staffing</title>
                    </Helmet>
                    <div className="dashboard-content">
                        <SiteHeader />
                        <div className='main-section container'>
                            <div className="bread-crumbs">
                                <Link to={"/dashboard/"}>Dashboard/</Link>
                                <p className='current'>Profile</p>
                            </div>
                            <section className='profile-header'>
                                <div className="first">
                                    {userData.profilePicture ? (
                                        <img className='user-profile-pic' src={`${MEDIA_URL}/${userData.profilePicture}`} alt="Profile" />
                                    ) : (
                                        <img src={require("../../assets/img/logo.png")} alt="Profile" />
                                    )}
                                    <div className="text">
                                        <h3>
                                            {userData.user.first_name} {userData.user.last_name ? userData.user.last_name : ''}
                                        </h3>
                                        <div className="profile-content">
                                            <p>
                                                <img src={require("../../assets/css/pages/profile-page/pin.png")} alt="" />
                                                <span>{userData.location.name ? userData.location.name : 'No location available'}</span>
                                            </p>
                                            <p>
                                                <img src={require("../../assets/css/pages/profile-page/bulletpoint.png")} alt="" />
                                                <span>{userData.profession ? userData.profession : 'No profession available'}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <button className='edit-profile-button'>
                                    <i className="fa-regular fa-pen-to-square"></i> <span className='button-text'>Edit Profile</span>
                                </button>
                            </section>


                            <section className='main-content'>
                                <div className='text-content'>

                                    <div className='biography'>
                                        <div className='render-biography'>
                                            {userData.bio ? (
                                                <div className='biography card'>
                                                    <h3>Biography <i className="fa-regular fa-pen-to-square normalbio-x"></i></h3>
                                                    <p>{userData.bio}</p>
                                                </div>
                                            ) : (
                                                <div className='render-biography card'>
                                                    <img src={require("../../assets/css/pages/profile-page/Social biography-rafiki 1.png")} alt="" />
                                                    {isBioPopupVisible && <div className="overlay" onClick={hideAddBioPopupFn}></div>}
                                                    {showAddBioPopup && (
                                                        <div className="add-bio-popup card">
                                                            <h3>Biography <i onClick={hideAddBioPopupFn} className="fa-solid fa-xmark popup-x"></i></h3>
                                                            <textarea value={newBio} onChange={handleBioChange} placeholder="Add your biography here" />
                                                            <div className='save-cancel'>
                                                                <button onClick={hideAddBioPopupFn}>Cancel</button>
                                                                <button className='save' onClick={handleSubmitBio}>Save</button>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <button onClick={showAddBioPopupFn}>Add Biography <i className="fa-solid fa-plus"></i></button>
                                                </div>

                                            )}
                                        </div>
                                    </div>


                                    <div className='work-experience card'>
                                        {userData.formData ? (
                                            <>
                                                <h3>Work Experience <i className="fa-solid fa-plus other-icon work-icon"></i></h3>

                                                <div className="work-body">
                                                    <div className="work-img">
                                                        <img src={require("../../assets/css/pages/profile-page/spotify_symbol.svg.png")} alt="" />
                                                    </div>
                                                    <div className="work-text">

                                                        <h4>{userData.formData.employment_title}<i className="fa-regular fa-pen-to-square"></i></h4>
                                                        <div className="work-content">
                                                            <span>{userData.formData.company_name}</span>
                                                            <span>
                                                                <img src={require("../../assets/css/pages/profile-page/bulletpoint.png")} alt="" />
                                                                {userData.formData.started_from} - {userData.formData.ended_at}
                                                            </span>
                                                        </div>
                                                        <p>{userData.formData.description ? userData.formData.description : "Description not available"}</p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <PopUpUpdateWorkExperience setUserData={setUserData} hideFunction={showAddWorkExperiencePopupFn} />
                                        )}
                                    </div>

                                    <section className='education card'>
                                        <h3>Educations <i className="fa-solid fa-plus"></i></h3>
                                        <div className='education-body'>
                                            <img src={require("../../assets/css/pages/profile-page/harvard_university_symbol.svg.png")} alt="" className='work-img' />
                                            <div className='education-content'>
                                                <div className='edu-text'>
                                                    {userData.academicEducation.map((education, index) => (
                                                        <div key={index}>
                                                            <h4>{education.name ? education.name : "School name not available"}  <i className="fa-regular fa-pen-to-square"></i></h4>
                                                            <div className='under-content'>
                                                                <p>{education.degree_attained ? education.degree_attained : "Degree not available"}</p>
                                                                <span> <img src={require("../../assets/css/pages/profile-page/bulletpoint.png")} alt="" /> {education.degree_info ? education.degree_info : "No data available"}</span>
                                                            </div>
                                                            <div className='year'>
                                                                <span>{education.started_year ? education.started_year : "Start year not available"} - {education.ended_year ? education.ended_year : "End year not available"}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section className='skills card'>
                                        <h3>Skills<i className="fa-solid fa-plus"></i></h3>
                                        <div className='content'>
                                            <div className='buttons'>
                                                <div className="button-row">
                                                    <i className="fa-regular fa-pen-to-square"></i>
                                                    {userData.skills.map((skill, index) => (
                                                        <p key={index} className="skill-button">{skill.name}</p>

                                                    ))}
                                                </div>

                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <section className='side-bar'>

                                    <div className='additional-details card'>
                                        <div className='additional-content'>
                                            <h4>Additional Details  <i className="fa-regular fa-pen-to-square"></i></h4>

                                            <p><i className="fa-solid fa-envelope"></i> <span> Email </span></p>
                                            <p className='second-paragraph'>{userData.user.email ? userData.user.email : <button className='add-manual'>Add email <i className="fa-solid fa-plus"></i></button>}</p>

                                            {userData.additionalInfo ? (
                                                <>
                                                    <p><i className="fa-solid fa-mobile-screen-button"></i> <span> Phone </span></p>
                                                    <p className='second-paragraph'>{userData.additionalInfo.phone_number}</p>
                                                    <p><i className="fa-solid fa-language"></i> <span>Language </span></p>
                                                    {userData.additionalInfo.languages && userData.additionalInfo.languages.map((language, index) => (
                                                        <p className='second-paragraph' key={index}>{language.name}</p>
                                                    ))}
                                                </>
                                            ) : (
                                                <PopUpUpdateAdditionalInfo setUserData={setUserData} hideFunction={toggleAddAdditionalInfoPopup} />
                                            )}
                                        </div>
                                    </div>



                                    <div className='social-links card'>
                                        {userData.socialLinks ? (
                                            <>
                                                <h4>Social links  <i className="fa-regular fa-pen-to-square"></i></h4>
                                                <p><i className="fa-solid fa-envelope"></i> <span> {userData.socialLinks.handle} </span></p>
                                                <p className='second-paragraph'> {userData.socialLinks.link}</p>
                                            </>
                                        ) : (
                                            <PopUpUpdateSocialLinks setUserData={setUserData} hideFunction={showAddSocialLinksPopupFn} />
                                        )}

                                    </div>

                                    {/* <p><i className="fa-solid fa-mobile-screen-button"></i> <span> X </span></p>
                                    <p className='second-paragraph'>{userData.x_url ? userData.x_url : <button className='add-manual'>Add language <i className="fa-solid fa-plus"></i></button>}</p>
                                    <p><i className="fa-solid fa-globe"></i> <span>Website </span></p>
                                <p className='second-paragraph'>{userData.portfolioUrl ? userData.portfolioUrl : <button className='add-manual'>Add language <i className="fa-solid fa-plus"></i></button>}</p> */}

                                    {userData.resume ? (
                                        <div className='resume card'>
                                            <h4>Resume <i className="fa-regular fa-pen-to-square"></i></h4>
                                            <PdfPreview url={`${MEDIA_URL}${userData.resume}`}></PdfPreview>
                                        </div>
                                    ) : (
                                        <div className='render-details card'>
                                            <div className='resume-content'>
                                                <img src={require("../../assets/css/pages/profile-page/gala_file-document.png")} alt="" />
                                                <h2>Click this area or drag & drop the Resume/CV in PDF</h2>
                                                <h2>form</h2>
                                                <p>You can upload PDF files up to 3 MB in size</p>
                                            </div>
                                        </div>
                                    )}
                                </section>
                            </section>

                        </div>
                        <PageFooter />

                    </div>
                </div>

                : <SessionExpired />
        )
    }

}
export default ProfilePage;
