import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet";
import SiteHeader from "../../siteHeader";
import StepsCards from "./stepsCards";
import { useNotification } from "../../services/notificationContext";
import api from "../../../api";
import IsLoading from "../../loadingIcon";

// styles
import '../../../assets/css/pages/newJob/newJob.css'

const UpdateJobDescription = () => {
    const { jobSlug } = useParams()
    const [jobObj, setJobObj] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const { showNotification } = useNotification()
    const updateUrl = `/jobs/job/${jobSlug}/description/`
    // form data

    const [description, setDescription] = useState("")
    const [responsibilities, setResponsibilities] = useState("")
    const [qualifications, setQualifications] = useState("")
    const [niceToHave, setNiceToHave] = useState("")
    // get job data
    useEffect(() => {
        const fetchBlog = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(updateUrl);
                if (response.status === 200) {
                    setJobObj(response.data);
                    setIsLoading(false)
                }
            } catch (error) {
                // Handle errors
            } finally {
                setIsLoading(false);
            }
        };
        fetchBlog();
    }, []);

    const updateDescriptions = async () => {
        setIsUpdating(true)
        const jobData = {
            "description": description,
            "responsibilities": responsibilities,
            "qualifications": qualifications,
            "nice_to_have": niceToHave,
        }

        try {
            const response = await api.post(updateUrl, jobData)
            if (response.status === 200) {
                showNotification("success", "Job is updated")
                setIsUpdating(false)
                window.location.href = `/job/${jobSlug}/benefits/`
            }
        } catch (error) {
            if (error.response.status === 400) {
                showNotification("fail", error.response.data.message)
                setIsLoading(false)
            }
            else {
                showNotification("fail", "There was an error while updating your job")
                setIsUpdating(false)
            }
        } finally {
            setIsUpdating(false)
        }
    }
    return (

        <div className="dashboard-content">
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <title>Business Dashboard | New Job</title>
            </Helmet>
            <SiteHeader />
            <div className="bread-crumbs container">
                <p>Dashboard /</p>
                <p className="current">New job</p>
            </div>

            <div className="container forms">
                <StepsCards step={"2"} />
                {
                    isLoading
                        ? <IsLoading text={"job data"} />
                        : (
                            Object.keys(jobObj).length > 0

                                ? <div className="form card">
                                    <form action="">
                                        <div className="row">
                                            <div className="input-info">
                                                <h4 className="input-title">Job Descriptions</h4>
                                                <p className="input-desc">Give a brief of the job</p>
                                            </div>
                                            <div className="input-container">
                                                <textarea
                                                    rows={8}
                                                    type="text"
                                                    name="jobTitle"
                                                    id="jobTitle"
                                                    onChange={(e) => { setDescription(e.target.value) }}
                                                    placeholder="Eg: Enter job description" />
                                                <div className="help">Maximum 500 characters</div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="input-info">
                                                <h4 className="input-title">Responsibilities</h4>
                                                <p className="input-desc">Outline the core responsibilities of the position</p>
                                            </div>
                                            <div className="input-container">
                                                <textarea
                                                    rows={8}
                                                    type="text"
                                                    name="jobTitle"
                                                    id="jobTitle"
                                                    onChange={(e) => { setResponsibilities(e.target.value) }}
                                                    placeholder="Eg: Enter job responsibilities" />
                                                <div className="help">Maximum 500 characters</div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="input-info">
                                                <h4 className="input-title">Who You Are</h4>
                                                <p className="input-desc">Add your preferred candidates qualifications</p>
                                            </div>
                                            <div className="input-container">
                                                <textarea
                                                    rows={8}
                                                    type="text"
                                                    name="jobTitle"
                                                    id="jobTitle"
                                                    onChange={(e) => { setQualifications(e.target.value) }}
                                                    placeholder="Eg: Enter qualifications" />
                                                <div className="help">Maximum 500 characters</div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="input-info">
                                                <h4 className="input-title">Nice-To-Haves</h4>
                                                <p className="input-desc">Add nice-to-have skills and qualifications for the role to encourage a more diverse set of candidates to apply</p>
                                            </div>
                                            <div className="input-container">
                                                <textarea
                                                    rows={8}
                                                    type="text"
                                                    name="jobTitle"
                                                    id="jobTitle"
                                                    onChange={(e) => { setNiceToHave(e.target.value) }}
                                                    placeholder="Eg: Enter nice-to-haves" />
                                                <div className="help">Maximum 500 characters</div>
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            onClick={updateDescriptions}
                                            className="primary-button">
                                            {isUpdating
                                                ? (<img className='loading-icon' src={require("../../../assets/icons/loading.png")} alt="" />)
                                                : 'Save and continue'}
                                        </button>
                                    </form>

                                </div>
                                : <p>No job found</p>
                        )
                }
            </div>
        </div>



    )
}

export default UpdateJobDescription