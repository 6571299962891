import React from "react";
import { FaRegEdit } from "react-icons/fa";
import { CiSquarePlus } from "react-icons/ci";
import "../../assets/css/pages/company_info/company_info.css"

const CompanyInfo = ({ business }) => {
  return (
    <div className="company_info">
      <div className="contents">
        <div className="title">
          <h3>Biography</h3>
          <FaRegEdit id="edit" />
        </div>
        <p>
          {business.description || 'No bio provided'}
        </p>


      </div>
      <div className="services card">
        <div className="title-service">
          <h3>Services</h3>
          <div className="icons">
            <CiSquarePlus id="add" />
          </div>
        </div>
        <div className="texts">
          {business.services.length > 0
            ? business.services.map((service, index) => (
              <p key={index}>{service.name}</p>
            ))
            : 'No services provided'
          }
        </div>
      </div>
    </div>
  );
}

export default CompanyInfo;
