import React, { useState, useEffect } from 'react';
import api from '../../api';
import ProfilePage from "../../pages/dashboard/profilePage";

 


const PopUpUpdateWorkExperience = ({ setUserData, hideFunction}) => {
    const [showAddWorkExperiencePopup, setShowAddWorkExperiencePopup] = useState(false);
    const [isWorkPopupVisible, setWorkPopupVisible] = useState(false);
    
    const [employmentTitle, setEmploymentTitle] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [startedFrom, setStartedFrom] = useState("");
    const [endedAt, setEndedAt] = useState("");
    
    const [formData, setformData] = useState({
        employmentTitle: '',
        companyName: '',
        startedFrom: '',
        endedAt: '',
      });
    // useEffect(() => {
    //   const fetchData = async () => {
    //     const response = await api.get('candidates/update/experiences/');
    //     setWorkExperienceData(response.data);
    //   };
    
    //   fetchData();
    // }, []);
   
    const handleSubmitWorkExperience = async (event) => {
        event.preventDefault();
        console.log(employmentTitle);
        console.log(companyName);
        const formData = {
            "company_name": companyName,
            "employment_title": employmentTitle,
            "started_from": startedFrom,
            "ended_at": endedAt,
        };
        console.log(formData);
    
        try {
            
            const response = await api.post('candidates/update/experiences/', formData);
            console.log('Work experience updated successfully:', response.data);
            
            setUserData(prevUserData => ({ ...prevUserData, formData: formData }));
            
           
            hideFunction();
           
            showAddWorkExperiencePopupFn();
        } catch (error) {
            if (error.response && error.response.status === 400) {
                console.error("Error:", error.response.data.message);
            } else {
                console.error("Error:", error);
            }
        }
        
    };
    // const hideAddWorkExperiencePopupFn = () => {
    //     setShowAddWorkExperiencePopup(false);
    //     setWorkPopupVisible(false);
    //     console.log(hideAddWorkExperiencePopupFn);
        

    // };
    const showAddWorkExperiencePopupFn = () => {
        setShowAddWorkExperiencePopup(!showAddWorkExperiencePopup);
        setWorkPopupVisible(!isWorkPopupVisible);
      
        
    };

  

    return (
        <>
           {isWorkPopupVisible && (<div className="overlay" onClick={showAddWorkExperiencePopupFn}></div>)}
           {showAddWorkExperiencePopup && (
        <div className="add-workexperience-popup card">
            
         
             
            <h3>Experience <i onClick={ showAddWorkExperiencePopupFn} className="fa-solid fa-xmark popup-x"></i></h3>
            <form onSubmit={handleSubmitWorkExperience}>
                <label>
                    <input type="text" name='employment_title' placeholder='Job title' value={employmentTitle} onChange={(e) => setEmploymentTitle(e.target.value)} />
                </label>
                <label>
                    <input type="text" name='company_name' placeholder='Company name' value={companyName} onChange={(e) => setCompanyName(e.target.value)} required />
                </label>
                <label>
                    <input type="date" name=" started_from" placeholder='Start date' value={startedFrom} onChange={(e) => setStartedFrom(e.target.value)} />
                </label>
                <label>
                    <input type="date" name="ended_at" placeholder='End date' value={endedAt} onChange={(e) => setEndedAt(e.target.value)} required />
                </label>
                <textarea placeholder='Description' name="description"></textarea>

                <div className='save-cancel'>
                    <button type="button" onClick={ showAddWorkExperiencePopupFn}>Cancel</button>
                    <button type="submit"  className='save' onClick={handleSubmitWorkExperience}>Save</button>
                </div>
            </form>
        
        </div>
           )}
           <div className='work-button'>
           <button className='workexperience-button' onClick={showAddWorkExperiencePopupFn}>Add Work Experience <i className="fa-solid fa-plus"></i></button>
           </div>
        </>
    );
}
    

export default PopUpUpdateWorkExperience;
