import React from "react";
import { Link } from "react-router-dom";
const NoDataContainer = ({ title, text, actionText, actionLink }) => {
    return (
        <div className="no-data-container">
            <h3 className='no-data-text'>{title}</h3>
            <p>{text}</p>
            <Link to={actionLink} className='button primary-button action-link'>{actionText}</Link>
        </div>
    )
}

export default NoDataContainer