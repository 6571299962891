import React from 'react';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import './App.css';
import './assets/css/main.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import RegistrationPage from './pages/auth/registrationPage';
import ProfileInfoPage from './pages/register/profileInfo';
import UpdateSkillsPage from './pages/accounts/updateSkills';
import UpdateWorkExperiencePage from './pages/register/profileWorExperience';
import UpdateAcademicEducationPage from './pages/register/profileAcademicEducation';
import LoginPage from './pages/auth/login';
import ForgotPasswordPage from './pages/auth/forgotPassword';
import VerifyCodePage from './pages/auth/verifyCode';
import ResetPasswordPage from './pages/auth/restPassword';
import BusinessRegistrationPage from './pages/register/businessRegistration';
import UpdateBusinessServicesPage from './pages/register/updateBusinessServices';
import NewJobPage from './pages/dashboard/newJob';
import UpdateJobDescription from './components/jobs/form/updateJobDescription';
import UpdateJobBenefits from './components/jobs/form/updateJobBenefits';
import IndexCandidatesPage from './pages/indexCandidates';
import IndexBusinessPage from './pages/indexBusiness';
import Dashboard from './pages/dashboard/dashboard';
import ProfilePage from './pages/dashboard/profilePage';
import JobsPage from './pages/dashboard/jobsPage';
import ApplicationsPage from './pages/dashboard/applicationsPage';
import MessagesPage from './pages/dashboard/messagesPage';
import TrainingsPage from './pages/trainings';
import ProfileFiles from './pages/register/profileFiles';
import SingleBlogPage from './pages/dashboard/singleJobPage';
import BusinessSingleBlogPage from './pages/dashboard/jobsApplicants';
import { NotificationProvider } from './components/services/notificationContext';
import ForbiddenAccess from './pages/errorPages/ForbiddenAccess';
import PageNotFound from './pages/errorPages/PageNotFound';
import TimeOut from './pages/errorPages/TimeOut';
import CreateProfile from './pages/accounts/createProfile';
import SettingsPage from './components/profile/companySettings';
import ProtectedRoute from './components/ProtectedRoute';
import ThankYouPage from './pages/thankYou';
import ComingSoon from './pages/comingSoon';
import LandingPageV2 from './pages/landing/landingPageV2';
import LandingV3 from './pages/landing/landingV3';
import DashBusiness from './pages/dashboard/dashBusiness';
import DashCandidates from './pages/dashboard/dashCandidates';
import TermsAndConditions from './pages/legal/termsAndConditions';
import PrivacyPolicy from './pages/legal/privacyPolicy';

function App() {
  return (
    <div>
      <NotificationProvider>
        <Router>
          <Routes>
            {/* Coming soon */}

            <Route path="/thank-you/" element={<ThankYouPage />} />
            {/* Public routes (Auth pages) */}
            <Route path="/register/" element={<RegistrationPage />} />
            <Route path="/login/" element={<LoginPage />} />
            <Route path="/forgot-password/" element={<ForgotPasswordPage />} />
            <Route path="/forgot-password/verify-code/" element={<VerifyCodePage />} />
            <Route path="/reset-password/" element={<ResetPasswordPage />} />

            {/* Error pages (public) */}
            <Route path="/403/" element={<ForbiddenAccess />} />
            <Route path="/404/" element={<PageNotFound />} />
            <Route path="coming-soon" element={<PageNotFound />} />
            <Route path="/408/" element={<TimeOut />} />
            <Route path='/terms-and-conditions/' element={<TermsAndConditions />}></Route>
            <Route path='/privacy-policy/' element={<PrivacyPolicy />}></Route>

            {/* Protected routes */}
            <Route path="/" element={<LandingV3 />} />
            {/* <Route path="/business/" element={<IndexBusinessPage />} />
            <Route path="/coming-soon/" element={<ProtectedRoute><ComingSoon /></ProtectedRoute>} /> */}

            {/* Candidate routes (protected) */}
            <Route path="/candidate/create-profile/" element={<ProtectedRoute><CreateProfile /></ProtectedRoute>} />
            <Route path="/candidate/profile/form/" element={<ProtectedRoute><ProfileInfoPage /></ProtectedRoute>} />
            <Route path="/candidate/skills/form/" element={<ProtectedRoute><UpdateSkillsPage /></ProtectedRoute>} />
            <Route path="/candidate/work-experience/form/" element={<ProtectedRoute><UpdateWorkExperiencePage /></ProtectedRoute>} />
            <Route path="/candidate/education/form/" element={<ProtectedRoute><UpdateAcademicEducationPage /></ProtectedRoute>} />
            <Route path="/candidate/profile/files/" element={<ProtectedRoute><ProfileFiles /></ProtectedRoute>} />

            {/* Business routes (protected) */}
            <Route path="/business/register/" element={<ProtectedRoute><BusinessRegistrationPage /></ProtectedRoute>} />
            <Route path="/update/business/services/" element={<ProtectedRoute><UpdateBusinessServicesPage /></ProtectedRoute>} />
            <Route path="/business/:businessSlug/new-job/" element={<ProtectedRoute><NewJobPage /></ProtectedRoute>} />

            {/* Other protected routes */}
            <Route path="/dashboard/candidates/" element={<ProtectedRoute><DashCandidates /></ProtectedRoute>} />
            <Route path="/dashboard/business/" element={<ProtectedRoute><DashBusiness /></ProtectedRoute>} />

            <Route path="/dashboard/profile/" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
            <Route path="/dashboard/business/jobs/" element={<ProtectedRoute><JobsPage /></ProtectedRoute>} />
            <Route path="/dashboard/business/job/:jobSlug" element={<ProtectedRoute><SingleBlogPage /></ProtectedRoute>} />
            <Route path="/dashboard/business/job/:jobSlug/description/" element={<ProtectedRoute><UpdateJobDescription /></ProtectedRoute>} />
            <Route path="/dashboard/business/job/:jobSlug/benefits/" element={<ProtectedRoute><UpdateJobBenefits /></ProtectedRoute>} />
            <Route path="/dashboard/business/job/:jobSlug/applicants/" element={<ProtectedRoute><BusinessSingleBlogPage /></ProtectedRoute>} />
            <Route path="/dashboard/business/trainings/" element={<ProtectedRoute><TrainingsPage /></ProtectedRoute>} />
            <Route path="/dashboard/business/applications/" element={<ProtectedRoute><ApplicationsPage /></ProtectedRoute>} />
            <Route path="/dashboard/business/messages/" element={<ProtectedRoute><MessagesPage /></ProtectedRoute>} />
            <Route path="/dashboard/business/settings" element={<ProtectedRoute><SettingsPage /></ProtectedRoute>} />
          </Routes>
        </Router>
        <Helmet>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"></link>
        </Helmet>
        <Toaster
          position="bottom-center"
          reverseOrder={false}
        />
      </NotificationProvider>
    </div>
  );
}

export default App;