import React, { useState } from 'react';
import { API_URL } from '../../api';
import { Helmet } from 'react-helmet';
import Branding from '../../components/v3/Branding';

function getCookie(name) {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : '';
}
const ResetPasswordPage = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)


    const ResetPassword = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        const password = document.getElementById("password").value;
        const confirmPassword = document.getElementById("confirmPassword").value;

        if (password === "" || confirmPassword === "") {
            setError("Password and confirm password are required")
        }
        else {
            if (password !== confirmPassword) {
                setError("Passwords do not match")
                return
            }
            if (password.length < 6) {
                setError("Password is too weak")
                return
            }

            try {
                const csrftoken = getCookie()
                const passwordData = {
                    "password": password,
                    "confirm_password": confirmPassword
                }
                const response = await fetch(`${API_URL}/accounts/reset-password/`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrftoken,
                    },
                    body: JSON.stringify({ passwordData })
                })
                const data = await response.json()
                if (response.ok) {
                    window.location.href = "/login/"

                } else {
                    setIsLoading(false)
                    setError(data.message)
                    return
                }
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        }

    }
    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <title>Reset password | jobMatch</title>
            </Helmet>

            <div className="page-content">
                {error && (<div className='error-message'>{error}</div>)}
                <div className="container auth-forms">
                    <Branding />
                    <h1 className="title">Reset your password</h1>
                    <p>Almost done. Enter your new password and you’re good to go</p>
                    <form action="" className="registerForm">
                        <input type="password" name="password" id="password" placeholder='New password' />
                        <input type="password" name="password" id="confirmPassword" placeholder='Confirm new password' />
                        <button onClick={ResetPassword} id="ResetPasswordButton" className={`button primary-button ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>{isLoading ? <img className='loading-icon' src={require("../../assets/icons/loading.png")} alt="" /> : 'Reset password'}</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordPage