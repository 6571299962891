import { React, useState } from "react";
import { useNotification } from "../components/services/notificationContext";
import '../assets/css/pages/comingSoon/coming_soon.css'
import ShareButtons from "../components/shareButtons";
import api, { DOMAIN_NAME } from "../api";

const handleSubscribe = async (fullName, email, createMeAccount, type) => {
    try {
        if (email === '') {
            return [false, "At least an email is required"]
        }
        const subscriptionData = {
            "email": email,
            "full_name": fullName,
            "create_me_account": createMeAccount,
            "type": type
        }
        console.log(subscriptionData)
        const response = await api.post("/accounts/subscribe/", subscriptionData)
        if (response.status === 200) {
            return [true, response.data.message]
        }

    } catch (error) {
        if (error.response.status === 400) {
            console.log("There was an error", error.response.data)
            return [false, error.response.data.message]
        }
        return [false, error.message]
    }
}

export const JoinWaitingList = () => {
    const { showNotification } = useNotification()
    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [createMeAccount, setCreateMeAccount] = useState(true)
    const [isLoading, setIsLoading] = useState(false)


    const handleJoin = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        const type = "Earlier joiners"
        const [joined, message] = await handleSubscribe(fullName, email, createMeAccount, type)
        if (joined) {
            showNotification("success", message)
            setIsLoading(false)
            setTimeout(() => {
                window.location.href = "/thank-you/"
            }, 2000);
        } else {
            showNotification("fail", message)
            setIsLoading(false)
        }
    }
    return (
        <div className="join-list">
            <h3>Join the waiting list</h3>
            <p>When we launch, people on waiting list are the first to know</p>
            <form className="form">

                <input
                    onChange={(e) => setFullName(e.target.value)}
                    value={fullName}
                    type="text"
                    name="fullName"
                    id="fullName"
                    placeholder="Full name" />
                <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    type="email"
                    name="email" id="email"
                    placeholder="Your email address" />

                <div className="create-account acceptance">
                    <input
                        onChange={(e) => (setCreateMeAccount(!createMeAccount))}
                        type="checkbox"
                        name="createMeAccount"
                        id="createMeAccount"
                        checked={createMeAccount ? true : false}
                    />
                    <label htmlFor="createMeAccount">Create me an account too</label>
                </div>
                <button onClick={handleJoin} type="button" className="primary-button">
                    {
                        isLoading
                            ? <div className="isolating-icon">
                                <img className='loading-icon' src={require("../assets/icons/loading.png")} alt="" />
                                Joining
                            </div>
                            : 'Join now'

                    }
                </button>
                <p>By click on Join, you allow us to send you updates related to this platform</p>
            </form>
        </div>
    )
}
const ComingSoon = ({ stage, launchDate, url }) => {
    const [popupOpen, setPopupOpen] = useState(false)

    const toggleJoin = () => {
        setPopupOpen(!popupOpen)
    }
    return (
        <div className="coming-soon-page">
            <div className="coming-soon-content">
                {
                    popupOpen
                        ? <JoinWaitingList />
                        : <div className="message">
                            <h2 className="">We are launching soon</h2>
                            <p>
                                Be the first to get updated when the platform is launched
                            </p>
                            <button type="button" onClick={toggleJoin} className="primary-button">Join waiting list</button>
                            <ShareButtons url={`${DOMAIN_NAME}/${url}/`} />
                        </div>
                }

            </div>
        </div>
    )
}

export default ComingSoon