import React from 'react';
import { Link } from 'react-router-dom';
import SlicedText from './slicedTitle';

const JobsContainer = ({ jobs, isAuth, isBusiness }) => {
    return (
        <div className="jobs-container">
            <div className="jobs">
                {jobs.map(job => (
                    <div key={job.id} className="job card">
                        <div className="job-card-header">
                            <img src={require("../assets/img/pages/jobs/spotify.png")} alt="" className="company-logo" />
                            <div className="company-info">
                                <h3 className="job-title"><SlicedText text={job.name} limit={40} /> </h3>
                                {
                                    isBusiness
                                        ? (<p>{parseInt(job.applicants) > 0 ? job.applicants : '0'} applicants</p>)
                                        : <p className="job-location">{job.location}</p>
                                }
                            </div>
                        </div>

                        <p className="job-summary">
                            <SlicedText text={job.job_description} limit={97} />
                        </p>
                        <p className="job-type">{job.job_type}</p>

                        <div className="job-salary">
                            <p className="amount">{job.max_salary}</p> /  <span className='salary-period'>month</span>
                        </div>

                        <div className="job-actions">
                            {
                                isBusiness
                                    ? ''
                                    : <Link to={`/job/${job.slug}`} className="button outline-button">Details</Link>
                            }
                            {
                                isAuth ?
                                    <Link className="button outline-button apply-now">Apply now</Link>
                                    :
                                    (isBusiness
                                        ? <Link to={`job/${job.slug}/applicants/`} className='button outline-button'>View Applicants</Link> :
                                        <Link to={"/login/"} className="button outline-button">Login to apply</Link>)
                            }
                            <i className="fa-regular fa-star"></i>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default JobsContainer;
