import { React, useState, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import { useParams, Link } from "react-router-dom";
import { useAuthentication } from "../../components/auth/checkAuth";
import { useNotification } from "../../components/services/notificationContext";
import SessionExpired from "../auth/sessionExpired";
import InterviewPopup from "../../components/applications/interviewPopup";
import { MEDIA_URL } from "../../api";
import { Helmet } from "react-helmet";
import api from "../../api";
import SiteHeader from "../../components/siteHeader";
import '../../assets/css/pages/jobsApplications/jobsApplication.css'
import CalculateAge from "../../components/services/calculateAge";
import CalculateExperience from "../../components/services/calclulateExperience";
import { formatDate } from "../../components/services/dateFormat";
import { formatTime } from "../../components/services/timeFormat";


const BusinessSingleBlogPage = () => {
    const { showNotification } = useNotification()
    const { jobSlug } = useParams()
    const { isAuth } = useAuthentication()
    const [jobObj, setJobObj] = useState({})
    const [applications, setApplications] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('applications');
    const [updateStatusOpen, setUpdateStatusOpen] = useState(false)
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
    const [isInterviewPopupOpen, setIsInterviewPopupOpen] = useState(false)

    const selectStatus = (selectedStatus, application_id) => {
        console.log(selectedStatus)
        setSelectedStatus(selectedStatus)
        setIsUpdatingStatus(true)

        try {
            const updatedApplicationStatus = async () => {
                const response = await api.post(`/business/update/application/${application_id}/`, {
                    "status": selectedStatus,
                    "application_id": application_id,
                },
                );
                if (response.status === 200) {
                    showNotification("success", "Status updated")
                    setIsUpdatingStatus(false)
                    if (response.data.status === "Interview") {
                        openInterviewPopup()
                    }
                    console.log(response.data.status)
                    // window.location.reload()
                } else {
                    showNotification("fail", "There was an error")
                }
            }
            updatedApplicationStatus()
        } catch (error) {
            if (error.response.status === 403) {
                showNotification("fail", "Please login")
            } else if (error.response.status === 400) {
                showNotification("fail", error.response.data.message)
            } else {
                showNotification("fail", "There was an error")
            }
        }
    }
    const UpdateStatusTab = () => {

        return (
            <div className="job-popup card">
                <div onClick={closeUpdatePopup} className="close-icon">
                    <i className="fa-solid fa-arrow-left"></i>
                </div>
                <div className="applicant">
                    <div className="applicant-info">
                        <div className="info">
                            <div className="basic-info names">
                                <div className="name_preview">
                                    <h3>{selectedApplication.applicant.user.first_name.slice(0, 1)}</h3>
                                    <h3>{selectedApplication.applicant.user.last_name.slice(0, 1)}</h3>
                                </div>

                                <div className="names-email">
                                    <h3 className="first-last-name">{selectedApplication.applicant.user.first_name} {selectedApplication.applicant.last_name}</h3>
                                    <p className="email">{selectedApplication.applicant.user.email}</p>
                                </div>
                            </div>
                            <div className="more-info">
                                <div className="gender">
                                    <p>Gender</p>
                                    <h4>{selectedApplication.applicant.gender}</h4>
                                </div>

                                <div className="gender">
                                    <p>Age</p>
                                    {
                                        selectedApplication.applicant.date_of_birth
                                            ? <h4>< CalculateAge birthDay={selectedApplication.applicant.date_of_birth} /></h4>
                                            : "NaN"
                                    }
                                </div>

                                <div className="gender">
                                    <p>Experience</p>
                                    {
                                        selectedApplication.applicant.started_working
                                            ? <h4> <CalculateExperience startedAt={selectedApplication.applicant.started_working} /> Year(s)</h4>
                                            : "NaN"
                                    }
                                </div>
                            </div>

                        </div>
                        <Link to={`${MEDIA_URL}${selectedApplication.applicant.resume}`} target="blank" className="button primary-button">
                            <i className="fa-solid fa-angles-down"></i>
                            <p>download cv</p>
                        </Link>
                    </div>

                    <div className="skills-container">
                        <h3>Skills</h3>
                        <div className="skills">
                            {selectedApplication.applicant.skills.map(skill => (
                                <p key={skill} className="skill">{skill.name}</p>
                            ))}
                        </div>

                    </div>
                </div>
                <hr />
                <div className="application-status">
                    <h3>Application Status</h3>
                    <p>Change talent application status by click</p>

                    <div className="statuses">
                        <div onClick={() => selectStatus("Pending", selectedApplication.id)} className="status">
                            {
                                selectedStatus === "Pending" ?
                                    <div className="process-icon">
                                        {isUpdatingStatus
                                            ? <img className='loading-icon' src={require("../../assets/icons/loading-yellow.png")} alt="icon"></img>
                                            : <i className="fa-solid fa-square-check"></i>
                                        }
                                    </div> :
                                    ''
                            }

                            <div className="icon">
                                <i className="fa-solid fa-layer-group"></i>
                            </div>
                            <p>Pending</p>
                        </div>
                        <div onClick={() => selectStatus("Shortlisted", selectedApplication.id)} className="status">
                            {
                                selectedStatus === "Shortlisted" ?
                                    <div className="process-icon">
                                        {
                                            isUpdatingStatus
                                                ? <img className='loading-icon' src={require("../../assets/icons/loading-yellow.png")} alt="icon"></img>
                                                : <i className="fa-solid fa-square-check"></i>
                                        }
                                    </div> :
                                    ''
                            }
                            <div className="icon">
                                <i className="fa-solid fa-elevator"></i>
                            </div>
                            <p>Shortlisted</p>
                        </div>
                        <div onClick={() => selectStatus("Interview", selectedApplication.id)} className="status">
                            {
                                selectedStatus === "Interview" ?
                                    <div className="process-icon">
                                        {
                                            isUpdatingStatus
                                                ? <img className='loading-icon' src={require("../../assets/icons/loading-yellow.png")} alt="icon"></img>
                                                : <i className="fa-solid fa-square-check"></i>
                                        }
                                    </div> :
                                    ''
                            }
                            <div className="icon">
                                <i className="fa-regular fa-square-plus"></i>
                            </div>
                            <p>Interview</p>
                        </div>

                        {
                            isInterviewPopupOpen
                                ? <InterviewPopup application={selectedApplication} setIsInterviewPopupOpen={setIsInterviewPopupOpen} />
                                : ''
                        }
                        <div onClick={() => selectStatus("Hired", selectedApplication.id)} className="status">
                            {
                                selectedStatus === "Hired" ?
                                    <div className="process-icon">
                                        {
                                            isUpdatingStatus
                                                ? <img className='loading-icon' src={require("../../assets/icons/loading-yellow.png")} alt="icon"></img>
                                                : <i className="fa-solid fa-square-check"></i>
                                        }
                                    </div> :
                                    ''
                            }
                            <div className="icon">
                                <i className="fa-solid fa-user-check"></i>
                            </div>
                            <p>Hired</p>
                        </div>
                        <div onClick={() => selectStatus("Rejected", selectedApplication.id)} className="status">
                            {
                                selectedStatus === "Rejected" ?
                                    <div className="process-icon">
                                        {
                                            isUpdatingStatus
                                                ? <img className='loading-icon' src={require("../../assets/icons/loading-yellow.png")} alt="icon"></img>
                                                : <i className="fa-solid fa-square-check"></i>
                                        }
                                    </div> :
                                    ''
                            }
                            <div className="icon">
                                <i className="fa-solid fa-user-minus"></i>
                            </div>
                            <p>Rejected</p>
                        </div>

                    </div>
                </div>
                <p>{selectedApplication.id}</p>
            </div>
        )
    }
    useEffect(() => {
        const fetchBlog = async () => {
            setIsLoading(true)
            try {
                const response = await api.get(`/business/${jobSlug}/applications/`)
                console.log(response.data)
                setJobObj(response.data.job);
                setApplications(response.data.applications);

                setIsLoading(false)
            } catch (error) {
                console.log(error)
                if (error.response.status === 403) {
                    showNotification('fail', "You probably need to login")
                    localStorage.clear()
                    window.location.href = "/login/"
                }

                // alert("You probably need to login")
                else {
                    showNotification('fail', "An error happened")
                }
            }
        }
        fetchBlog()
    }, [jobSlug, showNotification])

    const openUpdatePopup = (application) => {
        setSelectedApplication(application);
        setUpdateStatusOpen(true);
    };

    const closeUpdatePopup = () => {
        setSelectedApplication(null)
        setUpdateStatusOpen(false)
    }

    console.log(jobObj)

    const ApplicationsTab = () => {

        return (
            updateStatusOpen
                ? <UpdateStatusTab /> //I want to  get the clicked applications in this component
                : <div className="applications-list">
                    {applications.length > 0
                        ? (
                            <div className="applications-container card">
                                <div className="search-filters">
                                    <div className="count">
                                        <p>Jobs</p> <div className="number"><p>{applications.length}</p></div>
                                    </div>

                                    <div className="input">
                                        <div className="icon">
                                            <i className="fa-solid fa-magnifying-glass"></i>
                                        </div>
                                        <input type="search" name="" id="" placeholder="Search applications" />
                                    </div>

                                    <button className="button tertiary-button">
                                        <i className="fa-solid fa-arrow-down-wide-short"></i>
                                        <p>Filter</p>
                                    </button>
                                </div>

                                <div className="applications-table">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Application date</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                applications.map((application, index) => (

                                                    <tr key={index}>
                                                        <td className="names">
                                                            <div className="name_preview">
                                                                <p>{application.applicant.user.first_name.slice(0, 1)}</p>
                                                                <p>{application.applicant.user.last_name.slice(0, 1)}</p>
                                                            </div>
                                                            <div className="names-email">
                                                                <p className="first-last-name">{application.applicant.user.first_name} {application.applicant.last_name}</p>
                                                                <p className="email">{application.applicant.user.email}</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="date">{formatDate(application.date_updated)}</p>
                                                            <p className="time">{formatTime(application.date_updated)}</p>
                                                        </td>
                                                        <td>{application.status}</td>
                                                        <td className="actions">
                                                            <button onClick={() => openUpdatePopup(application)} className="button edit">
                                                                <i className="fa-regular fa-pen-to-square" />
                                                                <div className="tooltip"><p>Change status</p></div>
                                                            </button>
                                                        </td>
                                                    </tr>


                                                ))
                                            }

                                        </tbody>
                                    </table>

                                    <div className="mobile-grid">
                                        {
                                            applications.map((application, index) => (
                                                <div key={index} className="grid">
                                                    <div className="names">
                                                        <div className="name_preview">
                                                            <p>{application.applicant.user.first_name.slice(0, 1)}</p>
                                                            <p>{application.applicant.user.last_name.slice(0, 1)}</p>
                                                        </div>
                                                        <div className="names-email">
                                                            <p className="first-last-name">{application.applicant.user.first_name} {application.applicant.last_name}</p>
                                                            <p className="email">{application.applicant.user.email}</p>
                                                        </div>
                                                    </div>

                                                    <div className="date-experience">
                                                        <p className="date">{formatDate(application.date_updated)}</p>
                                                        <p className="time">{formatTime(application.date_updated)}</p>
                                                    </div>

                                                    <div className="actions">
                                                        <div>{application.status}</div>
                                                        <button onClick={() => openUpdatePopup(application)} className="button edit">
                                                            <i className="fa-regular fa-pen-to-square" />
                                                            <div className="tooltip"><p>Change status</p></div>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                            </div>

                        )

                        : (<p>No applications</p>)
                    }

                </div>
        );
    };

    const DetailsTab = () => {
        return (
            <div className="job-details card">
                <div className="job-header">
                    <h2>{jobObj.name}</h2>
                    <div className="required-skills">
                        {jobObj.required_skills.map((skill, index) => (
                            <p key={index} className="skill">{skill.name}</p>
                        ))}
                    </div>
                </div>

                <CKEditor data={jobObj.job_description} />
                {jobObj.job_description}
            </div>
        );
    };
    const handleTabClick = (tab) => {
        setActiveTab(tab)
    }

    const openInterviewPopup = () => {
        setIsInterviewPopupOpen(true)
    }



    return (
        isAuth ?
            <div>
                <Helmet>
                    <meta charSet="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link rel="icon" href="favicon.ico" type="image/x-icon" />
                    <title>{`Job | ${jobObj.name}`}</title>
                </Helmet>

                <div className="dashboard-content">
                    <SiteHeader />
                    <div className="bread-crumbs container">
                        <p>Dashboard /</p>
                        <p>Job applicants</p>
                        <p className="current">{jobObj.job_title}</p>
                    </div>
                    <div className="jobs-content container">
                        {jobObj.company_name &&
                            <div className="job-content card">
                                <div className="job-info">
                                    <div className="company-logo">
                                        <img src={`${MEDIA_URL}${jobObj.company_name.logo}`} alt="logo" className="logo" />
                                    </div>
                                    <div className="job-name">
                                        <div className="name-container">
                                            <h3>{jobObj.job_title}</h3>
                                            <p>Published {formatDate(jobObj.date_created)}</p>
                                        </div>
                                        <div className="edit-button"><i className="fa-regular fa-pen-to-square"></i></div>
                                    </div>
                                </div>
                                <hr />
                                <div className="job-work-type">
                                    <div className="type">
                                        <p>Job Type</p>
                                        <h4>{jobObj.job_type}</h4>
                                    </div>
                                    <div className="type">
                                        <p>Work type</p>
                                        <h4>{jobObj.work_type}</h4>
                                    </div>
                                </div>
                                <div className="salary">
                                    <p>Salary</p>
                                    <h4>{jobObj.min_salary} - {jobObj.max_salary}</h4>
                                </div>
                                <hr />
                                <div className="company-services">
                                    <h3>Services</h3>
                                    <div className="services">
                                        {jobObj.company_name.services.map(service => (
                                            <p key={service.id} className="service">{service.name}</p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }


                        <div className="application-content">
                            <div className="tab-buttons card">
                                <div
                                    className={activeTab === 'applications' ? 'applications active' : 'applications'}
                                    onClick={() => handleTabClick('applications')}
                                >
                                    <div className="tab">
                                        <div className="icon">
                                            <i className="fa-solid fa-user-group"></i>
                                        </div>
                                        <p>Applications</p>
                                    </div>

                                </div>
                                <div
                                    className={activeTab === 'details' ? 'details active' : 'details'}
                                    onClick={() => handleTabClick('details')}
                                >
                                    <div className="tab">
                                        <div className="icon">
                                            <i className="fa-solid fa-table-list"></i>
                                        </div>
                                        <p>Details</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                {activeTab === 'applications' && <ApplicationsTab />}
                                {activeTab === 'details' && <DetailsTab />}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            :
            <SessionExpired />
    )
}

export default BusinessSingleBlogPage