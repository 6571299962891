import { React, useState, useEffect } from "react";
import SessionExpired from "../../pages/auth/sessionExpired";
import { useAuthentication } from "../auth/checkAuth";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import api, { MEDIA_URL } from "../../api";
import SettingsPage from "./companySettings";
import CompanyInfo from "./companyInfo";
import CompanyEmployee from "./companyEmployees";
import SiteHeader from "../siteHeader";
import IsLoading from "../loadingIcon";
import NoDataContainer from "../noDataContainer";
import CompanyProfileJobs from "./companyJobs";
import CompanyProfileEmployees from "./companyEmployees";

const BusinessProfile = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(useAuthentication())
    const [activeTab, setActiveTab] = useState("info")
    const [business, setBusiness] = useState({})
    const [companyJobs, setCompanyJobs] = useState([])
    const [employees, setEmployees] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const handleTabClick = (tab) => {
        setActiveTab(tab)
    }
    useEffect(() => {
        setBusiness({})
        const fetchProfileData = async () => {
            setIsFetching(true)
            try {
                const response = await api.get(`/business/${businessSlug}/profile/`)
                if (response.status === 200) {
                    setBusiness(response.data.business || '')
                    setCompanyJobs(response.data.jobs || '')
                    setEmployees(response.data.employees || '')
                    setIsFetching(false)
                }
            } catch (error) {
                if (error.response.status === 403) {
                    setIsLoggedIn(false)
                }
            }

        }
        fetchProfileData()
    }, [])
    const businessSlug = localStorage.getItem("businessSlug")
    return (
        isLoggedIn
            ? <div>
                <Helmet>
                    <meta charSet="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link rel="icon" href="favicon.ico" type="image/x-icon" />
                    <title>Business Profile | CSR Staffing</title>
                </Helmet>
                <SiteHeader />
                <div className="dashboard-content">
                    {isFetching
                        ? <IsLoading text={"profile data"} />
                        : Object.keys(business).length > 0
                            ? <div className="main-section container">
                                <div className="bread-crumbs">
                                    <Link to={"/dashboard/"}>Dashboard/</Link>
                                    <p className='current'>Business Profile</p>
                                </div>

                                <section className="profile-header">
                                    <div className="first">
                                        {
                                            business.logo
                                                ? (
                                                    <img src={`${MEDIA_URL}/${business.logo}`} alt='business-logo'></img>
                                                )
                                                : (
                                                    <img src={require("../../assets/img/logo.png")} alt="Profile" />
                                                )
                                        }
                                        <div className="text">
                                            <h3>
                                                {
                                                    business.name
                                                }
                                            </h3>

                                            <div className="profile-content">
                                                <p>
                                                    <img src={require("../../assets/css/pages/profile-page/pin.png")} alt="" />
                                                    <span>{business.business_address ? business.business_address : "No business location"}</span>
                                                </p>
                                                <p>
                                                    <img src={require("../../assets/css/pages/profile-page/bulletpoint.png")} alt="" />
                                                    <span>{business.industry.name ? business.industry.name : 'No profession available'}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <button className='edit-profile-button'>
                                        <i className="fa-regular fa-pen-to-square"></i> <span className='button-text'>Edit Profile</span>
                                    </button>
                                </section>

                                <section className="main-content business-profile">
                                    <div className="company-info">
                                        <div className="tabs-buttons card">
                                            <div
                                                onClick={() => handleTabClick("info")}
                                                className={activeTab === 'info' ? 'info active' : 'info'}
                                            >
                                                <div className="tab">
                                                    <div className="icon">
                                                        <i className="fa-solid fa-briefcase"></i>
                                                    </div>
                                                    <p>Company info</p>
                                                </div>
                                            </div>

                                            <div
                                                onClick={() => handleTabClick("jobs")}
                                                className={activeTab === 'jobs' ? 'jobs active' : 'jobs'}
                                            >
                                                <div className="tab">
                                                    <div className="icon">
                                                        <i className="fa-solid fa-calendar-days"></i>
                                                    </div>
                                                    <p>Jobs</p>
                                                </div>
                                            </div>

                                            <div
                                                onClick={() => handleTabClick("employees")}
                                                className={activeTab === 'employees' ? 'employees active' : 'employees'}
                                            >
                                                <div className="tab">
                                                    <div className="icon">
                                                        <i className="fa-solid fa-people-group"></i>
                                                    </div>
                                                    <p>Employees</p>
                                                </div>
                                            </div>

                                            <div
                                                onClick={() => handleTabClick("settings")}
                                                className={activeTab === 'settings' ? 'settings active' : 'settings'}
                                            >
                                                <div className="tab">
                                                    <div className="icon">
                                                        <i className="fa-solid fa-gear"></i>
                                                    </div>
                                                    <p>Settings</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-content">
                                            {activeTab === 'info' && < CompanyInfo business={business} />}
                                            {activeTab === 'jobs' && <CompanyProfileJobs jobs={companyJobs} />}
                                            {activeTab === 'employees' && <CompanyProfileEmployees employees={employees} />}
                                            {activeTab === 'settings' && <SettingsPage business={business} />}
                                        </div>
                                    </div>
                                    <div className="additional-details">
                                        <div className="card">
                                            <h3>Additional details</h3>

                                            <div className="detail">
                                                <div className="icon">
                                                    <i className="fa-regular fa-envelope"></i>
                                                </div>
                                                <div className="detail-content">
                                                    <p className="content-item">Email</p>
                                                    <p>{business.email}</p>
                                                </div>
                                            </div>

                                            <div className="detail">
                                                <div className="icon">
                                                    <i className="fa-solid fa-mobile-screen"></i>
                                                </div>
                                                <div className="detail-content">
                                                    <p className="content-item">Phone</p>
                                                    <p>{business.phone_number}</p>
                                                </div>
                                            </div>

                                            <div className="detail">
                                                <div className="icon">
                                                    <i className="fa-solid fa-language"></i>
                                                </div>
                                                <div className="detail-content">
                                                    <p className="content-item">Languages</p>
                                                    {
                                                        business.languages.map((language, index) => (
                                                            <p key={index}>{language.name}</p>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card">
                                            <h3>Social links</h3>
                                            <div className="detail">
                                                <div className="icon">
                                                    <i className="fa-brands fa-instagram"></i>
                                                </div>
                                                <div className="detail-content">
                                                    <p className="content-item">Instagram</p>
                                                    <p>English, Kinyarwanda</p>
                                                </div>
                                            </div>
                                            <div className="detail">
                                                <div className="icon">
                                                    <i className="fa-brands fa-x-twitter"></i>
                                                </div>
                                                <div className="detail-content">
                                                    <p className="content-item">X</p>
                                                    <p>English, Kinyarwanda</p>
                                                </div>
                                            </div>

                                            <div className="detail">
                                                <div className="icon">
                                                    <i className="fa-solid fa-globe"></i>
                                                </div>
                                                <div className="detail-content">
                                                    <p className="content-item">Website</p>
                                                    <p>English, Kinyarwanda</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </section>

                            </div>
                            : <NoDataContainer
                                title={"No profile data"}
                                text={"We could not find data related to you, or your business"}
                                actionText={"Register a business"}
                                actionLink={"/business/register/"}
                            />


                    }
                </div>
            </div>
            : <SessionExpired />
    )
}

export default BusinessProfile