import { React, useEffect, useState } from "react";
import api from "../../../api";
import IsLoading from "../../loadingIcon";
import { useNotification } from "../../services/notificationContext";
import { Loading02Icon } from "hugeicons-react";
const JobInformation = ({ businessSlug }) => {
    const apiUrl = `/jobs/new_job/${businessSlug}/`;
    const { showNotification } = useNotification()

    // word types
    const [workTypes, setWorkTypes] = useState([])
    const [fetchingWorkTypes, setFetchingWorkTypes] = useState(false)
    const [selectedWorkType, setSelectedWorkType] = useState("")

    // job types
    const [jobTypes, setJobTypes] = useState([])
    const [fetchingJobTypes, setFetchingJobTypes] = useState(false)
    const [selectedJobType, setSelectedJobType] = useState("")

    // categories
    const [jobCategory, setJobCategory] = useState({});
    const [fetchingCategories, setFetchingCategories] = useState(false)
    const [category, setCategory] = useState("")

    // skills
    const [requiredSkills, setRequiredSkills] = useState([]);
    const [fetchingSkills, setFetchingSkills] = useState(false)
    const [isNewSkillOpen, setIsNewSkillOpen] = useState(false)
    const [newSkillName, setNewSkillName] = useState(null)

    // experience levels
    const [experienceLevels, setExperienceLevels] = useState([])
    const [fetchingLevels, setFetchingLevels] = useState(false)
    const [selectedLevel, setSelectedLevel] = useState("")
    //states
    const [isLoading, setIsLoading] = useState(false)

    // from data
    const [jobTitle, setJobTitle] = useState("")
    const [minSalary, setMinSalary] = useState("")
    const [maxSalary, setMaxSalary] = useState("")
    const [jobLocation, setJobLocation] = useState("")


    const newJobData = {
        "job_title": jobTitle,
        "location": jobLocation,
        "job_category": category,
        "job_type": selectedJobType,
        "work_type": selectedWorkType,
        "min_salary": minSalary,
        "max_salary": maxSalary,
        "required_skills": requiredSkills,
        "experience_level": selectedLevel,
    };


    useEffect(() => {
        // job types
        const fetchJobTypes = async () => {
            setFetchingJobTypes(true)
            try {
                const response = await api.get("/jobs/job-types/")
                if (response.status = 200) {
                    setJobTypes(response.data)
                    setFetchingJobTypes(false)
                }
            } catch (error) {
                showNotification("fail", error.response.data.message)
                setJobTypes([])
                setFetchingJobTypes(false)
            }


        }

        //work types

        const fetchWorkTypes = async () => {
            setFetchingWorkTypes(true)

            try {
                const response = await api.get("/jobs/work-types/")
                if (response.status = 200) {
                    setWorkTypes(response.data)
                    setFetchingWorkTypes(false)
                }
            } catch (error) {
                showNotification("fail", error.response.data.message)
                setWorkTypes([])
                setFetchingWorkTypes(false)
            }

        }

        // categories
        const fetchJobCategories = async (selectedCategory) => {
            setFetchingCategories(true)
            try {
                const response = await api.get("/jobs/job-categories/")
                if (response.status === 200) {
                    setJobCategory(response.data)
                    setFetchingCategories(false)

                } else if (response.status === 500) {
                    showNotification("fail", "Internal server error")
                    setFetchingCategories(false)
                } else {
                    showNotification("fail", "Unknown error")
                    setFetchingCategories(false)
                }
            } catch (error) {
                showNotification("fail", error.response.message)
                setFetchingCategories(false)
            }


        }

        // experience levels
        const fetchExperienceLevels = async () => {
            setFetchingLevels(true)
            try {
                const response = await api.get("/jobs/experience-levels/")
                if (response.status === 200) {
                    setExperienceLevels(response.data)
                    setFetchingLevels(false)
                }
            } catch (error) {
                showNotification("fail", error.response.data.message)
                setExperienceLevels([])
                setFetchingLevels(false)
            }
        }

        fetchExperienceLevels()
        fetchJobCategories()
        fetchJobTypes()
        fetchWorkTypes()

    }, [])

    const fetchRequiredSkills = async (selectedCategory) => {
        console.log(selectedCategory)
        setFetchingSkills(true)
        try {
            const response = await api.get(`/jobs/new_job/skills/${selectedCategory}/`)
            if (response.status === 200) {
                setRequiredSkills(response.data)
                setFetchingSkills(false)
            } else {
                showNotification("fail", response.data.message)
                setFetchingSkills(false)
            }
        } catch (error) {
            showNotification("fail", error.response.data.message || "An error happened")
            setFetchingSkills(false)
        }
    }
    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        setCategory(selectedCategory)
        fetchRequiredSkills(selectedCategory)

    }

    const addSKill = (event) => {
        event.preventDefault()
        const skillOjb = { "name": newSkillName }
        setRequiredSkills(prevSkill => [...prevSkill, skillOjb])
        setNewSkillName("");
    }

    const toggleNewSkillFom = (event) => {
        event.preventDefault()
        setIsNewSkillOpen(!isNewSkillOpen)
    }
    const removeSKill = (skillName) => {
        setRequiredSkills(prevSkills => prevSkills.filter(skill => skill.name !== skillName));
    }
    const postNewJob = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        try {
            const response = await api.post(apiUrl, newJobData)
            if (response.status == 201) {
                showNotification("success", "Job Basic info are saved")
                const jobSlug = response.data.job.slug
                setIsLoading(false)
                window.location.href = `/job/${jobSlug}/description/`
            }
        } catch (error) {
            showNotification("fail", error.response.data.message)
            setIsLoading(false)
        }
    };
    return (
        <div className="form card">
            <h2 className="form-title">Basic information</h2>
            <form action="">
                <div className="row">
                    <div className="input-info">
                        <h4 className="input-title">Job title</h4>
                        <p className="input-desc">Job titles must be describe one position</p>
                    </div>
                    <div className="input-container">
                        <input
                            type="text"
                            name="jobTitle"
                            id="jobTitle"
                            onChange={(e) => { setJobTitle(e.target.value) }}
                            placeholder="Eg: Python django  backend developer" />
                        <div className="help">At least 80 characters</div>
                    </div>
                </div>

                <div className="row">
                    <div className="input-info">
                        <h4 className="input-title">Type of Employment</h4>
                        <p className="input-desc">You can select multiple type of employment</p>
                    </div>
                    {
                        !fetchingWorkTypes

                            ? (
                                workTypes && workTypes.length >= 0

                                    ? (
                                        workTypes.map((type, index) => (
                                            <div key={index} className="input-container checkboxes">
                                                <label htmlFor={type.name}>
                                                    <input
                                                        type="radio"
                                                        value={type.name}
                                                        onChange={(e) => setSelectedWorkType(e.target.value)}
                                                        name="employmentType"
                                                        id={type.name} />
                                                    {type.name}
                                                </label>
                                            </div>
                                        ))
                                    )
                                    : (<p>Error getting employment types</p>)
                            )
                            : <Loading02Icon className="loading-icon" />
                    }
                </div>

                <div className="row">
                    <div className="input-info">
                        <h4 className="input-title">Type of the job</h4>
                        <p className="input-desc">You can select multiple type of employment</p>
                    </div>
                    {
                        !fetchingJobTypes

                            ? (
                                jobTypes && jobTypes.length >= 0

                                    ? (
                                        jobTypes.map((type, index) => (
                                            <div key={index} className="input-container checkboxes">
                                                <label htmlFor={type.name}>
                                                    <input
                                                        type="radio"
                                                        value={type.name}
                                                        onChange={(e) => setSelectedJobType(e.target.value)}
                                                        name="jobType"
                                                        id={type.name} />
                                                    {type.name}
                                                </label>
                                            </div>
                                        ))
                                    )
                                    : (<p>Error getting employment types</p>)
                            )
                            : <Loading02Icon className="loading-icon" />
                    }
                </div>

                <div className="row">
                    <div className="input-info">
                        <h4 className="input-title">Salary</h4>
                        <p className="input-desc">Please specify the estimated salary range for the role. *You can leave this blank</p>
                    </div>
                    <div className="half">
                        <div className="input-container">
                            <div className="icon">
                                <i className="fa-solid fa-dollar-sign"></i>
                            </div>
                            <input
                                type="number"
                                name="minSalary"
                                id="minSalary"
                                onChange={(e) => setMinSalary(e.target.value)}
                                placeholder="Min salary" />

                        </div>

                        <div className="help">to</div>

                        <div className="input-container">
                            <div className="icon">
                                <i className="fa-solid fa-dollar-sign"></i>
                            </div>
                            <input
                                type="number"
                                name="maxSalary"
                                id="maxSalary"
                                onChange={(e) => setMaxSalary(e.target.value)}
                                placeholder="Max salary" />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="input-info">
                        <h4 className="input-title">Job location</h4>
                        <p className="input-desc">Where will this job be based, of company headquarters, or branch</p>
                    </div>

                    <div className="input-container">
                        <input
                            type="text"
                            name="jobLocation"
                            id="jobLocation"
                            onChange={(e) => { setJobLocation(e.target.value) }}
                            placeholder="Eg: KICUKIRO /Kigali / Rwanda" />
                        <div className="help">At least 80 characters</div>
                    </div>
                </div>

                <div className="row categories">
                    <div className="input-info">
                        <h4 className="input-title">Job Category</h4>
                        <p className="input-desc">Job titles must be describe one position</p>
                    </div>
                    <div className="half">
                        <div className="input-container">
                            {
                                fetchingCategories
                                    ? <Loading02Icon className="loading-icon" />
                                    : (jobCategory.length > 0
                                        ?
                                        <select
                                            onChange={handleCategoryChange}
                                            name="jobCategory"
                                            id="jobCategory">

                                            <option value="" disabled>Select Category</option>
                                            {jobCategory.map((category, index) => (

                                                <option
                                                    key={index}
                                                    value={category.name}>{category.name}
                                                </option>
                                            ))}
                                        </select>
                                        : <p>No categories</p>
                                    )
                            }

                        </div>

                        <div className="input-container">
                            {
                                fetchingLevels
                                    ? <Loading02Icon className="loading-icon" />
                                    : (experienceLevels.length > 0
                                        ?
                                        <select
                                            onChange={(e) => setSelectedLevel(e.target.value)}
                                            name="experienceLevel"
                                            id="experienceLevel">

                                            <option value="" disabled selected>Select level</option>
                                            {experienceLevels.map((level, index) => (

                                                <option
                                                    key={index}
                                                    value={level.name}>{level.name}
                                                </option>
                                            ))}
                                        </select>
                                        : <p>No Levels</p>
                                    )
                            }

                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="input-info">
                        <h4 className="input-title">Required Skills</h4>
                        <p className="input-desc">Add required skills for the job</p>
                    </div>
                    <div className="input-container skills-container">
                        <div className="new-skill">
                            {isNewSkillOpen
                                ? <div className="newSkillForm">
                                    <input
                                        type="text"
                                        name="skillName"
                                        id="skillName"
                                        value={newSkillName}
                                        onChange={(e) => setNewSkillName(e.target.value)}
                                        placeholder="Skill name" />
                                    <button onClick={addSKill} className="outline-button"><i className="fa-solid fa-plus"></i> <span>Save skill</span></button>
                                </div>
                                : ''
                            }
                            <button onClick={toggleNewSkillFom} className="outline-button"><i className={isNewSkillOpen ? "" : "fa-solid fa-plus"}></i> <span>{isNewSkillOpen ? 'Cancel' : 'Add skill'}</span></button>
                        </div>
                        {fetchingSkills ?
                            (<Loading02Icon className="loading-icon" />)
                            : (requiredSkills
                                ? <div className="skills">
                                    {requiredSkills.map((skill, index) => (
                                        <div key={index} className="skill">
                                            <p className="name">{skill.name}</p>
                                            <i onClick={() => removeSKill(skill.name)} className="fa-solid fa-xmark"></i>
                                        </div>
                                    ))}

                                </div> : <p>No skills</p>)

                        }
                    </div>
                </div>

                <button
                    type="button"
                    onClick={postNewJob}
                    className="primary-button">
                    {isLoading
                        ? (<img className='loading-icon' src={require("../../../assets/icons/loading.png")} alt="" />)
                        : 'Save and continue'}
                </button>
            </form >
        </div >
    )
}

export default JobInformation