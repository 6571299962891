import { useState, useEffect } from 'react';
import api from '../../api'
const FetchData = (url) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {

                const responseData = await api.get(url);
                if (responseData.status === 200) {
                    setData(responseData.data);
                } else {
                    setError("Network response was not ok");
                }
            } catch (error) {
                setError("Error fetching data: " + error.message);
            }
            setIsLoading(false);
        };

        fetchData();
    }, [url]);

    return { data, isLoading, error };
};

export default FetchData;
