import React, { useState } from 'react';
import { useAuthentication } from '../../components/auth/checkAuth';
import { MEDIA_URL } from '../../api';
import { Helmet } from 'react-helmet';
import SiteHeader from '../../components/siteHeader';
import SessionExpired from '../auth/sessionExpired';
import { Link } from 'react-router-dom';
import '../../assets/css/pages/dashCandidates/dashCandidates.css'
import SlicedText from '../../components/slicedTitle';
import NoDataContainer from '../../components/noDataContainer';
import api from '../../api';

const DashCandidates = () => {
    const [dashboardData, setDashboardData] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const formatMonth = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' });
        return `${month} ${year}`;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' });
        const day = date.getDate();
        return `${month} ${day}, ${year}`;
    };


    useState(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("/candidates/dashboard/")
                if (response.status === 200) {
                    setDashboardData(response.data)
                    console.log(response.data.stats.profile_data.user.first_name)
                    console.log(response.data)
                    setIsLoading(false)
                }
            } catch (error) {
                setIsLoading(false)
                console.error('Error fetching dashboard data:', error);
            }
        }

        fetchData();
    }, [])
    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <title>Candidate Dashboard | CSR Staffing</title>
            </Helmet>

            <div className="dashboard-content">
                <SiteHeader />
                {isLoading
                    ? '..Getting dashboard info'
                    : <div className="content container">
                        <div className="dashboard-header">
                            <div className="greetings">
                                <h4>Hi, {dashboardData.stats.profile_data.user.first_name}</h4>
                                <p className='text'>
                                    Let’s find your dream job, Complete your profile to have better chance than other candidate. <Link className='on-page-link'> Complete Profile</Link>
                                </p>
                            </div>
                            <div className="filter">
                                <i className="fa-solid fa-calendar-days"></i>
                                <p>This month</p>
                            </div>

                        </div>
                        <div className='dashboard-data'>
                            <div className="cards">
                                <div className="stats">
                                    <div className="card">
                                        <i className="fa-solid fa-calendar-days"></i>
                                        <div className="starts">
                                            <p className="numbers">{dashboardData.stats.upcoming_interviews}</p>
                                            <p className="text">Interviews Scheduled</p>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <i className="fa-solid fa-briefcase"></i>
                                        <div className="starts">
                                            <p className="numbers">{dashboardData.stats.sent_applications}</p>
                                            <p className="text">Applications sent</p>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <i className="fa-regular fa-message"></i>
                                        <div className="starts">
                                            <p className="numbers">{dashboardData.stats.unread_messages}</p>
                                            <p className="text">Unread messages</p>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <i className="fa-solid fa-trash"></i>
                                        <div className="starts">
                                            <p className="numbers">{dashboardData.stats.rejected_applications}</p>
                                            <p className="text">Applications rejected</p>
                                        </div>
                                    </div>

                                </div>


                                <div className="upcoming-interview card">
                                    <h3>Upcoming Interview</h3>
                                    {!dashboardData.stats.recent_interview.business.name ?
                                        <NoDataContainer title={"No interviews"} text={"You do not have any interviews scheduled.Review your application to increase chances "} actionLink={"/dashboard/business/jobs/"} actionText={"Check applications"} />
                                        :
                                        <div className="interview-info">
                                            <div className="interview-head">
                                                <p className="date">Date : <span> {formatMonth(dashboardData.stats.recent_interview.interview_date)}</span></p>
                                            </div>
                                            <div className="interview-card">
                                                <img src={require("../../assets/img/logo-white.png")} alt="" className="company-logo" />
                                                <div className="company-info">
                                                    <h4 className="company-name">{dashboardData.stats.recent_interview.business.name}</h4>
                                                    <p className='interview-location'>{dashboardData.stats.recent_interview.location}</p>
                                                </div>
                                                <p className='interview-date'>10:00 - 11:00</p>
                                            </div>
                                        </div>}

                                </div>

                                <div className="recent-applications card">
                                    <div className="recent-applications-header">
                                        <h4>Recent Applications History</h4>
                                        {!dashboardData.stats.recent_applications.length > 0 ? '' :
                                            <Link className='on-page-link'>View all</Link>}
                                    </div>
                                    {!dashboardData.stats.recent_applications.length > 0 ?
                                        <NoDataContainer title={"No application"} text={
                                            "You do not have any applications. Check out jobs available and apply"
                                        } actionText={"Check jobs"} actionLink={"/dashboard/business/jobs/"}
                                        /> :
                                        <div className="applications">
                                            {
                                                dashboardData.stats.recent_applications.map(recent_applications => (
                                                    <div key={recent_applications.application_id} className='application'>
                                                        <p className="application-date">{formatDate(recent_applications.date_applied_at)}</p>
                                                        <div className="application-data">
                                                            <div className="company">
                                                                <div className="logo">
                                                                    <img src={require("../../assets/img/logo.png")} alt="" className="company-logo" />
                                                                </div>
                                                                <div className="application-info">
                                                                    <h3 className='job-title'><SlicedText text={recent_applications.job_applied.name} limit={70} /> </h3>
                                                                    <div className="job-info">
                                                                        <p>{recent_applications.job_applied.work_type.name}</p>
                                                                        <div className="dot"></div>
                                                                        <p>Full time</p>
                                                                        <div className="dot"></div>
                                                                        <p>{recent_applications.job_applied.location.name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`status ${recent_applications.status}`}><p>{recent_applications.status}</p></div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>}
                                </div>

                                <div className="total-applications card">
                                    <h3>Applications review</h3>
                                    <div className="stats-line">
                                        <div className="line short-listed" style={{ width: `${parseFloat(dashboardData.stats.total_applications.shortlisted)}%` }}></div>
                                        <div className="line on-hold" style={{ width: `${parseFloat(dashboardData.stats.total_applications.on_hold)}%` }}></div>
                                        <div className="line rejected" style={{ width: `${parseFloat(dashboardData.stats.total_applications.rejected)}%` }}></div>
                                    </div>

                                    <div className="application short-listed">
                                        <div className="title">

                                            <div className="dot"></div>
                                            <p>Shortlisted</p>
                                        </div>
                                        <div className="percentage">
                                            <p>
                                                {dashboardData.stats.total_applications.shortlisted}%
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="application on-hold">
                                        <div className="title">
                                            <div className="dot"></div>
                                            <p>
                                                <p>On hold</p>
                                            </p>

                                        </div>
                                        <div className="percentage">
                                            <p>{dashboardData.stats.total_applications.on_hold}</p>
                                        </div>
                                    </div>

                                    <hr />
                                    <div className="application rejected">
                                        <div className="title">
                                            <div className="dot"></div><p>Rejected</p>


                                        </div>
                                        <div className="percentage">
                                            <p>{dashboardData.stats.total_applications.rejected}</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="profile-info">
                                <div className="profile card">
                                    <img src={`${MEDIA_URL}/${dashboardData.stats.profile_data.profile_picture}`} alt="" className="profile-pic" />
                                    <h3 className="">{dashboardData.stats.profile_data.user.first_name} {dashboardData.stats.profile_data.user.last_name}</h3>
                                    <p className='profile-career'>Software Developer</p>
                                    <div className="profile-location">
                                        <img src={require("../../assets/icons/pin.png")} alt="" className="pin" />
                                        <p>{dashboardData.stats.profile_data.location}</p>
                                    </div>
                                    <button className="button primary-button">Edit Profile</button>
                                </div>
                                <div className="work-experience card">
                                    <h3>Work Experience</h3>
                                    <div className="experiences">
                                        {dashboardData.stats.profile_data.work_experience.map(experience => (

                                            <div key={experience.id} className="experience">
                                                <img src={require("../../assets/img/logo.png")} alt="" className="company-logo" />
                                                <div className="experience-info">
                                                    <div className="company-name">
                                                        <h4 className='job-title'><SlicedText text={experience.name} limit={25} /></h4>
                                                        <div className="company-job-type">
                                                            <p>{experience.company_name}</p>-<p>Full time</p>
                                                        </div>

                                                    </div>
                                                    <div className="time-frame">
                                                        <p>{formatMonth(experience.started_from)}</p><p>{formatMonth(experience.ended_at)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="skills-card card">
                                    <h3>Skills</h3>
                                    <div className="skills">
                                        {dashboardData.stats.profile_data.skills.map(skill => (
                                            <div key={skill.id} className="skill">
                                                <p> {skill.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )


}

export default DashCandidates