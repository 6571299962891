import React, { useEffect, useState } from 'react';
import { useAuthentication } from '../../components/auth/checkAuth';
import { useNotification } from '../../components/services/notificationContext';
import api from '../../api';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import '../../assets/css/pages/profile_info/profile_info.css'
import SessionExpired from '../auth/sessionExpired';

const ProfileInfoPage = ({ handleNext }) => {
    const { isAuth } = useAuthentication();
    const { showNotification } = useNotification()
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // form
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [gender, setGender] = useState("")

    console.log(firstName, lastName, phoneNumber, dateOfBirth, gender)
    const SubmitProfileInfo = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        if (!firstName || !lastName || !phoneNumber || !dateOfBirth || !gender) {
            showNotification('fail', "All fields are required")
            setIsLoading(false)
            return
        }
        const candidateInfo = {
            "first_name": firstName,
            "last_name": lastName,
            "phone_number": phoneNumber,
            "gender": gender,
            "date_of_birth": dateOfBirth,
        }

        try {
            const response = await api.post("/candidates/update-profile/info/", candidateInfo)
            if (response.status === 200) {
                showNotification("success", "Names are updated")
                handleNext()
            } else {
                showNotification("fail", response.error.message)
            }

        } catch (error) {
            showNotification("fail", error.response.data.message)
            setIsLoading(false)
        } finally {
            setIsLoading(false);
        }
    }

    return (

        <form action="" className="proFileInfoForm" encType='multipart/form-data'>
            <h3>Personal info</h3>
            {error && (<div className='error-message'>{error}</div>)}
            {/* <div className="profile-upload">
                                                <div className="profile-icon">
                                                    <i className="fa-solid fa-camera"></i>
                                                </div>
                                                <input type="file" name="profilePic" id="ProfilePic" />
                                            </div> */}
            <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" name="firstName" id="firstName" placeholder='Your first name' />
            <input value={lastName} onChange={(e) => setLastName(e.target.value)} type="text" name="lastName" id="lastName" placeholder='Your last name' />
            <input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} type="text" name="phoneNumber" id="phoneNumber" placeholder='Phone number' />
            <p>Date of birth</p>
            <input value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} type="date" name="dateOfBirth" id="dateOfBirth" placeholder='Date of birth' />
            <select value={gender} onChange={(e) => setGender(e.target.value)} name="gender" id="gender">
                <option value="" disabled>Select gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
            </select>
            <button onClick={SubmitProfileInfo} id="RegisterButton" className={`button primary-button ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>{isLoading ? <img className='loading-icon' src={require("../../assets/icons/loading.png")} alt="" /> : 'Next'}</button>
        </form>

    )
}

export default ProfileInfoPage